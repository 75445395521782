import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { FormDataInterface, MemberInfo } from '../../../types/formData'
import { Family } from '../../../types/LoginInfo'
import React, { useContext } from 'react'
import FormStepLayout from './layout/FormStepLayout'
import { mapToApplicationCreateRequestObject } from '../../../mapper/request'
import { MemberAuthContext } from '../../../auth/MemberAuthProvider'
import { API } from '../../../const'
import { getFormComponentType } from './form'
import { FormType } from '../../../types/formType'
import { BrowserBackWarning } from '../../util'

export type ParamType = {
  formType: FormType
}

const Create: React.FC = () => {
  const { formType } = useParams<ParamType>()

  const { loginInfo, bearerToken, revalidate } = useContext(MemberAuthContext)

  const applicant: MemberInfo = {
    id: loginInfo.user.memberId,
    officeName: loginInfo.office.name,
    officeNameKana: loginInfo.office.nameKana,
    name: loginInfo.user.name,
    nameKana: loginInfo.user.nameKana,
    postalCode: loginInfo.user.postalCode,
    address: loginInfo.user.address,
    tel: loginInfo.user.tel,
    email: loginInfo.user.email,
  }

  const family: Family[] = loginInfo.user.family

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { applicant, family },
  })

  const FormComponentType = getFormComponentType(formType)

  const handleConfirm = async (): Promise<boolean> => {
    document?.querySelector('main')?.scrollTo(0, 0)
    return await methods.trigger()
  }

  const handleSend = async (): Promise<void> => {
    await methods.handleSubmit(async (data: FormDataInterface['formData']) => {
      data.attachedFileObject =
        (await methods.watch('attachedFileObject')) ?? []
      const requestBody = await mapToApplicationCreateRequestObject(
        formType,
        data,
      )
      const res = await fetch(API.APPLICATIONS, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        method: 'post',
        mode: 'cors',
        body: JSON.stringify(requestBody),
      })
      if (res.status >= 400) {
        return
      }
      methods.reset(data)
    })()
    await revalidate()
  }

  return (
    <FormProvider {...methods}>
      <BrowserBackWarning
        isDirty={methods.formState.isDirty}
        message="申請が保存されていません。本当に戻りますか？"
      />
      <FormStepLayout
        FormComponentType={FormComponentType}
        formType={formType}
        handleConfirm={handleConfirm}
        handleSend={handleSend}
      />
    </FormProvider>
  )
}

export default Create
