import React, { useContext } from 'react'
import { API, PATH } from '../../../const'
import useSWR from 'swr'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { AdminLoginInfo } from '../../../types/AdminLoginInfo'
import { PageLoader } from '../../ui/Loader'
import { Heading } from '../../ui/Heading'
import { Body, Cell, Head, Row, Table } from '../../ui/Table'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { Button } from '../../ui/Button'

const List: React.FC = () => {
  const { bearerToken } = useContext(AdminAuthContext)
  const history = useHistory()

  const apiPath = API.ADMIN_ADMIN_USERS
  const fetcher = () =>
    fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())

  const { data } = useSWR<AdminLoginInfo['admin'][]>(apiPath, fetcher)

  if (typeof data === 'undefined') {
    return <PageLoader />
  }

  // @todo 外だししたみ
  const getAdminRoleLabel = (role: AdminLoginInfo['admin']['role']) => {
    switch (role) {
      case 'confirmer':
        return '確認者'
      case 'approver':
        return '次長'
      case 'finalApprover':
        return '局長'
      default:
        return ''
    }
  }

  // 偉い人順
  const orderRule = ['finalApprover', 'approver', 'confirmer']
  data.sort((a, b) => orderRule.indexOf(a.role) - orderRule.indexOf(b.role))

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ 管理者一覧
      </Heading>
      <ButtonWrapper>
        <ButtonWrapperLink to={PATH.ADMIN_ADMIN_USERS_CREATE}>
          <LinkButton>新規登録</LinkButton>
        </ButtonWrapperLink>
      </ButtonWrapper>
      <Wrapper>
        <Table>
          <Head>
            <Row>
              <ThCell>氏名</ThCell>
              <ThCell>メールアドレス</ThCell>
              <ThCell>権限</ThCell>
            </Row>
          </Head>
          <Body>
            {data.map((adminUser, index) => (
              <ClickableRow
                key={index}
                onClick={() =>
                  history.push(`${PATH.ADMIN_ADMIN_USERS}/${adminUser.adminId}`)
                }
              >
                <TdCell>{adminUser.name}</TdCell>
                <TdCell>{adminUser.email}</TdCell>
                <TdCell>{getAdminRoleLabel(adminUser.role)}</TdCell>
              </ClickableRow>
            ))}
          </Body>
        </Table>
      </Wrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`

const Wrapper = styled.div`
  display: block;
  margin-top: 72px;
`
const ClickableRow = styled(Row)`
  cursor: pointer;
  &:hover > td {
    background-color: rgba(67, 154, 137, 0.1);
  }
`
const ThCell = styled(Cell)`
  text-align: center;
  background: rgba(67, 154, 137, 0.25);
  padding: 16px 0;
  font-weight: bold;
`
const TdCell = styled(Cell)`
  text-align: center;
  height: 48px;
`

export default List
