import moment from 'moment'

const useParamGetter = (param: any) => {
  const date = (key: string, initialValue?: Date): Date | null => {
    const val = param[key]
    if (typeof val === 'undefined') {
      return typeof initialValue === 'undefined' ? null : initialValue
    }
    if (val === null || val.length === 0) {
      return null
    }
    return moment(param[key]).toDate()
  }

  const string = (key: string): string =>
    typeof param[key] === 'string' ? param[key] : ''

  const array = (key: string): string[] => {
    const value = param[key]
    if (Array.isArray(value)) {
      return value
    } else if (typeof value === 'string') {
      return [value]
    }
    return []
  }

  return { date, string, array }
}

export default useParamGetter
