import { useState } from 'react'

const FORM_STEP = ['detail', 'input', 'confirm', 'complete']

type FormStep = 'detail' | 'input' | 'confirm' | 'complete'

export function useMultiStepStateAdmin() {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const currentStep = FORM_STEP[currentIndex] as FormStep

  const prevStep = () => setCurrentIndex(currentIndex - 1)
  const nextStep = () => setCurrentIndex(currentIndex + 1)
  const detailStep = () => setCurrentIndex(0)

  return { currentStep, prevStep, nextStep, detailStep }
}
