import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import { RadioButtonLabel } from '../../ui/RadioButtonLabel'
import {
  InputField,
  Section,
} from '../../main/applications/form/ShougaiMimaiKin'
import styled from 'styled-components'
import { PATH } from '../../../const'
import { FORM_TYPE_IDS } from '../../../const/formTypeIds'
import { OfficeInfo } from '../../../types/formData'
import { useParams } from 'react-router-dom'
import { ParamType } from '../../main/applications/Create'
import { fetchAddress } from '../../../util/fetchAddressByPostalCode'
import {
  isOfficeId,
  isPhoneNumber,
  isPostalCode,
  isZenKatakana,
} from '../../../util/validation'

type Props = {
  officeInfo: OfficeInfo
  currentStep?: string
  disabled: boolean
  shouldShowOtherFormLinks?: true
  isCreate?: boolean
  isAdmin: boolean
}

// @todo src/components/main/account/form/inputParts/MemberInfoSection.tsx と同時に申請のフォームと共通化やる
// formのsotreをPropsで受け取ってdisabledで表示するコンポーネントにすれば良さそう
// (storeをContextから受け取っても結局表示するだけなので、親からPropsで渡す使い方を全体的に矯正しても大丈夫そう)
export const OfficeInfoSection: React.FC<Props> = (props) => {
  const { formType } = useParams<ParamType>()

  const { control, errors, setValue } = useFormContext()

  const handleChangePostalCode = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputPostalCode = e.target.value
    if (inputPostalCode.length === 8) {
      const address = await fetchAddress(inputPostalCode)
      setValue('address', address, { shouldDirty: true })
    }
  }

  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■事業所情報
      </Heading>
      {Object.keys(errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所番号
        </Heading>
        <Controller
          control={control}
          name="officeId"
          rules={{
            required: '事業所番号を入力してください',
            validate: { isOfficeId },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.officeId}
              disabled={props.disabled || !props.isCreate}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.officeId && (
        <ErrorMessage>{errors.officeId.message}</ErrorMessage>
      )}
      {errors.officeId?.type === 'isOfficeId' && (
        <ErrorMessage>半角数字4桁で入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所名
        </Heading>
        <Controller
          control={control}
          name="name"
          rules={{ required: '事業所名を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.name}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
          <br />
          (全角カナ)
        </Heading>
        <Controller
          control={control}
          name="nameKana"
          rules={{
            required: 'フリガナを入力してください',
            validate: { isZenKatakana },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.nameKana}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.nameKana && (
        <ErrorMessage>{errors.nameKana.message}</ErrorMessage>
      )}
      {errors.nameKana?.type === 'isZenKatakana' && (
        <ErrorMessage>全角カナで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          代表者名
        </Heading>
        <Controller
          control={control}
          name="representativeName"
          rules={{ required: '代表者名を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.representativeName}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.representativeName && (
        <ErrorMessage>{errors.representativeName.message}</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
          <br />
          (全角カナ)
        </Heading>
        <Controller
          control={control}
          name="representativeNameKana"
          rules={{
            required: 'フリガナを入力してください',
            validate: { isZenKatakana },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.representativeNameKana}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.representativeNameKana && (
        <ErrorMessage>{errors.representativeNameKana.message}</ErrorMessage>
      )}
      {errors.representativeNameKana?.type === 'isZenKatakana' && (
        <ErrorMessage>全角カナで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          事務担当者
        </Heading>
        <Controller
          control={control}
          name="clerkName"
          rules={{ required: '事務担当者を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.clerkName}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.clerkName && (
        <ErrorMessage>{errors.clerkName.message}</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
          <br />
          (全角カナ)
        </Heading>
        <Controller
          control={control}
          name="clerkNameKana"
          rules={{
            required: 'フリガナを入力してください',
            validate: { isZenKatakana },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.clerkNameKana}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.clerkNameKana && (
        <ErrorMessage>{errors.clerkNameKana.message}</ErrorMessage>
      )}
      {errors.clerkNameKana?.type === 'isZenKatakana' && (
        <ErrorMessage>全角カナで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所郵便番号
          <br />
          (半角数字+半角ハイフン)
        </Heading>
        <Controller
          control={control}
          name="postalCode"
          rules={{
            required: '会員郵便番号を入力してください',
            validate: { isPostalCode },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={(e) => {
                onChange(e)
                handleChangePostalCode(e)
              }}
              defaultValue={props.officeInfo.postalCode}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.postalCode && (
        <ErrorMessage>{errors.postalCode.message}</ErrorMessage>
      )}
      {errors.postalCode?.type === 'isPostalCode' && (
        <ErrorMessage>半角数字+半角ハイフンで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          所在地
        </Heading>
        <Controller
          control={control}
          name="address"
          rules={{ required: '所在地を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.address}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.address && <ErrorMessage>{errors.address.message}</ErrorMessage>}
      <InputField>
        <Heading type="blockTitle" tag="span">
          電話番号
          <br />
          (半角数字)
        </Heading>
        <Controller
          control={control}
          name="tel"
          rules={{
            required: '電話番号を入力してください',
            validate: { isPhoneNumber },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.officeInfo.tel}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.tel && <ErrorMessage>{errors.tel.message}</ErrorMessage>}
      {errors.tel?.type === 'isPhoneNumber' && (
        <ErrorMessage>半角数字10桁か11桁で入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          会費振替方法
        </Heading>
        {props.currentStep !== 'input' && props.currentStep !== 'confirm' && (
          <Controller
            control={control}
            name="paymentMethod"
            render={({ value }) => (
              <Input
                type="text"
                value={
                  props.isAdmin
                    ? convertPaymentMethod(value)
                    : convertPaymentMethod(props.officeInfo.paymentMethod)
                }
                disabled={props.disabled}
              />
            )}
          />
        )}
        {(props.currentStep === 'input' || props.currentStep === 'confirm') && (
          <Controller
            control={control}
            name="paymentMethod"
            render={({ onChange, value }) => (
              <RadioButtonWrapper>
                <ThreeRadioButtonArea>
                  <RadioButtonLabel
                    label="現金"
                    value="cache"
                    checked={value === 'cache'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </ThreeRadioButtonArea>
                <ThreeRadioButtonArea>
                  <RadioButtonLabel
                    label="口座"
                    value="withdrawal"
                    checked={value === 'withdrawal'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </ThreeRadioButtonArea>
                <ThreeRadioButtonArea>
                  <RadioButtonLabel
                    label="振込"
                    value="transfer"
                    checked={value === 'transfer'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </ThreeRadioButtonArea>
              </RadioButtonWrapper>
            )}
          />
        )}
      </InputField>
      {props.shouldShowOtherFormLinks &&
        formType !== FORM_TYPE_IDS.OFFICE_TEIKIKENKOSHINDAN_JOSEIKIN && (
          <Link
            href={`${PATH.OFFICE_APPLICATIONS_NEW}/${FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE}`}
          >
            ※事業所情報を変更する場合はこちら
          </Link>
        )}
    </Section>
  )
}

// @todo presentation とかに持っていきたい
export const convertPaymentMethod = (
  paymentMethod: OfficeInfo['paymentMethod'],
): string => {
  let ret = ''
  switch (paymentMethod) {
    case 'cache':
      ret = '現金'
      break
    case 'withdrawal':
      ret = '口座'
      break
    case 'transfer':
      ret = '振込'
      break
  }
  return ret
}

const Link = styled.a`
  text-decoration: underline;
  color: #000;
  margin-top: 32px;
  display: inline-block;
  font-size: 16px;
  &:hover {
    text-decoration: none;
  }
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ThreeRadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 110px;
`
const ErrorMessage = styled.div`
  color: red;
`
