import React from 'react'
import styled from 'styled-components'

import { ShugakuIwaiKinData } from '../../../../types/formData'

import { Heading } from '../../../ui/Heading'
import { Input } from '../../../ui/Input'
import { RadioButtonLabel } from '../../../ui/RadioButtonLabel'
import { DatePicker } from '../../../ui/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'
import {
  BankAccountSection,
  FileSection,
  MemberInfoSection,
} from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { PATH } from '../../../../const'
import { FORM_TYPE_IDS } from '../../../../const/formTypeIds'
import { mediaQuery } from '../../../../themes/size'
import { isYYYYMMDD, isZenKatakana } from '../../../../util/validation'

type Props = {
  disabled: boolean
  canAdminUpload?: boolean
  handleSaveImage?: (files: File) => Promise<void>
  isUploading?: boolean
  handleDeleteImage?: (fileId: string) => Promise<void>
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const methods = useFormContext<ShugakuIwaiKinData['formData']>()
  const { control, errors } = methods

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼給付金申請 - 就学祝金
        </Heading>
      </HeadingArea>

      {Object.keys(methods.errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■申請情報
        </Heading>
        <InputField>
          <Heading type="blockTitle" tag="span">
            進学情報
          </Heading>
          <Controller
            control={control}
            name="schoolInfo"
            defaultValue="primarySchool"
            render={({ value, onChange }) => (
              <RadioButtonWrapper>
                <RadioButtonArea>
                  <RadioButtonLabel
                    label="小学校"
                    value="primarySchool"
                    checked={value === 'primarySchool'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </RadioButtonArea>
                <RadioButtonArea>
                  <RadioButtonLabel
                    label="中学校"
                    value="juniorHighSchool"
                    checked={value === 'juniorHighSchool'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </RadioButtonArea>
              </RadioButtonWrapper>
            )}
          />
        </InputField>
        {errors.schoolInfo && (
          <ErrorMessage>{errors.schoolInfo.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            就学者氏名
          </Heading>
          <Controller
            control={control}
            name="studentName"
            rules={{ required: '就学者氏名を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.studentName && (
          <ErrorMessage>{errors.studentName.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            フリガナ
            <br />
            (全角カナ)
          </Heading>
          <Controller
            control={control}
            name="studentNameKane"
            rules={{
              required: 'フリガナを入力してください',
              validate: { isZenKatakana },
            }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.studentNameKane && (
          <ErrorMessage>{errors.studentNameKane.message}</ErrorMessage>
        )}
        {errors.studentNameKane?.type === 'isZenKatakana' && (
          <ErrorMessage>全角カナで入力してください</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            続柄
          </Heading>
          <Controller
            control={control}
            name="relationship"
            rules={{ required: '続柄を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.relationship && (
          <ErrorMessage>{errors.relationship.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            生年月日
          </Heading>
          <Controller
            control={control}
            name="birthDay"
            rules={{
              required: '生年月日を入力してください',
              validate: { isYYYYMMDD },
            }}
            render={({ value, onChange, onBlur }) => (
              <DatePicker
                onChange={onChange}
                value={value}
                disabled={props.disabled}
                openToDate={true}
                onBlur={onBlur}
              />
            )}
          />
        </InputField>
        {errors.birthDay && (
          <ErrorMessage>{errors.birthDay.message}</ErrorMessage>
        )}
        {errors.birthDay?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            学校名
          </Heading>
          <Controller
            control={control}
            name="schoolName"
            rules={{ required: '学校名を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.schoolName && (
          <ErrorMessage>{errors.schoolName.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            請求金額
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="amount"
              defaultValue="10,000"
              render={({ value }) => (
                <Input type="text" value={value} disabled />
              )}
            />
            <InputLabel>円</InputLabel>
          </LabelWrapper>
        </InputField>
        {props.shouldShowOtherFormLinks && (
          <Heading type="sectionTitle" tag="h2">
            ※同居家族情報を変更する場合は
            <TextLink
              href={`${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE}`}
              target="_blank"
            >
              こちら
            </TextLink>
          </Heading>
        )}
      </Section>

      <FileSection
        headingLabel="就学通知書/子の健康保険者証/住民票"
        secondHeadingLabel="子供の年齢及び個別会員との関係がわかるもの"
        disabled={props.disabled}
        canAdminUpload={props.canAdminUpload}
        handleSaveImage={props.handleSaveImage}
        isUploading={props.isUploading}
        handleDeleteImage={props.handleDeleteImage}
      />

      <BankAccountSection disabled={props.disabled} isMember={true} />

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
// @fixme 子コンポーネントから共通で参照できるように一旦exportしました
export const Section = styled.section`
  margin-top: 72px;
`
// @fixme 子コンポーネントから共通で参照できるように一旦exportしました
export const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
`

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
  }
`
const RadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 160px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 8px;
  }
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const InputLabel = styled.label`
  margin-left: 8px;
  font-size: 16px;
`
const TextLink = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`

export default Component
