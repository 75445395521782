// 事業所は `事業所番号` をキーにログインする。擬似的にメアドとして扱うため、このドメインを入力値にappendする
export const OFFICE_NUMBER_SUFFIX_DOMAIN =
  process.env.REACT_APP_OFFICE_NUMBER_SUFFIX_DOMAIN

export const KAIIN_TOKUTEN_URL = 'https://bunkin.jp/memberbenefits/'

export const KYUFUKIN_SHOSAI_URL = 'https://bunkin.jp/application/'

export const SHUKUHAKU_SHOSAI_URL = 'https://bunkin.jp/tickets/trip/'

export const SHUKUHAKU_SHOMEISHO_URL = 'https://bunkin.jp/notification'

export const PRIVACY_URL = 'https://bunkin.jp/privacy/'

// @todo ワイヤーにリンク先の指定がなかったので要確認
export const JOSEIKIN_SHOSAI_URL = 'https://bunkin.jp/tickets/health/'

export const ABOUT_SHINSEI_SYSTEM_PAGE_URL =
  'https://bunkin.jp/applicationsystem/'

export const SUPPORTING_ENVIRONMENT_PAGE_URL =
  'https://bunkin.jp/applicationsystem/'

// アップロードファイルの上限バイト数(10MB)
export const UPLOAD_FILE_SIZE_LIMIT = 10485760
