import download from '../../../../../util/download'

const fetchDownloadCsv = (
  downloadLink: string,
  filename: string,
  bearerToken: string,
) => {
  fetch(downloadLink, {
    headers: {
      Authorization: 'Bearer ' + bearerToken,
      'Content-Type': 'text/csv',
    },
    mode: 'cors',
  })
    .then((res) => res.text())
    .then((csv) => download(csv, filename))
}

export default fetchDownloadCsv
