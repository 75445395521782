import React, { useContext } from 'react'
import styled from 'styled-components'
import { AdminAuthContext } from '../../../../auth/AdminAuthProvider'
import { useCsvDownloadCriteria, useDownloadSectionOpen } from './hooks'
import { fetchDownloadCsv } from './util'
import { Heading } from '../../../ui/Heading'
import { DatePicker } from '../../../ui/DatePicker'
import { Button } from '../../../ui/Button'
import { Select } from '../../../ui/Select'
import moment from 'moment'
import { API, PATH } from 'const'
import { useLocation } from 'react-router-dom'
import { CloseIcon, OpenIcon } from '../../../ui/Icon'

type Props = {
  downloadFormTypes: {
    label: string
    value: string
  }[]
}

const Component: React.FC<Props> = (props) => {
  const { bearerToken } = useContext(AdminAuthContext)
  const [isCsvCriteriaOpen, toggleCsvCriteriaOpen] = useDownloadSectionOpen()
  const criteria = useCsvDownloadCriteria(props.downloadFormTypes[0].value)
  const path = useLocation().pathname
  const handleClickDownload = () => {
    const type = criteria.downloadFormType.get()
    const timestamp = moment().format('YYYYMMDDHHmmss')
    const fileName = `${type}_${timestamp}.csv`
    const apiPath = path.match(
      new RegExp(`^${PATH.ADMIN_APPLICATIONS_MEMBER}*`),
    )
      ? API.ADMIN_APPLICATIONS_MEMBER
      : API.ADMIN_APPLICATIONS_OFFICE
    fetchDownloadCsv(criteria.buildDownLoadLink(apiPath), fileName, bearerToken)
  }

  return (
    <>
      <ButtonWrapper>
        <WhiteButton onClick={toggleCsvCriteriaOpen}>
          CSV
          {!isCsvCriteriaOpen ? <CloseIcon /> : <OpenIcon />}
        </WhiteButton>
      </ButtonWrapper>
      {isCsvCriteriaOpen && (
        <>
          <CriteriaWrapperXSmall>
            <div>
              <Heading type="blockTitle" tag="span">
                種別
              </Heading>
              <SelectBox
                name="downloadFormType"
                value={criteria.downloadFormType.get()}
                options={props.downloadFormTypes}
                onChange={criteria.downloadFormType.set}
              />
            </div>
            <div>
              <Heading type="blockTitle" tag="span">
                出力期間
              </Heading>
              <FlexBox>
                <DatePickerWrapper>
                  <DatePicker
                    name="acceptedAtFrom"
                    value={criteria.acceptedAtFrom.get()}
                    onChange={criteria.acceptedAtFrom.set}
                  />
                </DatePickerWrapper>
                <Between>～</Between>
                <DatePickerWrapper>
                  <DatePicker
                    name="acceptedAtTo"
                    value={criteria.acceptedAtTo.get()}
                    onChange={criteria.acceptedAtTo.set}
                  />
                </DatePickerWrapper>
              </FlexBox>
            </div>
          </CriteriaWrapperXSmall>
          <TwoButtonWrapper>
            <Button onClick={handleClickDownload}>出力</Button>
          </TwoButtonWrapper>
        </>
      )}
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
`
const WhiteButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #439a89;
  font-weight: normal;
  position: relative;
  display: inline-block;
`
const CriteriaWrapperXSmall = styled.div`
  display: flex;
  align-items: center;
  width: 66rem;
  justify-content: space-between;
  margin-top: 32px;
  width: 35rem;
`
const SelectBox = styled(Select)`
  flex-grow: 1;
  display: inline-block;
  width: 160px;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.05);
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  font-size: 16px;
  width: 200px;
`
const DatePickerWrapper = styled.div`
  input[type='text'] {
    width: 140px;
  }
`
const FlexBox = styled.div`
  display: inline-flex;
`
const Between = styled.div`
  align-self: center;
  padding: 0 15px;
`
const TwoButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  width: 18rem;
  justify-content: space-between;
`

export default Component
