import { useHistory, useParams } from 'react-router-dom'
import { Application as ApplicationType } from '../../../types/application'
import useSWR from 'swr'
import { API, PATH } from '../../../const'
import { FormProvider, useForm } from 'react-hook-form'
import React, { useContext, useEffect, useState } from 'react'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import { getFormComponentType } from './form'
import { PageLoader, PartialLoader } from '../../ui/Loader'
import { Section } from '../applications/form/SeijinIwaiKin'
import { Heading } from '../../ui/Heading'
import { MessageModal } from '../../ui/Modal'
import styled from 'styled-components'
import { Button } from '../../ui/Button'
import { Textarea } from '../../ui/Textarea'
import { mediaQuery } from '../../../themes/size'

type ParamType = {
  applicationId: string
}

const Single: React.FC = () => {
  const methods = useForm()
  const history = useHistory()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isCancelOK, setIsCancelOK] = useState(true)
  const [isCanceling, setIsCanceling] = useState(false)
  const { bearerToken } = useContext(OfficeAuthContext)
  const { applicationId } = useParams<ParamType>()
  const apiPath = `${API.APPLICATIONS}/${applicationId}`
  const fetcher = () =>
    fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())
  const { data: application } = useSWR<ApplicationType>(apiPath, fetcher)
  const loading = typeof application === 'undefined'
  useEffect(() => {
    if (!loading && application) {
      // 取得したapplicationをdefaultValueとして扱いたいが、Hooksの性質上asyncな取得処理を待てない
      // useForm 生成時に初期値を指定できないので、resetで擬似的に初期値を入れる
      // https://github.com/react-hook-form/react-hook-form/issues/2492#issuecomment-771578524
      methods.reset(application.formData)
    }
    // eslint-disable-next-line
  }, [loading])
  if (typeof application === 'undefined') {
    return <PageLoader />
  }

  const FormComponentType = getFormComponentType(application.formType)

  const handleCancel = async (): Promise<void> => {
    setIsCanceling(true)
    const res = await fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'delete',
    })
    if (res.status >= 400) {
      setIsCancelOK(false)
    } else {
      application.approvalStatus = 'rejected'
    }
    setIsConfirmModalOpen(false)
    setIsCanceling(false)
    setIsCompleteModalOpen(true)
  }

  return (
    <FormProvider {...methods}>
      <FormComponentType disabled={true} shouldShowOtherFormLinks={true} />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■管理者修正項目
        </Heading>
        <TextareaField>
          <Textarea disabled>{application.adminRemarks}</Textarea>
        </TextareaField>
      </Section>

      {application.approvalStatus === 'applying' && (
        <ButtonWrapper>
          <CancelButton
            type="button"
            onClick={() => setIsConfirmModalOpen(true)}
          >
            申請取消
          </CancelButton>
        </ButtonWrapper>
      )}
      <ButtonWrapper>
        <BackButton
          type="button"
          onClick={() => history.push(PATH.OFFICE_APPLICATIONS)}
        >
          戻る
        </BackButton>
      </ButtonWrapper>
      <MessageModal
        isOpen={isConfirmModalOpen}
        title={'本当に申請を取消しますか？'}
        closeText={isCanceling ? <PartialLoader /> : 'OK'}
        onClickClose={handleCancel}
        onClickOverlay={() => setIsConfirmModalOpen(false)}
        onPressEscape={() => setIsConfirmModalOpen(false)}
        onClickCancelButton={() => setIsConfirmModalOpen(false)}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isCompleteModalOpen}
        title={isCancelOK ? '申請を取消しました' : '申請の取消に失敗しました'}
        description={
          isCancelOK ? undefined : (
            <p>
              しばらく経ってから再度お試しいただくか運営にお問い合わせください。
            </p>
          )
        }
        closeText="OK"
        onClickClose={() => setIsCompleteModalOpen(false)}
        onClickOverlay={() => setIsCompleteModalOpen(false)}
        onPressEscape={() => setIsCompleteModalOpen(false)}
        id="dialog-message"
      />
    </FormProvider>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const CancelButton = styled(Button)`
  background-color: #fe6f32;
  &:hover {
    background-color: rgba(254, 111, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`
const TextareaField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`

export default Single
