import { officeCategorisedForms } from '../../../data/categorisedForms'
import { Heading } from '../../ui/Heading'
import styled from 'styled-components'
import { Button } from '../../ui/Button'
import { Link } from 'react-router-dom'
import { mediaQuery } from '../../../themes/size'

const New: React.FC = () => {
  return (
    <>
      {officeCategorisedForms.map((category, index) => (
        <div key={index}>
          <HeadingArea>
            <Heading type="screenTitle" tag="h1">
              ▼{category.name}
            </Heading>
          </HeadingArea>
          <Description type="sectionTitle" tag="h2">
            {category.description}
          </Description>
          {typeof category.aboutLink !== 'undefined' && (
            <Description type="sectionTitle" tag="h2">
              詳細は、
              <AnchorLink href={category.aboutLink} target="_blank">
                こちら
              </AnchorLink>
              からご確認ください。
            </Description>
          )}
          <ButtonWrapper>
            {category.forms.map((form) => (
              <ButtonWrapperLink to={form.link} key={form.type}>
                <LinkButton>{form.name}</LinkButton>
              </ButtonWrapperLink>
            ))}
          </ButtonWrapper>
        </div>
      ))}
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;
`
const Description = styled(Heading)`
  padding: 8px 0 0 22px;
`
const AnchorLink = styled.a`
  color: #439a89;
  &:hover {
    text-decoration: none;
  }
`
const ButtonWrapper = styled.div`
  min-width: 200px;
  max-width: 800px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    text-align: center;
  }
`
const LinkButton = styled(Button)`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  border: 1px solid #439a89;
  box-sizing: border-box;
  border-radius: 24px;
  width: 184px;
  height: 56px;
  margin-top: 32px;
  margin-right: 16px;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`

export default New
