import React from 'react'
import { FormType } from '../../../../types/formType'
import { FORM_TYPE_IDS } from '../../../../const/formTypeIds'
import TeikikenkoshindanJoseikin from './TeikikenkoshindanJoseikin'
import KaiinnjouhouHenkoutodoke from './KaiinnjouhouHenkoutodoke'
import Ichibutaikaitodoke from './Ichibutaikaitodoke'

export const getFormComponentType = (formType: FormType): React.ElementType => {
  switch (formType) {
    case FORM_TYPE_IDS.OFFICE_TEIKIKENKOSHINDAN_JOSEIKIN:
      return TeikikenkoshindanJoseikin
    case FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE:
      return KaiinnjouhouHenkoutodoke
    case FORM_TYPE_IDS.OFFICE_ICHIBUTAIKAITODOKE:
      return Ichibutaikaitodoke
    default:
      throw new Error('閲覧できない申請種別です')
  }
}
