import React, { useContext } from 'react'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { PATH } from '../../../const'
import { OfficeInfoSection } from '../../layout/inputParts/OfficeInfoSection'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import styled from 'styled-components'
import { Button } from '../../ui/Button'
import { mediaQuery } from '../../../themes/size'

const Account: React.FC = () => {
  const methods = useForm({
    mode: 'onBlur',
  })
  const { loginInfo } = useContext(OfficeAuthContext)
  const { office: officeInfo } = loginInfo

  return (
    <>
      <FormProvider {...methods}>
        <Heading type="sectionTitle" tag="h2">
          ■ {officeInfo.name}様（事業所番号{officeInfo.officeId}）
        </Heading>

        <OfficeInfoSection
          officeInfo={officeInfo}
          disabled={true}
          shouldShowOtherFormLinks={true}
          isAdmin={false}
        />

        <Section>
          <Heading type="sectionTitle" tag="h2">
            ■アカウント情報
          </Heading>
          <InputFieldWithButton>
            <HeadingThreeItems type="blockTitle" tag="span">
              パスワード
            </HeadingThreeItems>
            <Input type="text" defaultValue="*******" disabled />
            <ButtonWrapper>
              <ButtonWrapperLink to={`${PATH.OFFICE_ACCOUNT}/password/edit`}>
                <LinkButton>変更</LinkButton>
              </ButtonWrapperLink>
            </ButtonWrapper>
          </InputFieldWithButton>
        </Section>
        <BackButtonWrapper>
          <BackButtonLink to={PATH.OFFICE_TOP}>
            <BackButton>戻る</BackButton>
          </BackButtonLink>
        </BackButtonWrapper>
      </FormProvider>
    </>
  )
}

export const Section = styled.section`
  margin-top: 72px;
`

const InputFieldWithButton = styled.div`
  display: grid;
  grid-template-columns: 182px 328px 1fr;
  align-items: center;
  width: 669px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const HeadingThreeItems = styled(Heading)`
  width: 132px;
`
const ButtonWrapper = styled.div`
  margin-left: 64px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    text-align: center;
    margin-top: 16px;
    margin-left: 0%;
  }
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  border-radius: 24px;
  width: 64px;
  height: 32px;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const BackButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const BackButtonLink = styled(Link)`
  text-decoration: none;
`
const BackButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
export default Account
