import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { API, PATH } from '../../../const'
import { useContext } from 'react'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { Heading } from '../../ui/Heading'
import { Button } from '../../ui/Button'
import useSWR from 'swr'
import { AdminApplication as ApplicationType } from '../../../types/application'
import { ListPaginationResponse } from '../../../types/responseBody/ListPaginationResponse'
import { PageLoader } from '../../ui/Loader'
import { TWO_STAGE_APPROVALS } from '../../../const/formTypeIds'

const AdminTop: React.FC = () => {
  const { loginInfo, bearerToken } = useContext(AdminAuthContext)

  const fetcher = (url: string) =>
    fetch(url, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())

  const { data: memberApplicationResponse } = useSWR<
    ListPaginationResponse<ApplicationType>
  >(API.ADMIN_APPLICATIONS_MEMBER, fetcher)
  const { data: officeApplicationResponse } = useSWR<
    ListPaginationResponse<ApplicationType>
  >(API.ADMIN_APPLICATIONS_OFFICE, fetcher)
  if (
    typeof memberApplicationResponse === 'undefined' ||
    typeof officeApplicationResponse === 'undefined'
  ) {
    return <PageLoader />
  }

  const getApplicationsLength = (type: string, status: string) => {
    let filteredApplications = []

    if (type === 'user') {
      filteredApplications = memberApplicationResponse.items.filter(
        (application) => {
          if (status === 'twoStageApproved1') {
            return (
              application.approvalStatus === 'approved1' &&
              TWO_STAGE_APPROVALS.includes(application.formType)
            )
          } else if (status === 'approved1') {
            return (
              application.approvalStatus === 'approved1' &&
              !TWO_STAGE_APPROVALS.includes(application.formType)
            )
          } else {
            return application.approvalStatus === status
          }
        },
      )
    } else if (type === 'office') {
      filteredApplications = officeApplicationResponse.items.filter(
        (application) => {
          if (status === 'twoStageApproved1') {
            return (
              application.approvalStatus === 'approved1' &&
              TWO_STAGE_APPROVALS.includes(application.formType)
            )
          } else if (status === 'approved1') {
            return (
              application.approvalStatus === 'approved1' &&
              !TWO_STAGE_APPROVALS.includes(application.formType)
            )
          } else {
            return application.approvalStatus === status
          }
        },
      )
    }

    return filteredApplications.length
  }

  const buildQueryParam = (searchStatus: string) => {
    // デフォルトで一年間の日付絞り込みを行う画面仕様を回避するため、明示的に空を指定する
    const defaultParamString = `searchApplyDateFrom=&searchApplyDateTo=`
    return `searchStatus=${searchStatus}&`.concat(defaultParamString)
  }
  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ {loginInfo.admin.name}様
      </Heading>
      <BlankSpace />
      <Heading type="sectionTitle" tag="h2">
        現在ご申請いただいている項目は、下記の通りです。
      </Heading>
      <Wrapper>
        <Heading type="blockTitle" tag="span">
          【会員】
        </Heading>
        <ButtonWrapper>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_MEMBER}?${buildQueryParam(
              'applying',
            )}`}
          >
            <LinkButtonGray>
              1次承認待ち（新規）
              <br />
              {getApplicationsLength('user', 'applying')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_MEMBER}?${buildQueryParam(
              'remanded',
            )}`}
          >
            <LinkButtonGray>
              1次承認待ち（差戻し）
              <br />
              {getApplicationsLength('user', 'remanded')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
        </ButtonWrapper>
        <ButtonWrapper>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_MEMBER}?${buildQueryParam(
              'twoStageApproved1',
            )}`}
          >
            <LinkButtonGray>
              2次承認待ち
              <br />
              {getApplicationsLength('user', 'twoStageApproved1')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_MEMBER}?${buildQueryParam(
              'approved1',
            )}`}
          >
            <LinkButtonGray>
              次長承認待ち
              <br />
              {getApplicationsLength('user', 'approved1')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_MEMBER}?${buildQueryParam(
              'approved2',
            )}`}
          >
            <LinkButtonGray>
              局長承認待ち
              <br />
              {getApplicationsLength('user', 'approved2')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
        </ButtonWrapper>
      </Wrapper>
      <Wrapper>
        <Heading type="blockTitle" tag="span">
          【事業所】
        </Heading>
        <ButtonWrapper>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_OFFICE}?${buildQueryParam(
              'applying',
            )}`}
          >
            <LinkButtonGray>
              1次承認待ち（新規）
              <br />
              {getApplicationsLength('office', 'applying')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_OFFICE}?${buildQueryParam(
              'remanded',
            )}`}
          >
            <LinkButtonGray>
              1次承認待ち（差戻し）
              <br />
              {getApplicationsLength('office', 'remanded')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
        </ButtonWrapper>
        <ButtonWrapper>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_OFFICE}?${buildQueryParam(
              'twoStageApproved1',
            )}`}
          >
            <LinkButtonGray>
              2次承認待ち
              <br />
              {getApplicationsLength('office', 'twoStageApproved1')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_OFFICE}?${buildQueryParam(
              'approved1',
            )}`}
          >
            <LinkButtonGray>
              次長承認待ち
              <br />
              {getApplicationsLength('office', 'approved1')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink
            to={`${PATH.ADMIN_APPLICATIONS_OFFICE}?${buildQueryParam(
              'approved2',
            )}`}
          >
            <LinkButtonGray>
              局長承認待ち
              <br />
              {getApplicationsLength('office', 'approved2')}件
            </LinkButtonGray>
          </ButtonWrapperLink>
        </ButtonWrapper>
      </Wrapper>
    </>
  )
}

const BlankSpace = styled.div`
  height: 24px;
`
const Wrapper = styled.div`
  margin-top: 32px;
  min-width: 1000px;
`
const ButtonWrapper = styled.div`
  margin-top: 32px;
  > :nth-child(n + 2) {
    margin-left: 64px;
  }
`
const LinkButtonGray = styled(Button)`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background: #f1f0ee;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 48px 0;
  width: 272px;

  &:hover {
    background-color: rgba(241, 240, 238, 0.3);
    color: #000;
  }
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
export default AdminTop
