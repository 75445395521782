import React from 'react'
import styled from 'styled-components'

import { ShukuhakuRiyouHojoKinData } from '../../../../types/formData'

import { Heading } from '../../../ui/Heading'
import { Input } from '../../../ui/Input'
import { DatePicker } from '../../../ui/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CONFIG } from '../../../../const'
import {
  BankAccountSection,
  FileSection,
  MemberInfoSection,
  CompanionSection,
} from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { mediaQuery } from '../../../../themes/size'
import {
  isAmountYen,
  isCountNumber,
  isYYYYMMDD,
} from '../../../../util/validation'

type Props = {
  disabled: boolean
  canAdminUpload?: boolean
  handleSaveImage?: (files: File) => Promise<void>
  isUploading?: boolean
  handleDeleteImage?: (fileId: string) => Promise<void>
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const methods = useFormContext<ShukuhakuRiyouHojoKinData['formData']>()
  const { control, errors } = methods

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼給付金申請 - 宿泊利用補助金請求書
        </Heading>
      </HeadingArea>

      {Object.keys(methods.errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■申請情報
        </Heading>
        <InputField>
          <Heading type="blockTitle" tag="span">
            利用施設名
          </Heading>
          <Controller
            control={control}
            name="facilityName"
            rules={{ required: '利用施設名を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.facilityName && (
          <ErrorMessage>{errors.facilityName.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            利用年月日
          </Heading>
          <Controller
            control={control}
            name="useDate"
            rules={{
              required: '利用年月日を入力してください',
              validate: { isYYYYMMDD },
            }}
            render={({ onChange, value, onBlur }) => (
              <DatePicker
                onChange={onChange}
                value={value}
                disabled={props.disabled}
                onBlur={onBlur}
              />
            )}
          />
        </InputField>
        {errors.useDate && (
          <ErrorMessage>{errors.useDate.message}</ErrorMessage>
        )}
        {errors.useDate?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            泊数
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="stayDays"
              rules={{
                required: '泊数を半角数字で入力してください',
                validate: { isCountNumber },
              }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="number"
                  min="0"
                  defaultValue={value}
                  onChange={onChange}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
            <InputLabel>泊</InputLabel>
          </LabelWrapper>
        </InputField>
        {errors.stayDays && (
          <ErrorMessage>{errors.stayDays.message}</ErrorMessage>
        )}
        {errors.stayDays?.type === 'isCountNumber' && (
          <ErrorMessage>半角数字で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            利用人数
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="numOfUsers"
              rules={{
                required: '利用人数を半角数字で入力してください',
                validate: { isCountNumber },
              }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="number"
                  min="0"
                  defaultValue={value}
                  onChange={onChange}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
            <InputLabel>人</InputLabel>
          </LabelWrapper>
        </InputField>
        {errors.numOfUsers && (
          <ErrorMessage>{errors.numOfUsers.message}</ErrorMessage>
        )}
        {errors.numOfUsers?.type === 'isCountNumber' && (
          <ErrorMessage>半角数字で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            請求人数：会員
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="numOfBunkinMembers"
              rules={{
                required: '請求人数：会員を入力してください',
                validate: { isCountNumber },
              }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="number"
                  min="0"
                  defaultValue={value}
                  onChange={onChange}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
            <InputLabel>人</InputLabel>
          </LabelWrapper>
        </InputField>
        {errors.numOfBunkinMembers && (
          <ErrorMessage>{errors.numOfBunkinMembers.message}</ErrorMessage>
        )}
        {errors.numOfBunkinMembers?.type === 'isCountNumber' && (
          <ErrorMessage>半角数字で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            請求人数：家族
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="numOfFamilyUsers"
              rules={{
                required: '請求人数：家族を入力してください',
                validate: { isCountNumber },
              }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="number"
                  min="0"
                  defaultValue={value}
                  onChange={onChange}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
            <InputLabel>人</InputLabel>
          </LabelWrapper>
        </InputField>
        {errors.numOfFamilyUsers && (
          <ErrorMessage>{errors.numOfFamilyUsers.message}</ErrorMessage>
        )}
        {errors.numOfFamilyUsers?.type === 'isCountNumber' && (
          <ErrorMessage>半角数字で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            請求金額(半角数字)
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="amount"
              rules={{
                required: '請求金額を入力してください',
                validate: { isAmountYen },
              }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="text"
                  onChange={onChange}
                  value={value}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
            <InputLabel>円</InputLabel>
          </LabelWrapper>
        </InputField>
        {errors.amount && <ErrorMessage>{errors.amount.message}</ErrorMessage>}
        {errors.amount?.type === 'isAmountYen' && (
          <ErrorMessage>半角数字で入力してください</ErrorMessage>
        )}
        <LinkHeading type="sectionTitle" tag="h2">
          ※宿泊施設利用補助金の利用方法や計算方法等は
          <AnchorLink href={CONFIG.SHUKUHAKU_SHOSAI_URL} target="_blank">
            こちら
          </AnchorLink>
          をご確認ください。
        </LinkHeading>
      </Section>

      <CompanionSection disabled={props.disabled} />

      <FileSection
        disabled={props.disabled}
        canAdminUpload={props.canAdminUpload}
        handleSaveImage={props.handleSaveImage}
        isUploading={props.isUploading}
        handleDeleteImage={props.handleDeleteImage}
      />

      <BankAccountSection disabled={props.disabled} isMember={true} />

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`

const Section = styled.section`
  margin-top: 72px;
`
const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const InputLabel = styled.label`
  margin-left: 8px;
  font-size: 16px;
`
const LinkHeading = styled(Heading)`
  margin-top: 32px;
`
const AnchorLink = styled.a`
  color: #439a89;
  &:hover {
    text-decoration: none;
  }
`

export default Component
