import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import styled from 'styled-components'

export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode
  className?: string
  disabled?: boolean
}

export const Button: FC<Props> = ({
  className = '',
  children,
  onClick,
  disabled,
}) => {
  return (
    <Wrapper className={className} onClick={onClick} disabled={disabled}>
      {children}
    </Wrapper>
  )
}

// TODO: 共通にできるスタイルはテーマに持たせたい

const Wrapper = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 2rem;
  vertical-align: middle;
  font-weight: bold;
  font-size: 13px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  background: #439a89;
  border-radius: 27.5px;
  transition: all 0.3s ease-out 0s;
  height: 45px;
  padding: 0px 46px;
  border: none;

  &:hover {
    background-color: rgba(67, 154, 137, 0.5);
    color: rgb(255, 255, 255);
  }

  :disabled {
    background: rgb(190, 190, 190);
    cursor: default;
  }
`
