import React from 'react'
import styled from 'styled-components'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import OfficeMembers from '../../../../../types/OfficeMembers'
import { Heading } from '../../../../ui/Heading'
import { Input } from '../../../../ui/Input'
import { Table, Body, Head, Row, Cell } from '../../../../ui/Table'
import { DatePicker } from '../../../../ui/DatePicker'
import { Select } from '../../../../ui/Select'
import { WithdrawalMember } from '../../../../../types/formData'
import { mediaQuery } from '../../../../../themes/size'
import { isSp } from 'libs/ua'
import {
  isOfficeMemberNumber,
  isYYYYMMDD,
} from '../../../../../util/validation'

type Props = {
  officeMembers: OfficeMembers
  disabled: boolean
}

const initData: WithdrawalMember[] = [
  {
    memberId: '',
    name: '',
    nameKana: '',
    withdrawalDate: null,
    withdrawalReason: '退職',
  },
]

export const WithdrawalMembersSection: React.FC<Props> = (props) => {
  const { control, errors, reset, setValue, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'WithdrawalMembers',
  })

  const repository = watch('WithdrawalMembers')
  React.useEffect(() => {
    reset({
      WithdrawalMembers: repository.length > 0 ? repository : initData,
    })
    // eslint-disable-next-line
  }, [reset])

  const handleChangeMemberId = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const inputMemberId = e.currentTarget.value
    setValue(`WithdrawalMembers[${index}].memberId`, inputMemberId)

    let isMatch = false
    props.officeMembers.forEach((officeMember) => {
      // 退会済みのユーザは除外
      if (officeMember.withdrawalReason) {
        return
      }
      const officeMemberId = officeMember.memberId.split('-')[1]

      if (officeMemberId === inputMemberId) {
        setValue(`WithdrawalMembers[${index}].name`, officeMember.name)
        setValue(`WithdrawalMembers[${index}].nameKana`, officeMember.nameKana)
        isMatch = true
      }
    })

    // 個人番号に該当する会員がいない時は空値をセット(リセット)
    if (!isMatch) {
      setValue(`WithdrawalMembers[${index}].name`, '')
      setValue(`WithdrawalMembers[${index}].nameKana`, '')
    }
  }

  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■一部（会員）退会
      </Heading>
      <Wrapper>
        <Table>
          <TableHead>
            <TableHeadRow>
              <Cell>個人番号(半角数字3桁)</Cell>
              <Cell>氏名</Cell>
              <Cell>フリガナ</Cell>
              <Cell>退会日</Cell>
              <Cell>退会理由</Cell>
              <Cell></Cell>
            </TableHeadRow>
          </TableHead>
          <Body>
            {fields.map((field, index) => (
              <Row key={field.id}>
                {isSp && index >= 1 && <Border />}
                <TdCell dataLabel={'個人番号'}>
                  <Controller
                    control={control}
                    name={`WithdrawalMembers[${index}].memberId`}
                    defaultValue={field.memberId}
                    rules={{
                      required: '個人番号を入力してください',
                      validate: { isOfficeMemberNumber },
                    }}
                    render={({ value, onBlur }) => (
                      <TableInput
                        type="text"
                        maxLength={3}
                        onChange={(e) => handleChangeMemberId(e, index)}
                        value={value}
                        disabled={props.disabled}
                        onBlurCapture={onBlur}
                      />
                    )}
                  />
                  {errors?.['WithdrawalMembers']?.[index]?.['memberId'] && (
                    <ErrorMessage>
                      {
                        errors?.['WithdrawalMembers']?.[index]?.['memberId']?.[
                          'message'
                        ]
                      }
                    </ErrorMessage>
                  )}
                  {errors?.['WithdrawalMembers']?.[index]?.['memberId']
                    ?.type === 'isOfficeMemberNumber' && (
                    <ErrorMessage>半角数字3桁で入力して下さい</ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'氏名'}>
                  <Controller
                    control={control}
                    name={`WithdrawalMembers[${index}].name`}
                    defaultValue={field.name}
                    rules={{ required: '氏名を入力してください' }}
                    render={({ onChange, value }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled
                      />
                    )}
                  />
                  {errors?.['WithdrawalMembers']?.[index]?.['name'] && (
                    <ErrorMessage>
                      {
                        errors?.['WithdrawalMembers']?.[index]?.['name']?.[
                          'message'
                        ]
                      }
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'フリガナ'}>
                  <Controller
                    control={control}
                    name={`WithdrawalMembers[${index}].nameKana`}
                    defaultValue={field.nameKana}
                    rules={{ required: 'フリガナを入力してください' }}
                    render={({ onChange, value }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled
                      />
                    )}
                  />
                  {errors?.['WithdrawalMembers']?.[index]?.['nameKana'] && (
                    <ErrorMessage>
                      {
                        errors?.['WithdrawalMembers']?.[index]?.['nameKana']?.[
                          'message'
                        ]
                      }
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'退会日'}>
                  <Controller
                    control={control}
                    name={`WithdrawalMembers[${index}].withdrawalDate`}
                    defaultValue={field.withdrawalDate}
                    rules={{
                      required: '退会日を入力してください',
                      validate: { isYYYYMMDD },
                    }}
                    render={({ onChange, value }) => (
                      <DatePickerWrapper>
                        <DatePicker
                          onChange={onChange}
                          value={value}
                          withSuffix={true}
                          disabled={props.disabled}
                          maxDate={new Date('2040/01/01')} // 未来を指定できるようにするためDatePicker側の仕様として設定している上限を便宜的に指定する
                        />
                      </DatePickerWrapper>
                    )}
                  />
                  {errors?.['WithdrawalMembers']?.[index]?.[
                    'withdrawalDate'
                  ] && (
                    <ErrorMessage>
                      {
                        errors?.['WithdrawalMembers']?.[index]?.[
                          'withdrawalDate'
                        ]?.['message']
                      }
                    </ErrorMessage>
                  )}
                  {errors?.['WithdrawalMembers']?.[index]?.['withdrawalDate']
                    ?.type === 'isYYYYMMDD' && (
                    <ErrorMessage>
                      YYYY/MM/DDの形式で入力して下さい
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'退会理由'}>
                  <Controller
                    control={control}
                    name={`WithdrawalMembers[${index}].withdrawalReason`}
                    defaultValue={field.withdrawalReason}
                    render={({ onChange, value }) => (
                      <TableSelect
                        value={value}
                        options={[
                          { label: '退職', value: 'retirement' },
                          { label: '死亡', value: 'death' },
                          { label: '人事異動', value: 'personnelChange' },
                          { label: 'その他', value: 'other' },
                        ]}
                        onChange={onChange}
                        disabled={props.disabled}
                      />
                    )}
                  />
                </TdCell>
                {!isSp && <TdCell></TdCell>}
                <TdCell>
                  {!props.disabled && (
                    <DeleteButton
                      type="button"
                      onClick={() => remove(index)}
                      disabled={fields.length === 1}
                    >
                      削除
                    </DeleteButton>
                  )}
                </TdCell>
              </Row>
            ))}
          </Body>
        </Table>
        {!props.disabled && (
          <RowWrapper>
            <AddButtonWrapper>
              <AddButton
                type="button"
                onClick={() => {
                  append({})
                }}
                disabled={props.disabled}
              >
                <label htmlFor={'＋退会者を追加する'}>
                  <Prefix></Prefix>
                  ＋退会者を追加する
                </label>
              </AddButton>
            </AddButtonWrapper>
          </RowWrapper>
        )}
      </Wrapper>
    </Section>
  )
}

const Wrapper = styled.div`
  display: block;
  margin-top: 32px;
`
export const Section = styled.section`
  margin-top: 72px;
`
const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`
const TableInput = styled(Input)`
  > input[type='text'] {
    width: 224px;
  }
`
const DatePickerWrapper = styled.div`
  input[type='text'] {
    width: 224px;

    @media screen and (max-width: ${mediaQuery.SP}px) {
      width: 100%;
    }
  }
`
const AddButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 450px;
  margin: 0 auto;
  text-align: center;
  > input[type='file'] {
    position: absolute;
    height: 100%;
    left: -10px;
    top: 0;
    margin: 0;
    font-size: 128px !important;
    opacity: 0;
    appearance: none;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  &:hover {
    > button {
      background-color: rgba(67, 154, 137, 0.5);
      color: rgb(255, 255, 255);
    }
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    &:hover {
      > button {
        background-color: #fff;
        color: #439a89;
      }
    }

    &:active {
      > button {
        background-color: rgba(67, 154, 137, 0.5);
        color: rgb(255, 255, 255);
      }
    }
  }
`
const Prefix = styled.span`
  display: inline-flex;
`
const DeleteButton = styled.button`
  margin-left: 8px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  padding: 0 16px;
  height: 45px;
  transition: all 0.3s ease-out 0s;
  &:hover {
    background-color: rgb(242, 242, 242);
  }
`
const AddButton = styled.button`
  font-family: inherit;
  font-weight: bold;
  border-radius: 27.5px;
  color: #439a89;
  background-color: #fff;
  border: solid 1px #439a89;
  font-size: 16px;
  height: 40px;
  padding: 0 46px;
  transition: all 0.3s ease-out 0s;
  > label {
    display: flex;
    align-items: center;
  }
  &.prefix {
    justify-content: left;
  }
`
const TableSelect = styled(Select)`
  width: 224px;
  height: 57px;
  flex-grow: 1;
  display: inline-block;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  font-size: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
`
const TableHead = styled(Head)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: none;
  }
`
const TableHeadRow = styled(Row)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
const TdCell = styled(Cell)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    text-align: right;
    width: 100%;

    &:before {
      content: attr(data-label);
      float: left;
      margin-right: 10px;
    }
  }
`
const Border = styled.hr`
  margin-bottom: 32px;
`
