import React, { useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { ModalContentInner, ModalContentInnerProps } from './ModalContentInner'
import {
  MessageModalContentInner,
  MessageModalContentInnerProps,
} from './MessageModalContentInner'

type Props = MessageModalContentInnerProps &
  Pick<
    ModalContentInnerProps,
    | 'isOpen'
    | 'onClickOverlay'
    | 'onPressEscape'
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'id'
  >

export const MessageModal: React.VFC<Props> = ({
  title,
  description,
  closeText,
  onClickClose,
  isCloseButtonDisabled,
  onClickCancelButton,
  ...props
}) => {
  const element = useRef(document.createElement('div')).current

  useEffect(() => {
    document.body.appendChild(element)

    return () => {
      document.body.removeChild(element)
    }
  }, [element])

  const handleClickClose = useCallback(() => {
    if (!props.isOpen) {
      return
    }
    onClickClose()
  }, [onClickClose, props.isOpen])

  return createPortal(
    <ModalContentInner ariaLabel={title} {...props}>
      <MessageModalContentInner
        title={title}
        description={description}
        closeText={closeText}
        onClickClose={handleClickClose}
        isCloseButtonDisabled={isCloseButtonDisabled}
        onClickCancelButton={onClickCancelButton}
      />
    </ModalContentInner>,
    element,
  )
}
