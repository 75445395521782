import React, {
  InputHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import styled from 'styled-components'
import { mediaQuery } from 'themes/size'

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> & {
  type?: 'text' | 'date' | 'number' | 'checkbox' | 'password' | 'email'
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ onFocus, onBlur, autoFocus, className, width, ...props }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null)

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => innerRef.current,
    )

    return (
      <Wrapper className={className}>
        <StyledInput {...props} ref={innerRef} />
      </Wrapper>
    )
  },
)

// TODO: 共通にできるスタイルはテーマに持たせたい

const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: stretch;
  background-color: #fff;
  box-sizing: border-box;
  cursor: text;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`

const StyledInput = styled.input`
  flex-grow: 1;
  display: inline-block;
  width: 328px;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  font-size: 16px;
  :disabled {
    background: rgb(190, 190, 190);
  }

  &::placeholder {
    font-size: 16px;
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
