import useSWR from 'swr'
import styled from 'styled-components'
import OfficeMembers from '../../../../types/OfficeMembers'
import { API } from '../../../../const'
import React, { useContext } from 'react'
import { OfficeAuthContext } from '../../../../auth/OfficeAuthProvider'
import { PageLoader } from '../../../ui/Loader'
import { Heading } from '../../../ui/Heading'
import {
  OfficeInfoSection,
  ApplicantSection,
  WithdrawalMembersSection,
} from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { useFormContext } from 'react-hook-form'

type Props = {
  disabled: boolean
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const { errors } = useFormContext()

  const { loginInfo, bearerToken } = useContext(OfficeAuthContext)
  // admin から開いた際にAPIを投げないようにする
  let apiPath = 'dummy'
  let fetcher: () => Promise<[]> = () => new Promise((resolve) => resolve([]))
  if (loginInfo.uid) {
    apiPath = API.OFFICE_MEMBERS.replace(':officeId', loginInfo.office.officeId)
    fetcher = () =>
      fetch(apiPath, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        mode: 'cors',
      }).then((res) => res.json())
  }
  const { data: officeMembers } = useSWR<OfficeMembers>(apiPath, fetcher)
  if (typeof officeMembers === 'undefined') {
    return <PageLoader />
  }

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼一部会員退会届
        </Heading>
      </HeadingArea>

      {Object.keys(errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <OfficeInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <ApplicantSection disabled={props.disabled} />

      <WithdrawalMembersSection
        officeMembers={officeMembers}
        disabled={props.disabled}
      />

      <Paragraph>
        ※会費の前納分があるときは、次回の会費請求時に還付金として請求額から差し引きます。
        <br />
        　（退会届の提出が遅れたときの会費の返還は、届出月を含めて3ヶ月まで遡及できす。）
        <br />
        ※会員証をお返しください。（紛失の場合は見つかり次第返還願います）
        <br />
        ※事業所退会の場合は、共済会までご連絡ください。（03-5803-1108）
      </Paragraph>

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
export const Section = styled.section`
  margin-top: 72px;
`

const Paragraph = styled.p`
  margin-top: 88px;
  font-size: 14px;
`
const ErrorMessage = styled.div`
  color: red;
`

export default Component
