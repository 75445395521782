import React from 'react'
import { Heading } from '../../../../ui/Heading'
import { Input } from '../../../../ui/Input'
import { InputField, Section } from '../ShougaiMimaiKin'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ParamType } from '../../Create'
import { FORM_TYPE_IDS } from '../../../../../const/formTypeIds'
import { PATH } from '../../../../../const'

type Props = {
  shouldShowOtherFormLinks?: true
}

export const MemberInfoSection: React.FC<Props> = (props) => {
  const { formType } = useParams<ParamType>()

  const { control } = useFormContext()
  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■会員情報
      </Heading>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員番号
        </Heading>
        <Controller
          control={control}
          name="applicant.id"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所名
        </Heading>
        <Controller
          control={control}
          name="applicant.officeName"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所フリガナ
        </Heading>
        <Controller
          control={control}
          name="applicant.officeNameKana"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員氏名
        </Heading>
        <Controller
          control={control}
          name="applicant.name"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
        </Heading>
        <Controller
          control={control}
          name="applicant.nameKana"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員郵便番号
        </Heading>
        <Controller
          control={control}
          name="applicant.postalCode"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員住所
        </Heading>
        <Controller
          control={control}
          name="applicant.address"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員電話番号
        </Heading>
        <Controller
          control={control}
          name="applicant.tel"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      {props.shouldShowOtherFormLinks &&
        formType !== FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE &&
        formType !== FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE && (
          <Heading type="sectionTitle" tag="h2">
            ※会員情報を変更する場合は
            <Link
              href={`${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE}`}
              target="_blank"
            >
              こちら
            </Link>
          </Heading>
        )}
      <BlankSpace />
      <Heading type="sectionTitle" tag="h2">
        ■メールアドレス
      </Heading>
      <InputField>
        <Heading type="blockTitle" tag="span">
          メールアドレス
        </Heading>
        <Controller
          control={control}
          name="applicant.email"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      {props.shouldShowOtherFormLinks && (
        <Heading type="sectionTitle" tag="h2">
          ※メールアドレスを変更する場合は
          <Link href={`${PATH.ACCOUNT}/password/edit`} target="_blank">
            こちら
          </Link>
        </Heading>
      )}
    </Section>
  )
}

const Link = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`
const BlankSpace = styled.div`
  height: 72px;
`
