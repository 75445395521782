import React, { useContext, useState, useEffect } from 'react'
import { Heading } from '../../ui/Heading'
import { Body, Cell, Head, Row, Table } from '../../ui/Table'
import { Button } from '../../ui/Button'
import { Input } from '../../ui/Input'
import { DatePicker } from '../../ui/DatePicker'
import { Select } from '../../ui/Select'
import {
  toYYYYMMDDHHmm,
  toYYYYMMDD,
  getDate,
  dateStringify,
} from '../../../presenter/dateUtil'
import { getCategoryAndForm } from '../../../presenter/categorisedForms'
import { getAdminApprovalStatusLabel } from '../../../presenter/getApprovalStatusLabel'
import { useLocation, useHistory } from 'react-router-dom'
import { API, PATH } from '../../../const'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'
import { AdminApplication as AdminApplicationType } from '../../../types/application'
import { PageLoader } from '../../ui/Loader'
import { CheckBox } from '../../ui/CheckBox'
import { ListPaginationResponse } from '../../../types/responseBody/ListPaginationResponse'
import queryString from 'query-string'
import { ApprovalHistory } from '../../../types/application'
import { TWO_STAGE_APPROVALS } from '../../../const/formTypeIds'
import { CsvDownloadSection } from './components'
import * as querystring from 'querystring'
import { FormType } from '../../../types/formType'
import useParamGetter from '../../../hooks/useParamGetter'
import { CloseIcon, OpenIcon } from '../../ui/Icon'

const getAcceptedDate = (approvalHistories: ApprovalHistory[]) => {
  let result: string = ''

  const histories = approvalHistories ?? []
  histories.forEach((approvalHistory) => {
    if (approvalHistory.approvedStatus === 'accepted') {
      result = toYYYYMMDDHHmm(approvalHistory.date.toString())
    }
  })

  return result
}

const getApplicantValue = (formData: any, targetKeyName: string) => {
  let targetValue

  for (const key in formData) {
    if (key === 'applicant') {
      const applicant = formData[key]
      targetValue = applicant[targetKeyName]
    }
  }

  return targetValue
}

const getBankAccountValue = (formData: any, targetKeyName: string) => {
  let targetValue

  for (const key in formData) {
    if (key === 'bankAccount') {
      const applicant = formData[key]
      targetValue = applicant[targetKeyName]
    }
  }

  return targetValue
}

const getBankAccountTypeName = (typeValue: string) => {
  switch (typeValue) {
    case 'normal':
      return '普通'
    case 'current':
      return '当座'
    default:
      return ''
  }
}

const ListMember: React.FC = () => {
  const { bearerToken } = useContext(AdminAuthContext)
  const { reset } = useForm()
  const history = useHistory()

  const location = useLocation()
  const param = queryString.parseUrl(location.search).query

  const paramGetter = useParamGetter(param)

  // 絞り込み検索用State
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const handleClickSearchOpen = () => {
    setIsSearchOpen(!isSearchOpen)
  }
  const [searchId, setSearchId] = useState(paramGetter.string('searchId'))
  const handleChangeSearchId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchId(e.target.value)
  }
  const [searchStatus, setSearchStatus] = useState(
    paramGetter.string('searchStatus'),
  )
  const handleChangeSearchStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSearchStatus(e.target.value)
  }
  const [searchApplyDateFrom, setSearchApplyDateFrom] = useState<Date | null>(
    paramGetter.date('searchApplyDateFrom', getDate.yearAgo()),
  )
  const handleChangeSearchApplyDateFrom = (date: Date | null) => {
    setSearchApplyDateFrom(date)
  }
  const [searchApplyDateTo, setSearchApplyDateTo] = useState<Date | null>(
    paramGetter.date('searchApplyDateTo', getDate.today()),
  )
  const handleChangeSearchApplyDateTo = (date: Date | null) => {
    setSearchApplyDateTo(date)
  }
  const [
    searchApproveDateFrom,
    setSearchApproveDateFrom,
  ] = useState<Date | null>(paramGetter.date('searchApproveDateFrom'))
  const handleChangeSearchApproveDateFrom = (date: Date | null) => {
    setSearchApproveDateFrom(date)
  }
  const [searchApproveDateTo, setSearchApproveDateTo] = useState<Date | null>(
    paramGetter.date('searchApproveDateTo'),
  )
  const handleChangeSearchApproveDateTo = (date: Date | null) => {
    setSearchApproveDateTo(date)
  }
  const [searchOfficeId, setSearchOfficeId] = useState(
    paramGetter.string('searchOfficeId'),
  )
  const handleChangeSearchOfficeId = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeId(e.target.value)
  }
  const [searchOfficeName, setSearchOfficeName] = useState(
    paramGetter.string('searchOfficeName'),
  )
  const handleChangeSearchOfficeName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeName(e.target.value)
  }
  const [searchOfficeNameKana, setSearchOfficeNameKana] = useState(
    paramGetter.string('searchOfficeNameKana'),
  )
  const handleChangeSearchOfficeNameKana = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeNameKana(e.target.value)
  }
  const [searchMemberId, setSearchMemberId] = useState(
    paramGetter.string('searchMemberId'),
  )
  const handleChangeSearchMemberId = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchMemberId(e.target.value)
  }
  const [searchMemberName, setSearchMemberName] = useState(
    paramGetter.string('searchMemberName'),
  )
  const handleChangeSearchMemberName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchMemberName(e.target.value)
  }
  const [searchMemberNameKana, setSearchMemberNameKana] = useState(
    paramGetter.string('searchMemberNameKana'),
  )
  const handleChangeSearchMemberNameKana = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchMemberNameKana(e.target.value)
  }
  const [searchMemberEmail, setSearchMemberEmail] = useState(
    paramGetter.string('searchMemberEmail'),
  )
  const handleChangeSearchMemberEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchMemberEmail(e.target.value)
  }

  const [searchFormTypeList, setSearchFormTypeList] = useState<Set<FormType>>(
    new Set(paramGetter.array('searchFormTypeList') as FormType[]),
  )
  const handleChangeSearchFormTypeList = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const targetType = e.target.id as FormType
    if (e.target.checked) {
      searchFormTypeList.add(targetType)
    } else {
      searchFormTypeList.delete(targetType)
    }
    setSearchFormTypeList(new Set(searchFormTypeList))
  }

  const [filtered, setFiltered] = useState<AdminApplicationType[] | null>(null)

  const fetcher = (url: string) =>
    fetch(url, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())
  const { data } = useSWR<ListPaginationResponse<AdminApplicationType>>(
    API.ADMIN_APPLICATIONS_MEMBER,
    fetcher,
  )
  const loading = typeof data === 'undefined'
  useEffect(() => {
    if (!loading && data) {
      search()
    }
    // eslint-disable-next-line
  }, [loading])
  if (typeof data === 'undefined') {
    return <PageLoader />
  }
  const { items: memberAllApplications } = data

  const search = () => {
    let searchApplications = memberAllApplications

    if (searchStatus) {
      searchApplications = searchApplications.filter((application) => {
        // TODO: 2次承認待ちと次長承認待ちのステータスvalueが同じための暫定対応、あとで直したほうがよい
        if (searchStatus === 'twoStageApproved1') {
          return (
            application.approvalStatus === 'approved1' &&
            TWO_STAGE_APPROVALS.includes(application.formType)
          )
        } else if (searchStatus === 'approved1') {
          return (
            application.approvalStatus === 'approved1' &&
            !TWO_STAGE_APPROVALS.includes(application.formType)
          )
        } else {
          return application.approvalStatus === searchStatus
        }
      })
    }
    if (searchApplyDateFrom && searchApplyDateTo) {
      searchApplications = searchApplications.filter((application) => {
        return (
          searchApplyDateFrom.getTime() <=
            new Date(toYYYYMMDD(application.createdAt)).getTime() &&
          new Date(toYYYYMMDD(application.createdAt)).getTime() <=
            searchApplyDateTo.getTime()
        )
      })
    } else if (searchApplyDateFrom) {
      searchApplications = searchApplications.filter((application) => {
        return (
          searchApplyDateFrom.getTime() <=
          new Date(toYYYYMMDD(application.createdAt)).getTime()
        )
      })
    } else if (searchApplyDateTo) {
      searchApplications = searchApplications.filter((application) => {
        return (
          new Date(toYYYYMMDD(application.createdAt)).getTime() <=
          searchApplyDateTo.getTime()
        )
      })
    }
    if (searchApproveDateFrom && searchApproveDateTo) {
      searchApplications = searchApplications.filter((application) => {
        return (
          searchApproveDateFrom.getTime() <=
            new Date(
              toYYYYMMDD(getAcceptedDate(application.approvalHistories)),
            ).getTime() &&
          new Date(
            toYYYYMMDD(getAcceptedDate(application.approvalHistories)),
          ).getTime() <= searchApproveDateTo.getTime()
        )
      })
    } else if (searchApproveDateFrom) {
      searchApplications = searchApplications.filter((application) => {
        return (
          searchApproveDateFrom.getTime() <=
          new Date(
            toYYYYMMDD(getAcceptedDate(application.approvalHistories)),
          ).getTime()
        )
      })
    } else if (searchApproveDateTo) {
      searchApplications = searchApplications.filter((application) => {
        return (
          new Date(
            toYYYYMMDD(getAcceptedDate(application.approvalHistories)),
          ).getTime() <= searchApproveDateTo.getTime()
        )
      })
    }
    if (searchId) {
      const idReg = new RegExp(searchId, 'g')
      searchApplications = searchApplications.filter((application) =>
        application.id.match(idReg),
      )
    }
    if (searchOfficeId) {
      const idReg = new RegExp(searchOfficeId, 'g')
      searchApplications = searchApplications.filter((application) =>
        application.applicantId.split('-')[0].match(idReg),
      )
    }
    if (searchOfficeName) {
      const idReg = new RegExp(searchOfficeName, 'g')
      searchApplications = searchApplications.filter((application) => {
        const officeName: string = getApplicantValue(
          application.formData,
          'officeName',
        )

        if (officeName) {
          return officeName.match(idReg)
        } else {
          return false
        }
      })
    }
    if (searchOfficeNameKana) {
      const idReg = new RegExp(searchOfficeNameKana, 'g')
      searchApplications = searchApplications.filter((application) => {
        const officeNameKana: string = getApplicantValue(
          application.formData,
          'officeNameKana',
        )

        if (officeNameKana) {
          return officeNameKana.match(idReg)
        } else {
          return false
        }
      })
    }
    if (searchMemberId) {
      const idReg = new RegExp(searchMemberId, 'g')
      searchApplications = searchApplications.filter((application) => {
        const memberId = application.applicantId.split('-')[1]

        if (memberId) {
          return memberId.match(idReg)
        } else {
          return false
        }
      })
    }
    if (searchMemberName) {
      const idReg = new RegExp(searchMemberName, 'g')
      searchApplications = searchApplications.filter((application) => {
        const memberName: string = getApplicantValue(
          application.formData,
          'name',
        )

        if (memberName) {
          return memberName.match(idReg)
        } else {
          return false
        }
      })
    }
    if (searchMemberNameKana) {
      const idReg = new RegExp(searchMemberNameKana, 'g')
      searchApplications = searchApplications.filter((application) => {
        const memberNameKana: string = getApplicantValue(
          application.formData,
          'nameKana',
        )

        if (memberNameKana) {
          return memberNameKana.match(idReg)
        } else {
          return false
        }
      })
    }
    if (searchMemberEmail) {
      const idReg = new RegExp(searchMemberEmail, 'g')
      searchApplications = searchApplications.filter((application) => {
        const memberEmail: string = getApplicantValue(
          application.formData,
          'email',
        )

        if (memberEmail) {
          return memberEmail.match(idReg)
        } else {
          return false
        }
      })
    }
    // 申請種別絞り込みは一つもチェックがない場合はfilterをかけない（ == 全種別を対象とする）
    if (searchFormTypeList.size > 0) {
      // 一つでもチェックが入っていればfilterする
      searchApplications = searchApplications.filter((application) => {
        return searchFormTypeList.has(application.formType)
      })
    }

    const MAX_LENGTH = 1000
    const isTooMany = (filtered: any[]) => filtered.length > MAX_LENGTH
    if (isTooMany(searchApplications)) {
      setFiltered(null)
      return alert(
        `${searchApplications.length}件ヒットしました。検索結果が多すぎるため表示できません。条件を絞ってください`,
      )
    }

    setFiltered(searchApplications)
  }

  // 絞り込み検索
  const handleClickSearch = () => {
    search()
    const queryParams = {
      searchStatus,
      searchApplyDateFrom: dateStringify(searchApplyDateFrom),
      searchApplyDateTo: dateStringify(searchApplyDateTo),
      searchApproveDateFrom: dateStringify(searchApproveDateFrom),
      searchApproveDateTo: dateStringify(searchApproveDateTo),
      searchOfficeId,
      searchOfficeName,
      searchOfficeNameKana,
      searchMemberId,
      searchMemberName,
      searchMemberNameKana,
      searchMemberEmail,
      searchId,
      searchFormTypeList: Array.from(searchFormTypeList),
    }
    // @ts-ignore
    history.replace(
      `${history.location.pathname}?${querystring.stringify(queryParams)}`,
    )
  }

  const handleClickSearchReset = () => {
    setSearchStatus('')
    setSearchApplyDateFrom(getDate.yearAgo())
    setSearchApplyDateTo(getDate.today())
    setSearchApproveDateFrom(null)
    setSearchApproveDateTo(null)
    setSearchOfficeId('')
    setSearchOfficeName('')
    setSearchOfficeNameKana('')
    setSearchMemberId('')
    setSearchMemberName('')
    setSearchMemberNameKana('')
    setSearchMemberEmail('')
    setSearchId('')
    setSearchFormTypeList(new Set())

    reset({
      searchStatus: searchStatus,
      searchApplyDateFrom: searchApplyDateFrom,
      searchApplyDateTo: searchApplyDateTo,
      searchApproveDateFrom: searchApproveDateFrom,
      searchApproveDateTo: searchApproveDateTo,
      searchOfficeId: searchOfficeId,
      searchOfficeName: searchOfficeName,
      searchOfficeNameKana: searchOfficeNameKana,
      searchMemberId: searchMemberId,
      searchMemberName: searchMemberName,
      searchMemberNameKana: searchMemberNameKana,
      searchMemberEmail: searchMemberEmail,
      searchId: searchId,
      searchFormTypeList: searchFormTypeList,
    })

    // search() を呼びたいところだが、stateの更新をしたばかりで、更新後のstateを参照できないため、一部search()内のロジックを持ってきた
    setFiltered(
      memberAllApplications.filter((application) => {
        return (
          getDate.yearAgo().getTime() <=
            new Date(toYYYYMMDD(application.createdAt)).getTime() &&
          new Date(toYYYYMMDD(application.createdAt)).getTime() <=
            getDate.today().getTime()
        )
      }),
    )
    history.replace(history.location.pathname)
  }

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ 会員申請一覧
      </Heading>
      <ButtonWrapper>
        <WhiteButton onClick={handleClickSearchOpen}>
          絞り込み検索
          {!isSearchOpen ? <CloseIcon /> : <OpenIcon />}
        </WhiteButton>
      </ButtonWrapper>

      {isSearchOpen && (
        <>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                申請日From
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchApplyDateFrom"
                  value={searchApplyDateFrom}
                  onChange={handleChangeSearchApplyDateFrom}
                />
              </DatePickerWrapper>
            </InputField>
            ～
            <InputFieldMarginLeft>
              <Heading type="blockTitle" tag="span">
                申請日To
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchApplyDateTo"
                  value={searchApplyDateTo}
                  onChange={(e) => handleChangeSearchApplyDateTo(e)}
                />
              </DatePickerWrapper>
            </InputFieldMarginLeft>
            <InputField>
              <Heading type="blockTitle" tag="span">
                申請ID
              </Heading>
              <Input
                type="text"
                name="searchId"
                value={searchId}
                onChange={(e) => handleChangeSearchId(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                承認日From
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchApproveDateFrom"
                  value={searchApproveDateFrom}
                  onChange={(e) => handleChangeSearchApproveDateFrom(e)}
                />
              </DatePickerWrapper>
            </InputField>
            ～
            <InputFieldMarginLeft>
              <Heading type="blockTitle" tag="span">
                承認日To
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchApproveDateTo"
                  value={searchApproveDateTo}
                  onChange={(e) => handleChangeSearchApproveDateTo(e)}
                />
              </DatePickerWrapper>
            </InputFieldMarginLeft>
            <InputField>
              <Heading type="blockTitle" tag="span">
                ステータス
              </Heading>
              <SelectBox
                name="searchStatus"
                value={searchStatus}
                options={[
                  { label: '', value: '' },
                  { label: '1次承認待ち', value: 'applying' },
                  { label: '2次承認待ち', value: 'twoStageApproved1' },
                  { label: '次長承認待ち', value: 'approved1' },
                  { label: '局長承認待ち', value: 'approved2' },
                  { label: '差戻し', value: 'remanded' },
                  { label: '取消', value: 'rejected' },
                  { label: '承認済み', value: 'accepted' },
                ]}
                onChange={(e) => handleChangeSearchStatus(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所番号
              </Heading>
              <SmallInput
                type="text"
                name="searchOfficeId"
                value={searchOfficeId}
                onChange={(e) => handleChangeSearchOfficeId(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所名
              </Heading>
              <Input
                type="text"
                name="searchOfficeName"
                value={searchOfficeName}
                onChange={(e) => handleChangeSearchOfficeName(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所フリガナ
              </Heading>
              <Input
                type="text"
                name="searchOfficeNameKane"
                value={searchOfficeNameKana}
                onChange={(e) => handleChangeSearchOfficeNameKana(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                個人番号
              </Heading>
              <SmallInput
                type="text"
                name="searchMemberId"
                value={searchMemberId}
                onChange={(e) => handleChangeSearchMemberId(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                会員氏名
              </Heading>
              <MediumInput
                type="text"
                name="searchMemberName"
                value={searchMemberName}
                onChange={(e) => handleChangeSearchMemberName(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                会員フリガナ
              </Heading>
              <MediumInput
                type="text"
                name="searchMemberNameKana"
                value={searchMemberNameKana}
                onChange={(e) => handleChangeSearchMemberNameKana(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                メールアドレス
              </Heading>
              <Input
                type="text"
                name="searchMemberEmail"
                value={searchMemberEmail}
                onChange={(e) => handleChangeSearchMemberEmail(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <Heading type="blockTitle" tag="span">
              申請種別
            </Heading>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="shibouchouikin"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('shibouchouikin')}
              />
              <CheckBoxLabel htmlFor="shibouchouikin">死亡弔慰金</CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="shougaimimaikin"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('shougaimimaikin')}
              />
              <CheckBoxLabel htmlFor="shougaimimaikin">
                障害見舞金
              </CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="nyuinmimaikin"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('nyuinmimaikin')}
              />
              <CheckBoxLabel htmlFor="nyuinmimaikin">入院見舞金</CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="jutakusaigaimimaikin"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('jutakusaigaimimaikin')}
              />
              <CheckBoxLabel htmlFor="jutakusaigaimimaikin">
                住宅災害見舞金
              </CheckBoxLabel>
            </CheckBoxWrapper>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="kekkonginkonkinkon"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('kekkonginkonkinkon')}
              />
              <CheckBoxLabel htmlFor="kekkonginkonkinkon">
                結婚・銀婚・金婚祝金
              </CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="shussan"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('shussan')}
              />
              <CheckBoxLabel htmlFor="shussan">出産祝金</CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="shugakuiwaikin"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('shugakuiwaikin')}
              />
              <CheckBoxLabel htmlFor="shugakuiwaikin">
                小中就学祝金
              </CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="seijiniwaikin"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('seijiniwaikin')}
              />
              <CheckBoxLabel htmlFor="seijiniwaikin">成人祝金</CheckBoxLabel>
            </CheckBoxWrapper>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="shukuhakuriyouhojokin"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('shukuhakuriyouhojokin')}
              />
              <CheckBoxLabel htmlFor="shukuhakuriyouhojokin">
                宿泊施設利用補助金
              </CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="kaiinjouhou_henkoutodoke"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('kaiinjouhou_henkoutodoke')}
              />
              <CheckBoxLabel htmlFor="kaiinjouhou_henkoutodoke">
                変更届（会員情報）
              </CheckBoxLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                id="doukyokazoku_henkoutodoke"
                name="searchFormTypeList"
                onChange={(e) => handleChangeSearchFormTypeList(e)}
                checked={searchFormTypeList.has('doukyokazoku_henkoutodoke')}
              />
              <CheckBoxLabel htmlFor="doukyokazoku_henkoutodoke">
                変更届（同居家族）
              </CheckBoxLabel>
            </CheckBoxWrapper>
          </SearchInputWrapper>
          <TwoButtonWrapper>
            <WhiteButton onClick={handleClickSearchReset}>リセット</WhiteButton>
            <Button onClick={handleClickSearch}>検索</Button>
          </TwoButtonWrapper>
        </>
      )}
      <CsvDownloadSection
        downloadFormTypes={[
          { label: '給付金', value: 'memberKyufukin' },
          { label: '宿泊施設利用補助金', value: 'memberShukuhakuriyouhojokin' },
        ]}
      />
      <div style={{ textAlign: 'end', marginTop: '72px' }}>
        {filtered && <>{filtered.length}件 該当しました</>}
      </div>
      <Wrapper>
        <ScrollTable>
          <TableHead>
            <Row>
              <ThCell>申請日時</ThCell>
              <ThCell>申請ID</ThCell>
              <ThCell>申請種別</ThCell>
              <ThCell>ステータス</ThCell>
              <ThCell>承認日時</ThCell>
              <ThCellSmall>事業所番号</ThCellSmall>
              <ThCell>事業所名</ThCell>
              <ThCell>事業所フリガナ</ThCell>
              <ThCellSmall>個人番号</ThCellSmall>
              <ThCell>会員氏名</ThCell>
              <ThCell>会員フリガナ</ThCell>
              <ThCell>会員電話番号</ThCell>
              <ThCellLarge>メールアドレス</ThCellLarge>
              <ThCell>会員郵便番号</ThCell>
              <ThCell>会員住所</ThCell>
              <ThCell>金融機関名</ThCell>
              <ThCell>支店名</ThCell>
              <ThCell>口座種別</ThCell>
              <ThCell>口座番号</ThCell>
              <ThCell>口座名義</ThCell>
              <ThCell>口座フリガナ</ThCell>
            </Row>
          </TableHead>
          {filtered && <TBody applications={filtered} />}
        </ScrollTable>
      </Wrapper>
    </>
  )
}

type Props = {
  applications: AdminApplicationType[]
}
const areEqual = (prev: Props, next: Props) =>
  prev.applications.length === next.applications.length &&
  prev.applications.length > 0 &&
  next.applications.length > 0 &&
  prev.applications[0].id === next.applications[0].id

const TBody = React.memo<Props>((props) => {
  const history = useHistory()
  return (
    <TableBody>
      {props.applications.map((application, index) => (
        <ClickableRow
          key={index}
          onClick={() =>
            history.push(
              `${PATH.ADMIN_APPLICATIONS_MEMBER}/${application.id}`,
              { searchQuery: history.location.search },
            )
          }
        >
          <TdCell>{toYYYYMMDDHHmm(application.createdAt)}</TdCell>
          <TdCell>{application.id}</TdCell>
          <TdCell>{getCategoryAndForm(application.formType).formName}</TdCell>
          <TdCell>
            {getAdminApprovalStatusLabel(
              application.approvalStatus,
              application.formType,
            )}
          </TdCell>
          <TdCell>{getAcceptedDate(application.approvalHistories)}</TdCell>
          <TdCellSmall>{application.applicantId.split('-')[0]}</TdCellSmall>
          <TdCell>
            {getApplicantValue(application.formData, 'officeName')}
          </TdCell>
          <TdCell>
            {getApplicantValue(application.formData, 'officeNameKana')}
          </TdCell>
          <TdCellSmall>{application.applicantId.split('-')[1]}</TdCellSmall>
          <TdCell>{getApplicantValue(application.formData, 'name')}</TdCell>
          <TdCell>{getApplicantValue(application.formData, 'nameKana')}</TdCell>
          <TdCell>{getApplicantValue(application.formData, 'tel')}</TdCell>
          <TdCellLarge>
            {getApplicantValue(application.formData, 'email')}
          </TdCellLarge>
          <TdCell>
            {getApplicantValue(application.formData, 'postalCode')}
          </TdCell>
          <TdCell>{getApplicantValue(application.formData, 'address')}</TdCell>
          <TdCell>
            {getBankAccountValue(application.formData, 'bankName')}
          </TdCell>
          <TdCell>
            {getBankAccountValue(application.formData, 'branchName')}
          </TdCell>
          <TdCell>
            {getBankAccountTypeName(
              getBankAccountValue(application.formData, 'accountType'),
            )}
          </TdCell>
          <TdCell>
            {getBankAccountValue(application.formData, 'accountNumber')}
          </TdCell>
          <TdCell>
            {getBankAccountValue(application.formData, 'accountOwnerName')}
          </TdCell>
          <TdCell>
            {getBankAccountValue(application.formData, 'accountOwnerNameKana')}
          </TdCell>
        </ClickableRow>
      ))}
    </TableBody>
  )
}, areEqual)

const Wrapper = styled.div`
  display: block;
  overflow-x: scroll;
  box-shadow: rgb(51 51 51 / 15%) 0px 0px 4px 0px;
  border-radius: 6px;
`
const ScrollTable = styled(Table)`
  width: 4468px;
`
const TableHead = styled(Head)`
  display: block;
`
const TableBody = styled(Body)`
  display: block;
  overflow-y: scroll;
  max-height: 500px;
`
const ThCell = styled(Cell)`
  text-align: center;
  background: rgba(67, 154, 137, 0.25);
  padding: 16px 0;
  font-weight: bold;
  width: 220px;
`
const ThCellSmall = styled(ThCell)`
  width: 120px;
`
const ThCellLarge = styled(ThCell)`
  width: 250px;
`
const TdCell = styled(Cell)`
  text-align: center;
  height: 48px;
  width: 220px;
`
const TdCellSmall = styled(TdCell)`
  width: 120px;
`
const TdCellLarge = styled(TdCell)`
  width: 250px;
`
const ClickableRow = styled(Row)`
  cursor: pointer;
  &:hover > td {
    background-color: rgba(67, 154, 137, 0.1);
  }
`
const ButtonWrapper = styled.div`
  margin-top: 40px;
`
const TwoButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  width: 18rem;
  justify-content: space-between;
`
const SearchInputWrapper = styled.div`
  align-items: center;
  width: 980px;
  margin-top: 24px;
`
const InputField = styled.div`
  display: inline-block;
  margin-right: 32px;
`
const InputFieldMarginLeft = styled(InputField)`
  margin-left: 32px;
`
const SmallInput = styled(Input)`
  > input[type='text'] {
    width: 120px;
  }
`
const MediumInput = styled(Input)`
  > input[type='text'] {
    width: 200px;
  }
`
const WhiteButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #439a89;
  font-weight: normal;
  position: relative;
  display: inline-block;
`
const SelectBox = styled(Select)`
  flex-grow: 1;
  display: inline-block;
  width: 160px;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.05);
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  font-size: 16px;
`
const CheckBoxWrapper = styled.div`
  width: 210px;
  display: inline-block;
  margin-right: 16px;
`
const CheckBoxLabel = styled.label`
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  vertical-align: 0.15em;
`
const DatePickerWrapper = styled.div`
  input[type='text'] {
    width: 140px;
  }
`
export default ListMember
