import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Heading } from '../../ui/Heading'
import { Body, Cell, Head, Row, Table } from '../../ui/Table'
import { Input } from '../../ui/Input'
import { API, PATH } from '../../../const'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { Office } from '../../../types/LoginInfo'
import { ListPaginationResponse } from '../../../types/responseBody/ListPaginationResponse'
import { Button } from '../../ui/Button'
import { Select } from '../../ui/Select'
import { PartialLoader } from '../../ui/Loader'
import { dateStringify, toYYYYMMDD } from '../../../presenter/dateUtil'
import { DatePicker } from '../../ui/DatePicker'
import queryString from 'query-string'
import useParamGetter from '../../../hooks/useParamGetter'
import querystring from 'querystring'
import { CloseIcon, OpenIcon } from '../../ui/Icon'

const usePresenter = () => {
  const getOfficeStatusLabel = (office: Office) => {
    const officeStatus = getOfficeStatus(office)
    if (officeStatus === 'notLoggedIn') {
      return '会員'
    }
    return officeStatus === 'active' ? '会員' : '退会'
  }

  const getPaymentMethodName = (typeValue: string) => {
    switch (typeValue) {
      case 'cache':
        return '現金'
      case 'withdrawal':
        return '口座'
      case 'transfer':
        return '振込'
      default:
        return ''
    }
  }

  return { getOfficeStatusLabel, getPaymentMethodName }
}

const List: React.FC = () => {
  const { bearerToken } = useContext(AdminAuthContext)
  const { reset } = useForm()
  const history = useHistory()

  const location = useLocation()
  const param = queryString.parseUrl(location.search).query

  const paramGetter = useParamGetter(param)

  // 絞り込み検索用State
  const [isSearchOpen, setIsSearchOpen] = useState(true)
  const handleClickSearchOpen = () => {
    setIsSearchOpen(!isSearchOpen)
  }
  const [searchOfficeId, setSearchOfficeId] = useState(
    paramGetter.string('searchOfficeId'),
  )
  const handleChangeSearchOfficeId = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeId(e.target.value)
  }
  const [searchOfficeName, setSearchOfficeName] = useState(
    paramGetter.string('searchOfficeName'),
  )
  const handleChangeSearchOfficeName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeName(e.target.value)
  }
  const [searchOfficeNameKana, setSearchOfficeNameKana] = useState(
    paramGetter.string('searchOfficeNameKana'),
  )
  const handleChangeSearchOfficeNameKana = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeNameKana(e.target.value)
  }
  const [searchRepresentativeName, setSearchRepresentativeName] = useState(
    paramGetter.string('searchRepresentativeName'),
  )
  const handleChangeSearchRepresentativeName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchRepresentativeName(e.target.value)
  }
  const [
    searchRepresentativeNameKana,
    setSearchRepresentativeNameKana,
  ] = useState(paramGetter.string('searchRepresentativeNameKana'))
  const handleChangeSearchRepresentativeNameKana = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchRepresentativeNameKana(e.target.value)
  }
  const [searchClerkName, setSearchClerkName] = useState(
    paramGetter.string('searchClerkName'),
  )
  const handleChangeSearchClerkName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchClerkName(e.target.value)
  }
  const [searchClerkNameKana, setSearchClerkNameKana] = useState(
    paramGetter.string('searchClerkNameKana'),
  )
  const handleChangeSearchClerkNameKana = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchClerkNameKana(e.target.value)
  }
  const [searchPaymentMethod, setSearchPaymentMethod] = useState(
    paramGetter.string('searchPaymentMethod'),
  )
  const handleChangeSearchPaymentMethod = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSearchPaymentMethod(e.target.value)
  }
  const [searchStatus, setSearchStatus] = useState(
    paramGetter.string('searchStatus'),
  )
  const handleChangeSearchStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSearchStatus(e.target.value)
  }
  const [
    searchFirstLoggedInAtFrom,
    setSearchFirstLoggedInAtFrom,
  ] = useState<Date | null>(paramGetter.date('searchFirstLoggedInAtFrom'))
  const handleChangeSearchFirstLoggedInAtFrom = (date: Date | null) => {
    setSearchFirstLoggedInAtFrom(date)
  }
  const [
    searchFirstLoggedInAtTo,
    setSearchFirstLoggedInAtTo,
  ] = useState<Date | null>(paramGetter.date('searchFirstLoggedInAtTo'))
  const handleChangeSearchFirstLoggedInAtTo = (date: Date | null) => {
    setSearchFirstLoggedInAtTo(date)
  }

  const [filtered, setFiltered] = useState<Office[] | null>(null)

  const fetcher = (url: string) =>
    fetch(url, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())

  const { data: officeListResponse } = useSWR<ListPaginationResponse<Office>>(
    API.ADMIN_OFFICES,
    fetcher,
  )

  const [isSearching, setSearching] = useState(false)

  useEffect(() => {
    if (isSearching && typeof officeListResponse !== 'undefined') {
      search()
      setSearching(false)
    }
    // eslint-disable-next-line
  }, [isSearching, officeListResponse])

  useEffect(() => {
    // 初回描画時に検索条件が指定されていたらsearchをかける
    if (history.location.search.length > 0) {
      setSearching(true)
    }
    // eslint-disable-next-line
  }, [])

  const handleClickSearch = () => {
    setSearching(true)
    // search 処理はuseEffectで担保

    const queryParams = {
      searchOfficeId,
      searchOfficeName,
      searchOfficeNameKana,
      searchRepresentativeName,
      searchRepresentativeNameKana,
      searchClerkName,
      searchClerkNameKana,
      searchPaymentMethod,
      searchStatus,
      searchFirstLoggedInAtFrom: dateStringify(searchFirstLoggedInAtFrom),
      searchFirstLoggedInAtTo: dateStringify(searchFirstLoggedInAtTo),
    }
    history.replace(
      `${history.location.pathname}?${querystring.stringify(queryParams)}`,
    )
  }

  // 絞り込み検索
  const search = () => {
    let filteredOffices =
      typeof officeListResponse === 'undefined' ? [] : officeListResponse.items

    if (searchOfficeId) {
      const idReg = new RegExp(searchOfficeId, 'g')
      filteredOffices = filteredOffices.filter((office) =>
        office.officeId.match(idReg),
      )
    }
    if (searchOfficeName) {
      const nameReg = new RegExp(searchOfficeName, 'g')
      filteredOffices = filteredOffices.filter((office) =>
        office.name.match(nameReg),
      )
    }
    if (searchOfficeNameKana) {
      const nameKanaReg = new RegExp(searchOfficeNameKana, 'g')
      filteredOffices = filteredOffices.filter((office) =>
        office.nameKana.match(nameKanaReg),
      )
    }
    if (searchRepresentativeName) {
      const nameReg = new RegExp(searchRepresentativeName, 'g')
      filteredOffices = filteredOffices.filter((office) =>
        office.representativeName.match(nameReg),
      )
    }
    if (searchRepresentativeNameKana) {
      const nameKanaReg = new RegExp(searchRepresentativeNameKana, 'g')
      filteredOffices = filteredOffices.filter((office) =>
        office.representativeNameKana.match(nameKanaReg),
      )
    }
    if (searchClerkName) {
      const nameReg = new RegExp(searchClerkName, 'g')
      filteredOffices = filteredOffices.filter((office) =>
        office.clerkName.match(nameReg),
      )
    }
    if (searchClerkNameKana) {
      const nameKanaReg = new RegExp(searchClerkNameKana, 'g')
      filteredOffices = filteredOffices.filter((office) =>
        office.clerkNameKana.match(nameKanaReg),
      )
    }
    if (searchPaymentMethod) {
      filteredOffices = filteredOffices.filter(
        (office) => office.paymentMethod === searchPaymentMethod,
      )
    }
    if (searchStatus) {
      filteredOffices = filteredOffices.filter((office) => {
        if (searchStatus === 'active') {
          return (
            getOfficeStatus(office) === 'active' ||
            getOfficeStatus(office) === 'notLoggedIn'
          )
        } else {
          return getOfficeStatus(office) === searchStatus
        }
      })
    }
    if (searchFirstLoggedInAtFrom) {
      filteredOffices = filteredOffices.filter(
        (office) =>
          office.firstLoggedInAt &&
          searchFirstLoggedInAtFrom.getTime() <=
            new Date(toYYYYMMDD(office.firstLoggedInAt)).getTime(),
      )
    }
    if (searchFirstLoggedInAtTo) {
      filteredOffices = filteredOffices.filter(
        (office) =>
          office.firstLoggedInAt &&
          searchFirstLoggedInAtTo.getTime() >=
            new Date(toYYYYMMDD(office.firstLoggedInAt)).getTime(),
      )
    }

    const MAX_LENGTH = 1000
    const isTooMany = (filtered: any[]) => filtered.length > MAX_LENGTH
    if (isTooMany(filteredOffices)) {
      setFiltered(null)
      return alert(
        `${filteredOffices.length}件ヒットしました。検索結果が多すぎるため表示できません。条件を絞ってください`,
      )
    }
    setFiltered(filteredOffices)
  }

  const handleClickSearchReset = () => {
    setSearchOfficeId('')
    setSearchOfficeName('')
    setSearchOfficeNameKana('')
    setSearchRepresentativeName('')
    setSearchRepresentativeNameKana('')
    setSearchClerkName('')
    setSearchClerkNameKana('')
    setSearchPaymentMethod('')
    setSearchStatus('')
    setSearchFirstLoggedInAtFrom(null)
    setSearchFirstLoggedInAtTo(null)

    reset({
      searchOfficeId: searchOfficeId,
      searchOfficeName: searchOfficeName,
      searchOfficeNameKana: searchOfficeNameKana,
      searchRepresentativeName: searchRepresentativeName,
      searchRepresentativeNameKana: searchRepresentativeNameKana,
      searchClerkName: searchClerkName,
      searchClerkNameKana: searchClerkNameKana,
      searchPaymentMethod: searchPaymentMethod,
      searchStatus: searchStatus,
      searchFirstLoggedInAtFrom,
      searchFirstLoggedInAtTo,
    })

    setFiltered(null)
    history.replace(history.location.pathname)
  }

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ 事業所一覧
      </Heading>
      <ButtonWrapper>
        <ButtonWrapperLink to={PATH.ADMIN_OFFICES_CREATE}>
          <LinkButton>新規登録</LinkButton>
        </ButtonWrapperLink>
      </ButtonWrapper>

      <ButtonWrapper>
        <WhiteButton onClick={handleClickSearchOpen}>
          絞り込み検索
          {!isSearchOpen ? <CloseIcon /> : <OpenIcon />}
        </WhiteButton>
      </ButtonWrapper>

      {isSearchOpen && (
        <>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所番号
              </Heading>
              <SmallInput
                type="text"
                name="searchOfficeId"
                value={searchOfficeId}
                onChange={(e) => handleChangeSearchOfficeId(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所名
              </Heading>
              <Input
                type="text"
                name="searchOfficeName"
                value={searchOfficeName}
                onChange={(e) => handleChangeSearchOfficeName(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所フリガナ
              </Heading>
              <Input
                type="text"
                name="searchOfficeNameKane"
                value={searchOfficeNameKana}
                onChange={(e) => handleChangeSearchOfficeNameKana(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                代表者氏名
              </Heading>
              <MediumInput
                type="text"
                name="searchRepresentativeName"
                value={searchRepresentativeName}
                onChange={(e) => handleChangeSearchRepresentativeName(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                代表者フリガナ
              </Heading>
              <MediumInput
                type="text"
                name="searchRepresentativeNameKana"
                value={searchRepresentativeNameKana}
                onChange={(e) => handleChangeSearchRepresentativeNameKana(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事務担当者氏名
              </Heading>
              <MediumInput
                type="text"
                name="searchClerkName"
                value={searchClerkName}
                onChange={(e) => handleChangeSearchClerkName(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事務担当者フリガナ
              </Heading>
              <MediumInput
                type="text"
                name="searchClerkNameKana"
                value={searchClerkNameKana}
                onChange={(e) => handleChangeSearchClerkNameKana(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                会費振替方法
              </Heading>
              <SelectBox
                name="searchPaymentMethod"
                value={searchPaymentMethod}
                options={[
                  { label: '', value: '' },
                  { label: '現金', value: 'cache' },
                  { label: '口座', value: 'withdrawal' },
                  { label: '振込', value: 'transfer' },
                ]}
                onChange={(e) => handleChangeSearchPaymentMethod(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                ステータス
              </Heading>
              <SelectBox
                name="searchStatus"
                value={searchStatus}
                options={[
                  { label: '', value: '' },
                  { label: '会員', value: 'active' },
                  { label: '退会', value: 'inactive' },
                ]}
                onChange={(e) => handleChangeSearchStatus(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                利用開始日From
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchFirstLoggedInAtFrom"
                  value={searchFirstLoggedInAtFrom}
                  onChange={handleChangeSearchFirstLoggedInAtFrom}
                />
              </DatePickerWrapper>
            </InputField>
            ～
            <InputFieldMarginLeft>
              <Heading type="blockTitle" tag="span">
                利用開始日To
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchFirstLoggedInAtTo"
                  value={searchFirstLoggedInAtTo}
                  onChange={handleChangeSearchFirstLoggedInAtTo}
                />
              </DatePickerWrapper>
            </InputFieldMarginLeft>
          </SearchInputWrapper>
          <TwoButtonWrapper>
            <WhiteButton onClick={handleClickSearchReset}>リセット</WhiteButton>
            <Button onClick={handleClickSearch} disabled={isSearching}>
              {isSearching ? <PartialLoader /> : '検索'}
            </Button>
          </TwoButtonWrapper>
        </>
      )}
      <div style={{ textAlign: 'end', marginTop: '72px' }}>
        {filtered && <>{filtered.length}件 該当しました</>}
      </div>
      <Wrapper>
        <ScrollTable>
          <TableHead>
            <Row>
              <ThCellSmall>事業所番号</ThCellSmall>
              <ThCell>事業所名</ThCell>
              <ThCell>事業所フリガナ</ThCell>
              <ThCell>代表者氏名</ThCell>
              <ThCell>代表者フリガナ</ThCell>
              <ThCell>事務担当者氏名</ThCell>
              <ThCell>事務担当者フリガナ</ThCell>
              <ThCell>事業所電話番号</ThCell>
              <ThCell>事業所郵便番号</ThCell>
              <ThCell>所在地</ThCell>
              <ThCell>会費振替方法</ThCell>
              <ThCell>ステータス</ThCell>
            </Row>
          </TableHead>
          {isSearching ? (
            <div style={{ margin: '20px auto 20px calc(50%/2)' }}>
              <PartialLoader />
            </div>
          ) : (
            filtered && <TBody offices={filtered} />
          )}
        </ScrollTable>
      </Wrapper>
    </>
  )
}

type Props = { offices: Office[] }
const areEqual = (prev: Props, next: Props) =>
  prev.offices.length === next.offices.length &&
  prev.offices.length > 0 &&
  next.offices.length > 0 &&
  prev.offices[0].officeId === next.offices[0].officeId

const TBody = React.memo<Props>((props) => {
  const history = useHistory()
  const { getOfficeStatusLabel, getPaymentMethodName } = usePresenter()

  return (
    <TableBody>
      {props.offices.map((office, index) => (
        <ClickableRow
          key={index}
          onClick={() =>
            history.push({
              pathname: `${PATH.ADMIN_OFFICES}/${office.officeId}`,
              state: { searchQuery: history.location.search },
            })
          }
        >
          <TdCellSmall>{office.officeId}</TdCellSmall>
          <TdCell>{office.name}</TdCell>
          <TdCell>{office.nameKana}</TdCell>
          <TdCell>{office.representativeName}</TdCell>
          <TdCell>{office.representativeNameKana}</TdCell>
          <TdCell>{office.clerkName}</TdCell>
          <TdCell>{office.clerkNameKana}</TdCell>
          <TdCell>{office.tel}</TdCell>
          <TdCell>{office.postalCode}</TdCell>
          <TdCell>{office.address}</TdCell>
          <TdCell>{getPaymentMethodName(office.paymentMethod)}</TdCell>
          <TdCell>{getOfficeStatusLabel(office)}</TdCell>
        </ClickableRow>
      ))}
    </TableBody>
  )
}, areEqual)

const Wrapper = styled.div`
  display: block;
  overflow-x: scroll;
  box-shadow: rgb(51 51 51 / 15%) 0px 0px 4px 0px;
  border-radius: 6px;
`
const ScrollTable = styled(Table)`
  width: 2393px;
`
const TableHead = styled(Head)`
  display: block;
`
const TableBody = styled(Body)`
  display: block;
  overflow-y: scroll;
  max-height: 500px;
`
const ClickableRow = styled(Row)`
  cursor: pointer;
  &:hover > td {
    background-color: rgba(67, 154, 137, 0.1);
  }
`
const ThCell = styled(Cell)`
  text-align: center;
  background: rgba(67, 154, 137, 0.25);
  padding: 16px 0;
  font-weight: bold;
  width: 205px;
`
const ThCellSmall = styled(ThCell)`
  width: 120px;
`
const TdCell = styled(Cell)`
  text-align: center;
  height: 48px;
  width: 205px;
`
const TdCellSmall = styled(TdCell)`
  width: 120px;
`
const ButtonWrapper = styled.div`
  margin-top: 40px;
`
const TwoButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  width: 18rem;
  justify-content: space-between;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const SearchInputWrapper = styled.div`
  display: inline-block;
  width: 900px;
  margin-top: 24px;
`
const InputField = styled.div`
  display: inline-block;
  margin-right: 32px;
`
const SmallInput = styled(Input)`
  > input[type='text'] {
    width: 120px;
  }
`
const MediumInput = styled(Input)`
  > input[type='text'] {
    width: 180px;
  }
`
const WhiteButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #439a89;
  font-weight: normal;
  position: relative;
  display: inline-block;
`
const SelectBox = styled(Select)`
  flex-grow: 1;
  display: inline-block;
  width: 160px;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.05);
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  font-size: 16px;
`
const DatePickerWrapper = styled.div`
  input[type='text'] {
    width: 140px;
  }
`
const InputFieldMarginLeft = styled(InputField)`
  margin-left: 32px;
`

export function getOfficeStatus(
  office: Office,
): 'notLoggedIn' | 'active' | 'inactive' {
  if (!office.firstLoggedInAt && !office.deactivatedAt) {
    return 'notLoggedIn'
  }
  return !!office.firstLoggedInAt.length ? 'active' : 'inactive'
}
export default List
