import React from 'react'
import styled from 'styled-components'

import { KekkonGinkonKinkonData } from '../../../../types/formData'

import { Heading } from '../../../ui/Heading'
import { Input } from '../../../ui/Input'
import { RadioButtonLabel } from '../../../ui/RadioButtonLabel'
import { DatePicker } from '../../../ui/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'
import {
  BankAccountSection,
  FileSection,
  MemberInfoSection,
} from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { PATH } from '../../../../const'
import { FORM_TYPE_IDS } from '../../../../const/formTypeIds'
import { mediaQuery } from '../../../../themes/size'
import { isYYYYMMDD, isZenKatakana } from '../../../../util/validation'

type Props = {
  disabled: boolean
  canAdminUpload?: boolean
  handleSaveImage?: (files: File) => Promise<void>
  isUploading?: boolean
  handleDeleteImage?: (fileId: string) => Promise<void>
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const methods = useFormContext<KekkonGinkonKinkonData['formData']>()
  const { control, errors, getValues } = methods

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼給付金申請 - 結婚・銀婚・金婚
        </Heading>
      </HeadingArea>

      {Object.keys(methods.errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■申請情報
        </Heading>
        <InputField>
          <Heading type="blockTitle" tag="span">
            種別
          </Heading>
          <Controller
            control={control}
            name="type"
            defaultValue={props.disabled ? getValues('type') : 'marriage'}
            render={({ onChange, value }) => (
              <RadioButtonWrapper>
                <ThreeRadioButtonArea>
                  <RadioButtonLabel
                    label="結婚"
                    value="marriage"
                    checked={value === 'marriage'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </ThreeRadioButtonArea>
                <ThreeRadioButtonArea>
                  <RadioButtonLabel
                    label="銀婚"
                    value="silverWedding"
                    checked={value === 'silverWedding'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </ThreeRadioButtonArea>
                <ThreeRadioButtonArea>
                  <RadioButtonLabel
                    label="金婚"
                    value="goldenWedding"
                    checked={value === 'goldenWedding'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </ThreeRadioButtonArea>
              </RadioButtonWrapper>
            )}
          />
        </InputField>
        <InputField>
          <Heading type="blockTitle" tag="span">
            配偶者氏名
          </Heading>
          <Controller
            control={control}
            name="spouseName"
            rules={{ required: '配偶者氏名を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.spouseName && (
          <ErrorMessage>{errors.spouseName.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            フリガナ
            <br />
            (全角カナ)
          </Heading>
          <Controller
            control={control}
            name="spouseNameKana"
            rules={{
              required: 'フリガナを入力してください',
              validate: { isZenKatakana },
            }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.spouseNameKana && (
          <ErrorMessage>{errors.spouseNameKana.message}</ErrorMessage>
        )}
        {errors.spouseNameKana?.type === 'isZenKatakana' && (
          <ErrorMessage>全角カナで入力してください</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            婚姻年月日
          </Heading>
          <Controller
            control={control}
            name="marriageDate"
            rules={{
              required: '婚姻年月日を入力してください',
              validate: { isYYYYMMDD },
            }}
            render={({ onChange, value, onBlur }) => (
              <DatePicker
                onChange={onChange}
                value={value}
                disabled={props.disabled}
                onBlur={onBlur}
              />
            )}
          />
        </InputField>
        {errors.marriageDate && (
          <ErrorMessage>{errors.marriageDate.message}</ErrorMessage>
        )}
        {errors.marriageDate?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            性別
          </Heading>
          <Controller
            control={control}
            name="sex"
            defaultValue={props.disabled ? getValues('sex') : 'man'}
            render={({ value, onChange }) => (
              <RadioButtonWrapper>
                <TwoRadioButtonArea>
                  <RadioButtonLabel
                    label="男性"
                    value="man"
                    checked={value === 'man'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </TwoRadioButtonArea>
                <TwoRadioButtonArea>
                  <RadioButtonLabel
                    label="女性"
                    value="woman"
                    checked={value === 'woman'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </TwoRadioButtonArea>
              </RadioButtonWrapper>
            )}
          />
        </InputField>
        {errors.sex && <ErrorMessage>{errors.sex.message}</ErrorMessage>}
        <InputField>
          <Heading type="blockTitle" tag="span">
            請求金額
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="amount"
              defaultValue="10,000"
              render={({ onChange, value }) => (
                <Input type="text" onChange={onChange} value={value} disabled />
              )}
            />
            <InputLabel>円</InputLabel>
          </LabelWrapper>
        </InputField>
        {props.shouldShowOtherFormLinks && (
          <Heading type="sectionTitle" tag="h2">
            ※同居家族情報を変更する場合は
            <TextLink
              href={`${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE}`}
              target="_blank"
            >
              こちら
            </TextLink>
          </Heading>
        )}
      </Section>

      <FileSection
        headingLabel="・戸籍謄本（結婚の場合、婚姻受理証明書でも可）"
        disabled={props.disabled}
        canAdminUpload={props.canAdminUpload}
        handleSaveImage={props.handleSaveImage}
        isUploading={props.isUploading}
        handleDeleteImage={props.handleDeleteImage}
      />

      <BankAccountSection disabled={props.disabled} isMember={true} />

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
// @fixme 子コンポーネントから共通で参照できるように一旦exportしました
export const Section = styled.section`
  margin-top: 72px;
`
// @fixme 子コンポーネントから共通で参照できるように一旦exportしました
export const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
`

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
  }
`
const ThreeRadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 110px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 8px;
  }
`
const TwoRadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 160px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 8px;
  }
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const InputLabel = styled.label`
  margin-left: 8px;
  font-size: 16px;
`
const TextLink = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`

export default Component
