import * as React from 'react'

export type Props = {
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

export const Row: React.FC<Props> = ({ className = '', children, onClick }) => (
  <tr className={className} onClick={onClick}>
    {children}
  </tr>
)
