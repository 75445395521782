import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../../ui/Button'

type Props = {
  role: 'finalApprover' | 'approver' | 'confirmer'
  isTwoStagesApprove: boolean
  isSameApprover: boolean
  setIsApprovalModalOpen: (isOpen: boolean) => void
  setIsRemandModalOpen: (isOpen: boolean) => void
  setIsConfirmModalOpen: (isOpen: boolean) => void
}

export const AccepteButtonSection: React.FC<Props> = (props) => {
  return (
    <>
      <ButtonWrapper>
        <Button
          type="button"
          onClick={() => props.setIsApprovalModalOpen(true)}
          disabled={
            (props.role !== 'finalApprover' && !props.isTwoStagesApprove) ||
            props.isSameApprover
          }
        >
          承認
        </Button>
      </ButtonWrapper>
      <TwoButtonWrapper>
        <RemandedButton
          type="button"
          onClick={() => props.setIsRemandModalOpen(true)}
          disabled={
            (props.role !== 'finalApprover' && !props.isTwoStagesApprove) ||
            props.isSameApprover
          }
        >
          差戻し
        </RemandedButton>
        <CancelButton
          type="button"
          onClick={() => props.setIsConfirmModalOpen(true)}
          disabled={
            (props.role !== 'finalApprover' && !props.isTwoStagesApprove) ||
            props.isSameApprover
          }
        >
          取消
        </CancelButton>
      </TwoButtonWrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const TwoButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const RemandedButton = styled(Button)`
  background-color: #fe6f32;
  margin-right: 48px;
  &:hover {
    background-color: rgba(254, 111, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
const CancelButton = styled(Button)`
  background-color: #ea1c1c;
  &:hover {
    background-color: rgba(254, 57, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
