import { ApprovalStatus } from '../types/application'
import { FormType } from '../types/formType'
import { TWO_STAGE_APPROVALS } from '../const/formTypeIds'

export const getUserApprovalStatusLabel = (
  approvalStatus: ApprovalStatus,
): string => {
  switch (approvalStatus) {
    case 'applying':
      return '申請中'
    case 'inspecting':
      return '審査中'
    case 'rejected':
      return '取消'
    case 'accepted':
      return '受理'
    default:
      // remanded, approved1, approved2は返却されない
      console.error('無効な申請状態です')
      return ''
  }
}

// 現在の状態に対して表示するラベル
export const getAdminApprovalStatusLabel = (
  approvalStatus: ApprovalStatus,
  applicationType: FormType,
): string => {
  switch (approvalStatus) {
    case 'applying':
      return '1次承認待ち'
    case 'approved1':
      return TWO_STAGE_APPROVALS.includes(applicationType)
        ? '2次承認待ち'
        : '次長承認待ち'
    case 'approved2':
      return '局長承認待ち'
    case 'remanded':
      return '差戻し'
    case 'rejected':
      return '取消'
    case 'accepted':
      return '承認済み'
    default:
      // inspecting は管理の場合返却されない
      console.error('無効な申請状態です')
      return ''
  }
}

// 承認履歴に対して表示するラベル
export const getAdminApprovalHistoryLabel = (
  approvalStatus: ApprovalStatus,
): string => {
  switch (approvalStatus) {
    case 'approved1':
      return '1次承認'
    case 'approved2':
      // 2段承認の場合は approved2 にならないので getAdminApprovalStatusLabel() のような出し分けは考慮不要
      return '次長承認'
    case 'remanded':
      return '差戻し'
    case 'rejected':
      return '取消'
    case 'accepted':
      return '承認'
    default:
      // inspecting は管理の場合返却されない
      // フロー上、approvalHistory に applyingは入らない
      console.error('無効な申請状態です')
      return ''
  }
}
