/**
 * フロントのパス情報
 */

// ログイン不要なパス
export const LOGIN = '/login'
export const ACTIVATION_REQUEST = '/activation-request'
export const RESET_PASSWORD = '/reset-password'
export const CALLBACK = '/callback'
export const OFFICE_LOGIN = '/office/login'
export const ADMIN_LOGIN = '/admin/login'
export const NO_LOGIN_PATHS = [
  LOGIN,
  ACTIVATION_REQUEST,
  RESET_PASSWORD,
  OFFICE_LOGIN,
  ADMIN_LOGIN,
  CALLBACK,
]

// 会員ページ
export const TOP = '/'
export const APPLICATIONS = '/applications'
export const APPLICATIONS_NEW = '/new/applications'
export const ACCOUNT = '/account'

// 事業所ページ
export const OFFICE_TOP = '/office'
export const OFFICE_APPLICATIONS = `${OFFICE_TOP}/applications`
export const OFFICE_APPLICATIONS_NEW = `${OFFICE_TOP}/new/applications`
export const OFFICE_ACCOUNT = `${OFFICE_TOP}/account`
export const OFFICE_MEMBERS = `${OFFICE_TOP}/members`

// 管理ページ
export const ADMIN_TOP = '/admin'
export const ADMIN_APPLICATIONS_MEMBER = `${ADMIN_TOP}/applications-member`
export const ADMIN_APPLICATIONS_OFFICE = `${ADMIN_TOP}/applications-office`
export const ADMIN_MEMBERS = `${ADMIN_TOP}/members`
export const ADMIN_MEMBERS_CREATE = `${ADMIN_TOP}/members-create`
export const ADMIN_OFFICES = `${ADMIN_TOP}/offices`
export const ADMIN_OFFICES_CREATE = `${ADMIN_TOP}/offices-create`
export const ADMIN_ADMIN_USERS = `${ADMIN_TOP}/admin-users`
export const ADMIN_ADMIN_USERS_CREATE = `${ADMIN_TOP}/admin-users-create`
export const ADMIN_SETTING = `${ADMIN_TOP}/setting`
