import React, { useContext } from 'react'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import { API, PATH } from '../../../const'
import useSWR from 'swr'
import OfficeMembers from '../../../types/OfficeMembers'
import { PageLoader } from '../../ui/Loader'
import { Heading } from '../../ui/Heading'
import styled from 'styled-components'
import { Body, Cell, Head, Row, Table } from '../../ui/Table'
import { toYYYYMMDD } from '../../../presenter/dateUtil'
import { useHistory } from 'react-router-dom'
import { mediaQuery } from '../../../themes/size'
import { isSp } from 'libs/ua'

const List: React.FC = () => {
  const { loginInfo, bearerToken } = useContext(OfficeAuthContext)
  const history = useHistory()

  const apiPath = API.OFFICE_MEMBERS.replace(
    ':officeId',
    loginInfo.office.officeId,
  )
  const fetcher = () =>
    fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())
  const { data: officeMembers } = useSWR<OfficeMembers>(apiPath, fetcher)
  if (typeof officeMembers === 'undefined') {
    return <PageLoader />
  }

  const isInactive = (member: OfficeMembers[number]) =>
    member.deactivatedAt?.length && !member.firstLoggedInAt?.length

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ {loginInfo.office.name}様 (事業所番号{loginInfo.office.officeId})
      </Heading>
      <Wrapper>
        <Table>
          <TableHead>
            <TableHeadRow>
              <ThCell>会員番号</ThCell>
              <ThCell>氏名</ThCell>
              <ThCell>利用開始日</ThCell>
              <ThCell>退会日</ThCell>
              <ThCell>ステータス</ThCell>
            </TableHeadRow>
          </TableHead>
          <Body>
            {officeMembers.map((member, index) => (
              <ClickableRow
                key={index}
                onClick={() =>
                  history.push(`${PATH.OFFICE_MEMBERS}/${member.memberId}`)
                }
              >
                {isSp && <TdCell>会員 {index + 1}</TdCell>}
                <TdCell dataLabel={'会員番号'}>{member.memberId}</TdCell>
                <TdCell dataLabel={'氏名'}>{member.name}</TdCell>
                <TdCell dataLabel={'利用開始日'}>
                  {member.activatedAt ? toYYYYMMDD(member.activatedAt) : ''}
                </TdCell>
                <TdCell dataLabel={'退会日'}>
                  {isInactive(member) && member.deactivatedAt
                    ? toYYYYMMDD(member.deactivatedAt)
                    : ''}
                </TdCell>
                <TdCell dataLabel={'ステータス'}>
                  {isInactive(member) ? '退会済み' : '会員'}
                </TdCell>
              </ClickableRow>
            ))}
          </Body>
        </Table>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: block;
  margin-top: 72px;
`
const ThCell = styled(Cell)`
  text-align: center;
  background: rgba(67, 154, 137, 0.25);
  padding: 16px 0;
  font-weight: bold;
`
const TdCell = styled(Cell)`
  text-align: center;
  height: 48px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    text-align: right;
    width: 100%;

    &:first-child {
      background: rgba(67, 154, 137, 0.25);
      font-weight: bold;
      text-align: center;
    }
    &:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      margin-right: 10px;
    }
  }
`
const ClickableRow = styled(Row)`
  cursor: pointer;
  &:hover > td {
    background-color: rgba(67, 154, 137, 0.1);
  }
`
const TableHead = styled(Head)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: none;
  }
`
const TableHeadRow = styled(Row)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
export default List
