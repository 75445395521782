import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import { useHistory, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { OfficeInfoSection } from '../../layout/inputParts/OfficeInfoSection'
import { Button } from '../../ui/Button'
import { PageLoader, PartialLoader } from '../../ui/Loader'
import { MessageModal } from '../../ui/Modal'
import { API, PATH } from '../../../const'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { useMultiStepStateAdmin } from '../../../hooks/useMultiStepStateAdmin'
import { Office } from 'types/LoginInfo'
import { Heading } from '../../ui/Heading'
import { Section } from '../applications/form/SeijinIwaiKin'
import { Input } from '../../ui/Input'
import { isPassword } from '../../../util/validation'
import { getOfficeStatus } from './List'
import { BrowserBackWarning } from '../../util'
import { InputField } from '../../main/applications/form/ShougaiMimaiKin'
import { toYYYYMMDD } from '../../../presenter/dateUtil'

type ParamType = {
  officeId: string
}

const Component: React.FC = () => {
  const methods = useForm({
    mode: 'onBlur',
  })
  const history = useHistory<{ searchQuery: string }>()
  const { officeId } = useParams<ParamType>()
  const apiPath = `${API.ADMIN_OFFICES}/${officeId}`

  const handleConfirm = async (): Promise<boolean> => {
    document?.querySelector('main')?.scrollTo(0, 0)
    return await methods.trigger()
  }

  const handleSend = async (): Promise<void> => {
    await methods.handleSubmit(async (data: Office) => {
      const requestBody = data
      await fetch(apiPath, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        method: 'put',
        mode: 'cors',
        body: JSON.stringify(requestBody),
      })
    })()
  }

  const formState = useMultiStepStateAdmin()
  const shouldFormDisabled =
    formState.currentStep === 'confirm' || formState.currentStep === 'detail'

  const { bearerToken } = useContext(AdminAuthContext)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)
  const [isDeleteOK, setIsDeleteOK] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')
  const [
    isChangePasswordInputModalOpen,
    setChangePasswordInputModalOpen,
  ] = useState(false)
  const [
    isChangePasswordCompleteModalOpen,
    setChangePasswordCompleteModalOpen,
  ] = useState(false)
  const [isChangePasswordOK, setChangePasswordOK] = useState(true)
  const [isChangingPassword, setChangingPassword] = useState(false)

  const fetcher = () =>
    fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())

  const { data: office } = useSWR<Office>(apiPath, fetcher)

  const loading = typeof office === 'undefined'
  useEffect(() => {
    if (!loading && office) {
      methods.reset(office)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  if (typeof office === 'undefined') {
    return <PageLoader />
  }

  const handleModifyClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    formState.nextStep()
    document?.querySelector('main')?.scrollTo(0, 0)
    setButtonDisabled(false)
  }

  const handleConfirmClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    const isValid = await handleConfirm()
    if (isValid) {
      formState.nextStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
    setButtonDisabled(false)
  }

  const handleSendClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    await handleSend()
    setIsSendModalOpen(true)
    setButtonDisabled(false)
  }

  const handleSentModalClose = () => {
    setIsSendModalOpen(false)

    formState.detailStep()
    document?.querySelector('main')?.scrollTo(0, 0)
    methods.reset({ ...methods.watch() })
  }

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (formState.currentStep === 'detail') {
      const prevSearchQuery = history.location.state?.searchQuery
      const searchQuery =
        typeof prevSearchQuery !== 'undefined' ? prevSearchQuery : ''
      history.push(`${PATH.ADMIN_OFFICES}${searchQuery}`)
    } else if (formState.currentStep === 'input') {
      formState.prevStep()
      document?.querySelector('main')?.scrollTo(0, 0)
      methods.reset(office)
      methods.clearErrors()
    } else if (formState.currentStep === 'confirm') {
      formState.prevStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
  }

  const handleChangePasswordClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault()
    setChangePasswordInputModalOpen(true)
  }

  const handleCloseChangePasswordModal = (): void => {
    setChangePasswordInputModalOpen(false)
    setNewPasswordError('')
  }

  const handleChangePassword = async (): Promise<void> => {
    setChangingPassword(true)
    if (!isPassword(newPassword)) {
      setNewPasswordError('パスワードは半角英数字8文字以上で設定してください')
      return
    }
    const requestBody = { newPassword: newPassword }
    const res = await fetch(`${apiPath}/password`, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'put',
      body: JSON.stringify(requestBody),
    })
    if (res.status >= 400) {
      setChangePasswordOK(false)
    }
    handleCloseChangePasswordModal()
    setChangingPassword(false)
    setChangePasswordCompleteModalOpen(true)
  }

  const handleDelete = async (): Promise<void> => {
    setIsDeleting(true)
    const res = await fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'delete',
    })
    if (res.status >= 400) {
      setIsDeleteOK(false)
    }
    setIsDeleteModalOpen(false)
    setIsDeleting(false)
    setIsCompleteModalOpen(true)
  }

  return (
    <>
      <FormProvider {...methods}>
        <BrowserBackWarning
          isDirty={methods.formState.isDirty}
          message="変更が保存されていません。本当に戻りますか？"
        />
        <Heading type="screenTitle" tag="h1">
          事業所詳細
        </Heading>
        {Object.keys(methods.errors).length > 0 && (
          <Section>
            <ErrorMessage>
              入力内容に不備があります。各入力項目のメッセージを参照してください。
            </ErrorMessage>
          </Section>
        )}
        <OfficeInfoSection
          officeInfo={office}
          currentStep={formState.currentStep}
          disabled={shouldFormDisabled}
          isAdmin={true}
        />
        {formState.currentStep === 'detail' && (
          <Section>
            <Heading type="sectionTitle" tag="h2">
              ■利用状況
            </Heading>
            <InputField>
              <Heading type="blockTitle" tag="span">
                利用開始日
              </Heading>
              <Input
                type="text"
                value={
                  office.firstLoggedInAt
                    ? toYYYYMMDD(office.firstLoggedInAt)
                    : ''
                }
                disabled={true}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                退会日
              </Heading>
              <Input
                type="text"
                value={
                  getOfficeStatus(office) !== 'inactive'
                    ? ''
                    : toYYYYMMDD(office.deactivatedAt)
                }
                disabled={true}
              />
            </InputField>
          </Section>
        )}
        <Section>
          <Heading type="sectionTitle" tag="h2">
            ■アカウント情報
          </Heading>
          <PasswordResetButton
            type="button"
            onClick={handleChangePasswordClick}
          >
            パスワードリセット
          </PasswordResetButton>
        </Section>
        <ButtonWrapper>
          {formState.currentStep === 'detail' && (
            <ModifyButton
              type="button"
              onClick={handleModifyClick}
              disabled={buttonDisabled}
            >
              変更
            </ModifyButton>
          )}
          {formState.currentStep === 'input' && (
            <Button
              type="button"
              onClick={handleConfirmClick}
              disabled={buttonDisabled}
            >
              確認
            </Button>
          )}
          {formState.currentStep === 'confirm' && (
            <Button
              type="button"
              onClick={handleSendClick}
              disabled={buttonDisabled}
            >
              登録
            </Button>
          )}
        </ButtonWrapper>
        {formState.currentStep === 'detail' &&
          getOfficeStatus(office) !== 'inactive' && (
            <ButtonWrapper>
              <DeleteButton
                type="button"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                退会
              </DeleteButton>
            </ButtonWrapper>
          )}
        <ButtonWrapper>
          <BackButton type="button" onClick={handleBackClick}>
            戻る
          </BackButton>
        </ButtonWrapper>
        <MessageModal
          isOpen={isDeleteModalOpen}
          title={'本当に退会しますか？'}
          closeText={isDeleting ? <PartialLoader /> : 'OK'}
          onClickClose={handleDelete}
          onClickOverlay={() => setIsDeleteModalOpen(false)}
          onPressEscape={() => setIsDeleteModalOpen(false)}
          onClickCancelButton={() => setIsDeleteModalOpen(false)}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isCompleteModalOpen}
          title={isDeleteOK ? '退会しました' : '退会に失敗しました'}
          description={
            isDeleteOK ? undefined : (
              <p>しばらく経ってから再度お試しください。</p>
            )
          }
          closeText="OK"
          onClickClose={() => setIsCompleteModalOpen(false)}
          onClickOverlay={() => setIsCompleteModalOpen(false)}
          onPressEscape={() => setIsCompleteModalOpen(false)}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isSendModalOpen}
          title={'登録情報を変更しました'}
          closeText="OK"
          onClickClose={handleSentModalClose}
          onClickOverlay={handleSentModalClose}
          onPressEscape={handleSentModalClose}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isChangePasswordInputModalOpen}
          title={'パスワードリセット'}
          description={
            <ActivateModal>
              <ActivateModalMsg>
                パスワードを再設定してください。
                <br />
                ※8文字以上で入力してください。
              </ActivateModalMsg>
              <Input
                type="text"
                name="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <ErrorMessage>{newPasswordError}</ErrorMessage>
            </ActivateModal>
          }
          closeText={isChangingPassword ? <PartialLoader /> : 'OK'}
          onClickClose={handleChangePassword}
          onClickOverlay={() => handleCloseChangePasswordModal()}
          onPressEscape={() => handleCloseChangePasswordModal()}
          onClickCancelButton={() => handleCloseChangePasswordModal()}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isChangePasswordCompleteModalOpen}
          title={
            isChangePasswordOK
              ? 'パスワードリセット'
              : 'パスワードリセットに失敗しました'
          }
          description={
            isChangePasswordOK ? (
              <PasswordModalMsg>
                <p>
                  パスワードを再設定しました。
                  <br />
                  以下を事業所にご連絡ください。
                </p>
                <Input type="text" value={newPassword} disabled />
              </PasswordModalMsg>
            ) : (
              <p>しばらく経ってから再度お試しください。</p>
            )
          }
          closeText="OK"
          onClickClose={() => setChangePasswordCompleteModalOpen(false)}
          onClickOverlay={() => setChangePasswordCompleteModalOpen(false)}
          onPressEscape={() => setChangePasswordCompleteModalOpen(false)}
          id="dialog-message"
        />
      </FormProvider>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const ModifyButton = styled(Button)`
  background-color: #fe6f32;
  &:hover {
    background-color: rgba(254, 111, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
const DeleteButton = styled(Button)`
  background-color: #ea1c1c;
  &:hover {
    background-color: rgba(254, 57, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`
const PasswordResetButton = styled(Button)`
  margin-top: 40px;
  border: 1px solid #439a89;
  background-color: #fff;
  color: #439a89;
  font-weight: normal;
`
const ActivateModal = styled.div`
  text-align: center;
`
const ActivateModalMsg = styled.p`
  text-align: center;
`
const PasswordModalMsg = styled.div`
  text-align: center;
`
const ErrorMessage = styled.div`
  color: red;
`
export default Component
