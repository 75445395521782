import React, { useState } from 'react'

import styled from 'styled-components'

import { PATH, CONFIG } from '../../../const'
import { Link } from 'react-router-dom'
import { API } from '../../../const'
import { useHistory } from 'react-router-dom'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import { Button } from '../../ui/Button'
import { DatePicker } from '../../ui/DatePicker'
import { MessageModal } from '../../ui/Modal'
import { CheckBox } from '../../ui/CheckBox'
import { PartialLoader } from '../../ui/Loader'
import { mediaQuery } from '../../../themes/size'
import { Controller, useForm } from 'react-hook-form'
import { toYYYYMMDD } from '../../../presenter/dateUtil'
import { fetchAddress } from '../../../util/fetchAddressByPostalCode'
import {
  isMailAddress,
  isMemberId,
  isPhoneNumber,
  isPostalCode,
  isYYYYMMDD,
  isZenKatakana,
} from '../../../util/validation'

const ActivationRequest: React.FC = () => {
  const [isSending, setIsSending] = useState(false)
  type ActivationForm = {
    memberId: string
    officeName: string
    name: string
    nameKana: string
    birthDay: string
    postalCode: string
    address: string
    tel: string
    email: string
  }
  const methods = useForm<ActivationForm>({
    mode: 'onBlur',
    defaultValues: {
      memberId: '',
      officeName: '',
      name: '',
      nameKana: '',
      birthDay: '',
      postalCode: '',
      address: '',
      tel: '',
      email: '',
    },
  })

  const handleSubmit = async (e: any) => {
    await methods.trigger()
    e.preventDefault()
    await methods.handleSubmit(async (data) => {
      setIsSending(true)
      await fetch(API.ACTIVATIONS, {
        method: 'post',
        body: JSON.stringify({
          memberId: data.memberId,
          officeName: data.officeName,
          name: data.name,
          nameKana: data.nameKana,
          birthDay: toYYYYMMDD(data.birthDay),
          postalCode: data.postalCode,
          address: data.address,
          tel: data.tel,
          email: data.email,
        }),
      })
      setIsSending(false)

      modalOpen()
    })()
  }

  const [isPrivacyConfirmed, setIsPrivacyConfirmed] = useState(false)
  const handleChangePrivacy = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyConfirmed(e.target.checked)
  }

  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const modalOpen = () => setIsOpen(true)
  const onClickClose = () => {
    setIsOpen(false)
    history.push(PATH.TOP)
  }

  const handleChangePostalCode = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputPostalCode = e.target.value
    if (inputPostalCode.length === 8) {
      const address = await fetchAddress(inputPostalCode)
      methods.setValue('address', address, { shouldDirty: true })
    }
  }

  return (
    <>
      <Wrapper>
        <HeadingArea>
          <Heading type="screenTitle" tag="h1">
            ▼会員申請システムの利用申請
          </Heading>
        </HeadingArea>
        <Description type="sectionTitle" tag="h2">
          下記の項目をすべて入力し利用申請を行ってください。
          <br />
          内容に問題がなかった場合は、後日、申請したメールアドレス宛にログイン情報をお送りいたします。
          <br />
          また、申請内容について事務局から確認させて頂く場合もございます。
        </Description>
        <Section>
          <form onSubmit={handleSubmit}>
            <Heading type="sectionTitle" tag="h2">
              ■申請情報
            </Heading>
            <InputField>
              <Label type="blockTitle" tag="span">
                会員番号(半角数字+半角ハイフン)
              </Label>
              <Controller
                control={methods.control}
                name="memberId"
                rules={{
                  required: '会員番号を入力してください',
                  validate: { isMemberId },
                }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="text"
                    placeholder="0000-000"
                    value={value}
                    onChange={onChange}
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.memberId && (
              <ErrorMessage>{methods.errors.memberId.message}</ErrorMessage>
            )}
            {methods.errors.memberId?.type === 'isMemberId' && (
              <ErrorMessage>
                半角数字+半角ハイフンで入力してください
              </ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                事業所名
              </Label>
              <Controller
                control={methods.control}
                name="officeName"
                rules={{ required: '事業所名を入力してください' }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="text"
                    placeholder="文京区勤労者共済会"
                    value={value}
                    onChange={onChange}
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.officeName && (
              <ErrorMessage>{methods.errors.officeName.message}</ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                会員氏名
              </Label>
              <Controller
                control={methods.control}
                name="name"
                rules={{ required: '氏名を入力してください' }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="text"
                    value={value}
                    onChange={onChange}
                    placeholder="文京　太郎"
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.name && (
              <ErrorMessage>{methods.errors.name.message}</ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                フリガナ(全角カナ)
              </Label>
              <Controller
                control={methods.control}
                name="nameKana"
                rules={{
                  required: 'フリガナを入力してください',
                  validate: { isZenKatakana },
                }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="text"
                    placeholder="ブンキョウ　タロウ"
                    value={value}
                    onChange={onChange}
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.nameKana && (
              <ErrorMessage>{methods.errors.nameKana.message}</ErrorMessage>
            )}
            {methods.errors.nameKana?.type === 'isZenKatakana' && (
              <ErrorMessage>全角カナで入力してください</ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                生年月日
              </Label>
              <Controller
                control={methods.control}
                name="birthDay"
                rules={{
                  required: '生年月日を入力してください',
                  validate: { isYYYYMMDD },
                }}
                render={({ onChange, value, onBlur }) => (
                  <DatePicker
                    placeholder="1980/01/01"
                    value={value}
                    onChange={onChange}
                    openToDate={true}
                    onBlur={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.birthDay && (
              <ErrorMessage>{methods.errors.birthDay.message}</ErrorMessage>
            )}
            {methods.errors.birthDay?.type === 'isYYYYMMDD' && (
              <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                郵便番号(半角数字+半角ハイフン)
              </Label>
              <Controller
                control={methods.control}
                name="postalCode"
                rules={{
                  required: '会員郵便番号を入力してください',
                  validate: { isPostalCode },
                }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="text"
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      handleChangePostalCode(e)
                    }}
                    placeholder="112-0003"
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.postalCode && (
              <ErrorMessage>{methods.errors.postalCode.message}</ErrorMessage>
            )}
            {methods.errors.postalCode?.type === 'isPostalCode' && (
              <ErrorMessage>
                半角数字+半角ハイフンで入力してください
              </ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                会員住所
              </Label>
              <Controller
                control={methods.control}
                name="address"
                rules={{ required: '住所を入力してください' }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="text"
                    value={value}
                    onChange={onChange}
                    placeholder="東京都文京区春日1-16-21"
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.address && (
              <ErrorMessage>{methods.errors.address.message}</ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                電話番号(半角数字)
              </Label>
              <Controller
                control={methods.control}
                name="tel"
                rules={{
                  required: '会員電話番号を入力してください',
                  validate: { isPhoneNumber },
                }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="text"
                    value={value}
                    onChange={onChange}
                    placeholder="0300000000"
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.tel && (
              <ErrorMessage>{methods.errors.tel.message}</ErrorMessage>
            )}
            {methods.errors.tel?.type === 'isPhoneNumber' && (
              <ErrorMessage>半角数字10桁か11桁で入力してください</ErrorMessage>
            )}
            <InputField>
              <Label type="blockTitle" tag="span">
                メールアドレス
              </Label>
              <Controller
                control={methods.control}
                name="email"
                rules={{
                  required: 'メールアドレスを入力してください',
                  validate: { isMailAddress },
                }}
                render={({ onChange, value, onBlur }) => (
                  <Input
                    type="email"
                    value={value}
                    onChange={onChange}
                    placeholder="info@bunkin.jp"
                    onBlurCapture={onBlur}
                  />
                )}
              />
            </InputField>
            {methods.errors.email && (
              <ErrorMessage>{methods.errors.email.message}</ErrorMessage>
            )}
            {methods.errors.email?.type === 'isMailAddress' && (
              <ErrorMessage>
                メールアドレスの形式で入力してください　
                <br />
                ※ご利用いただけないメールアドレスの形式については
                <AnchorLink
                  href={CONFIG.ABOUT_SHINSEI_SYSTEM_PAGE_URL}
                  target="_blank"
                >
                  こちら
                </AnchorLink>
              </ErrorMessage>
            )}
            <CheckBoxWrapper>
              <CheckBox
                id="privacy"
                name="privacy"
                checked={isPrivacyConfirmed}
                onChange={(e) => {
                  handleChangePrivacy(e)
                }}
              />
              <CheckBoxLabel htmlFor="privacy">
                個人情報の取扱について同意する
              </CheckBoxLabel>
            </CheckBoxWrapper>
            <AnchorLink href={CONFIG.PRIVACY_URL} target="_blank">
              個人情報保護について
            </AnchorLink>
            <ButtonWrapper>
              <Button type="submit" disabled={!isPrivacyConfirmed || isSending}>
                {isSending ? <PartialLoader /> : '利用申請する'}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonWrapperLink to={PATH.TOP}>
                <LinkButton>戻る</LinkButton>
              </ButtonWrapperLink>
            </ButtonWrapper>
          </form>
        </Section>
      </Wrapper>
      <MessageModal
        isOpen={isOpen}
        title="利用申請をしました"
        description={
          <p>
            内容に問題がなかった場合は、後日、申請したメールアドレス宛にログイン情報をお送りいたします。また、申請内容について事務局から確認させて頂く場合もございます。自動送信される利用申請の内容確認メールが届かない場合は、メールアドレスが誤っていた可能性がございます。その場合は事務局までご連絡ください。
          </p>
        }
        closeText="OK"
        onClickClose={onClickClose}
        onClickOverlay={onClickClose}
        onPressEscape={onClickClose}
        id="dialog-message"
      />
    </>
  )
}
const Wrapper = styled.div`
  text-align: center;
`
const HeadingArea = styled.div`
  margin-top: 32px;
`
const Description = styled(Heading)`
  margin-top: 32px;
  line-height: 24px;
`
const Section = styled.div`
  margin-top: 72px;
`
const InputField = styled.div`
  display: block;
  margin-top: 16px;
  width: 792px;
  text-align: right;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;
    text-align: left;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const Label = styled(Heading)`
  display: inline-block;
  margin-right: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    margin-right: 0;
  }
`
const ButtonWrapper = styled.div`
  margin: 32px 0 16px 0;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const CheckBoxWrapper = styled.div`
  margin-top: 16px;
`
const CheckBoxLabel = styled.label`
  cursor: pointer;
  margin-left: 8px;
  vertical-align: 0.15em;
`
const AnchorLink = styled.a`
  color: #439a89;
  &:hover {
    text-decoration: none;
  }
`
const ErrorMessage = styled.div`
  color: red;
`

export default ActivationRequest
