import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { API, PATH } from '../../../../const'
import { MemberAuthContext } from '../../../../auth/MemberAuthProvider'
import { Heading } from '../../../ui/Heading'
import { Button } from '../../../ui/Button'
import { Input } from '../../../ui/Input'
import { MessageModal } from '../../../ui/Modal'
import { PartialLoader } from '../../../ui/Loader'
import { mediaQuery } from '../../../../themes/size'
import { isMailAddress } from '../../../../util/validation'

type Props = {
  currentEmail: string
}

const ChangeEmailForm: React.FC<Props> = (props) => {
  const history = useHistory()
  const { bearerToken, signInWithNewToken, revalidate } = useContext(
    MemberAuthContext,
  )

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (e: any) => {
    setButtonDisabled(true)

    e.preventDefault()
    const { newEmail } = e.target.elements

    if (props.currentEmail === newEmail.value) {
      setError('現在と異なるメールアドレスを入力してください')
      setButtonDisabled(false)
      return
    }

    if (!isMailAddress(newEmail.value)) {
      setError('メールアドレスの形式で入力してください')
      setButtonDisabled(false)
      return
    }

    const res = await fetch(API.ACCOUNT, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      method: 'put',
      mode: 'cors',
      body: JSON.stringify({ newEmail: newEmail.value }),
    })
    if (res.status >= 400) {
      setError(
        'メールアドレスが既に使われているか、サーバで問題が発生したため、変更に失敗しました。',
      )
      setButtonDisabled(false)
      return
    }

    const { token } = await res.json()

    signInWithNewToken(token)
    await revalidate()
    setIsModalOpen(true)
    setButtonDisabled(false)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    history.push(PATH.ACCOUNT)
  }

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■メールアドレスの変更
      </Heading>
      <InputField>
        <InputHeading type="blockTitle" tag="span">
          現在のメールアドレス
        </InputHeading>
        <Input type="text" defaultValue={props.currentEmail} disabled />
      </InputField>
      <form onSubmit={handleSubmit}>
        <InputField>
          <InputHeading type="blockTitle" tag="span">
            新しいメールアドレス
          </InputHeading>
          <Input type="text" name="newEmail" />
        </InputField>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonWrapper>
          <Button type="submit" disabled={buttonDisabled}>
            {buttonDisabled ? <PartialLoader /> : '送信'}
          </Button>
        </ButtonWrapper>
        <BackButtonWrapper>
          <ButtonWrapperLink to={PATH.ACCOUNT}>
            <LinkButton>戻る</LinkButton>
          </ButtonWrapperLink>
        </BackButtonWrapper>
      </form>
      <MessageModal
        isOpen={isModalOpen}
        title={'新しいメールアドレスを設定しました'}
        closeText="OK"
        onClickClose={handleModalClose}
        onClickOverlay={handleModalClose}
        onPressEscape={handleModalClose}
        id="dialog-message"
      />
    </>
  )
}

const InputHeading = styled(Heading)`
  width: 176px;
`
const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 512px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ButtonWrapper = styled.div`
  margin-top: 103px;
  text-align: center;
`
const BackButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const ErrorMessage = styled.div`
  color: red;
`

export default ChangeEmailForm
