import { PATH, CONFIG } from '../const'

type SidebarList = {
  label: string
  linkPath: string
}[]

export const memberSidebar: SidebarList = [
  {
    label: 'マイページ',
    linkPath: PATH.TOP,
  },
  {
    label: '新規申請',
    linkPath: PATH.APPLICATIONS_NEW,
  },
  {
    label: '過去の申請',
    linkPath: PATH.APPLICATIONS,
  },
  {
    label: '会員特典',
    linkPath: CONFIG.KAIIN_TOKUTEN_URL,
  },
  {
    label: 'アカウント',
    linkPath: PATH.ACCOUNT,
  },
]

export const officeSidebar: SidebarList = [
  {
    label: 'マイページ',
    linkPath: PATH.OFFICE_TOP,
  },
  {
    label: '新規申請',
    linkPath: PATH.OFFICE_APPLICATIONS_NEW,
  },
  {
    label: '過去の申請',
    linkPath: PATH.OFFICE_APPLICATIONS,
  },
  {
    label: '会員一覧',
    linkPath: PATH.OFFICE_MEMBERS,
  },
  {
    label: 'アカウント',
    linkPath: PATH.OFFICE_ACCOUNT,
  },
]

export const adminSidebar: SidebarList = [
  {
    label: 'マイページ',
    linkPath: PATH.ADMIN_TOP,
  },
  {
    label: '会員申請一覧',
    linkPath: PATH.ADMIN_APPLICATIONS_MEMBER,
  },
  {
    label: '事業所申請一覧',
    linkPath: PATH.ADMIN_APPLICATIONS_OFFICE,
  },
  {
    label: '会員一覧',
    linkPath: PATH.ADMIN_MEMBERS,
  },
  {
    label: '事業所一覧',
    linkPath: PATH.ADMIN_OFFICES,
  },
  {
    label: '管理者一覧',
    linkPath: PATH.ADMIN_ADMIN_USERS,
  },
  {
    label: 'アカウント設定',
    linkPath: PATH.ADMIN_SETTING,
  },
]
