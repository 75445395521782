import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Heading } from '../../ui/Heading'
import { Button } from '../../ui/Button'
import { Body, Cell, Head, Row, Table } from '../../ui/Table'
import { Input } from '../../ui/Input'
import { API, PATH } from '../../../const'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { User, Office } from '../../../types/LoginInfo'
import { ListPaginationResponse } from '../../../types/responseBody/ListPaginationResponse'
import { Select } from '../../ui/Select'
import { PartialLoader } from '../../ui/Loader'
import { DatePicker } from '../../ui/DatePicker'
import { dateStringify, toYYYYMMDD } from '../../../presenter/dateUtil'
import queryString from 'query-string'
import useParamGetter from '../../../hooks/useParamGetter'
import querystring from 'querystring'
import { CloseIcon, OpenIcon } from '../../ui/Icon'

const usePresenter = (offices: Office[]) => {
  const getOfficeName = (memberId: string) => {
    const targetOfficeId = memberId.split('-')[0]
    const office = offices.find((office) => office.officeId === targetOfficeId)
    return office?.name
  }

  const getOfficeNameKana = (memberId: string) => {
    const targetOfficeId = memberId.split('-')[0]
    const office = offices.find((office) => office.officeId === targetOfficeId)
    return office?.nameKana
  }

  const getUserStatusLabel = (member: User) => {
    const userStatus = getUserStatus(member)
    if (userStatus === 'notLoggedIn') {
      return '未ログイン'
    }
    return userStatus === 'active' ? '会員' : '退会'
  }

  return {
    getOfficeName,
    getOfficeNameKana,
    getUserStatusLabel,
  }
}

const List: React.FC = () => {
  const { bearerToken } = useContext(AdminAuthContext)
  const { reset } = useForm()
  const history = useHistory()

  const location = useLocation()
  const param = queryString.parseUrl(location.search).query

  const paramGetter = useParamGetter(param)

  // 絞り込み検索用State
  const [isSearchOpen, setIsSearchOpen] = useState(true)
  const handleClickSearchOpen = () => {
    setIsSearchOpen(!isSearchOpen)
  }
  const [searchName, setSearchName] = useState(paramGetter.string('searchName'))
  const handleChangeSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value)
  }
  const [searchNameKana, setSearchNameKana] = useState(
    paramGetter.string('searchNameKana'),
  )
  const handleChangeSearchNameKana = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchNameKana(e.target.value)
  }
  const [searchEmail, setSearchEmail] = useState(
    paramGetter.string('searchEmail'),
  )
  const handleChangeSearchEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchEmail(e.target.value)
  }
  const [searchMemberId, setSearchMemberId] = useState(
    paramGetter.string('searchMemberId'),
  )
  const handleChangeSearchMemberId = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchMemberId(e.target.value)
  }
  const [searchOfficeId, setSearchOfficeId] = useState(
    paramGetter.string('searchOfficeId'),
  )
  const handleChangeSearchOfficeId = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeId(e.target.value)
  }
  const [searchOfficeName, setSearchOfficeName] = useState(
    paramGetter.string('searchOfficeName'),
  )
  const handleChangeSearchOfficeName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeName(e.target.value)
  }
  const [searchOfficeNameKana, setSearchOfficeNameKana] = useState(
    paramGetter.string('searchOfficeNameKana'),
  )
  const handleChangeSearchOfficeNameKana = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchOfficeNameKana(e.target.value)
  }
  const [searchStatus, setSearchStatus] = useState(
    paramGetter.string('searchStatus'),
  )
  const handleChangeSearchStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSearchStatus(e.target.value)
  }
  const [
    searchActivatedAtFrom,
    setSearchActivatedAtFrom,
  ] = useState<Date | null>(paramGetter.date('searchActivatedAtFrom'))
  const handleChangeSearchActivatedAtFrom = (date: Date | null) => {
    setSearchActivatedAtFrom(date)
  }
  const [searchActivatedAtTo, setSearchActivatedAtTo] = useState<Date | null>(
    paramGetter.date('searchActivatedAtTo'),
  )
  const handleChangeSearchActivatedAtTo = (date: Date | null) => {
    setSearchActivatedAtTo(date)
  }

  const [filtered, setFiltered] = useState<User[] | null>(null)

  const { data: userListResponse } = useSWR<ListPaginationResponse<User>>(
    API.ADMIN_MEMBERS,
    (url) =>
      fetch(url, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        mode: 'cors',
      }).then((res) => res.json()),
  )

  const { data: officeListResponse } = useSWR<ListPaginationResponse<Office>>(
    API.ADMIN_OFFICES,
    (url) =>
      fetch(url, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        mode: 'cors',
      }).then((res) => res.json()),
  )

  const offices =
    typeof officeListResponse === 'undefined' ? [] : officeListResponse.items
  const { getOfficeName, getOfficeNameKana } = usePresenter(offices)

  const [isSearching, setSearching] = useState(false)

  useEffect(() => {
    if (isSearching && typeof userListResponse !== 'undefined') {
      search()
      setSearching(false)
    }
    // eslint-disable-next-line
  }, [isSearching, userListResponse])

  useEffect(() => {
    // 初回描画時に検索条件が指定されていたらsearchをかける
    if (history.location.search.length > 0) {
      setSearching(true)
    }
    // eslint-disable-next-line
  }, [])

  const handleClickSearch = () => {
    setSearching(true)
    // search 処理はuseEffectで担保

    const queryParams = {
      searchName,
      searchNameKana,
      searchEmail,
      searchMemberId,
      searchOfficeId,
      searchOfficeName,
      searchOfficeNameKana,
      searchStatus,
      searchActivatedAtFrom: dateStringify(searchActivatedAtFrom),
      searchActivatedAtTo: dateStringify(searchActivatedAtTo),
    }
    history.replace(
      `${history.location.pathname}?${querystring.stringify(queryParams)}`,
    )
  }

  // 絞り込み検索
  const search = () => {
    let filteredUsers =
      typeof userListResponse === 'undefined' ? [] : userListResponse.items

    if (searchName) {
      const nameReg = new RegExp(searchName, 'g')
      filteredUsers = filteredUsers.filter((user) => user.name.match(nameReg))
    }
    if (searchNameKana) {
      const nameKanaReg = new RegExp(searchNameKana, 'g')
      filteredUsers = filteredUsers.filter((user) =>
        user.nameKana.match(nameKanaReg),
      )
    }
    if (searchEmail) {
      const emailReg = new RegExp(searchEmail, 'g')
      filteredUsers = filteredUsers.filter((user) => user.email.match(emailReg))
    }
    if (searchMemberId) {
      const idReg = new RegExp(searchMemberId, 'g')
      filteredUsers = filteredUsers.filter((user) =>
        user.memberId.split('-')[1].match(idReg),
      )
    }
    if (searchOfficeId) {
      const idReg = new RegExp(searchOfficeId, 'g')
      filteredUsers = filteredUsers.filter((user) =>
        user.memberId.split('-')[0].match(idReg),
      )
    }
    if (searchOfficeName) {
      const idReg = new RegExp(searchOfficeName, 'g')
      filteredUsers = filteredUsers.filter((user) => {
        const officeName = getOfficeName(user.memberId)

        if (officeName) {
          return officeName.match(idReg)
        } else {
          return false
        }
      })
    }
    if (searchOfficeNameKana) {
      const idReg = new RegExp(searchOfficeNameKana, 'g')
      filteredUsers = filteredUsers.filter((user) => {
        const officeNameKana = getOfficeNameKana(user.memberId)

        if (officeNameKana) {
          return officeNameKana.match(idReg)
        } else {
          return false
        }
      })
    }
    if (searchStatus) {
      filteredUsers = filteredUsers.filter(
        (user) => getUserStatus(user) === searchStatus,
      )
    }
    if (searchActivatedAtFrom) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.activatedAt &&
          searchActivatedAtFrom.getTime() <=
            new Date(toYYYYMMDD(user.activatedAt)).getTime(),
      )
    }
    if (searchActivatedAtTo) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.activatedAt &&
          searchActivatedAtTo.getTime() >=
            new Date(toYYYYMMDD(user.activatedAt)).getTime(),
      )
    }

    const MAX_LENGTH = 1000
    const isTooMany = (filtered: any[]) => filtered.length > MAX_LENGTH
    if (isTooMany(filteredUsers)) {
      setFiltered(null)
      return alert(
        `${filteredUsers.length}件ヒットしました。検索結果が多すぎるため表示できません。条件を絞ってください`,
      )
    }
    setFiltered(filteredUsers)
  }

  const handleClickSearchReset = () => {
    setSearchName('')
    setSearchNameKana('')
    setSearchEmail('')
    setSearchMemberId('')
    setSearchOfficeId('')
    setSearchOfficeName('')
    setSearchOfficeNameKana('')
    setSearchStatus('')
    setSearchActivatedAtFrom(null)
    setSearchActivatedAtTo(null)

    reset({
      searchName: searchName,
      searchNameKana: searchNameKana,
      searchEmail: searchEmail,
      searchMemberId: searchMemberId,
      searchOfficeId: searchOfficeId,
      searchOfficeName: searchOfficeName,
      searchOfficeNameKana: searchOfficeNameKana,
      searchStatus: searchStatus,
      searchActivatedAtFrom,
      searchActivatedAtTo,
    })

    setFiltered(null)
    history.replace(history.location.pathname)
  }

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ 会員一覧
      </Heading>
      <ButtonWrapper>
        <ButtonWrapperLink to={PATH.ADMIN_MEMBERS_CREATE}>
          <LinkButton>新規登録</LinkButton>
        </ButtonWrapperLink>
      </ButtonWrapper>
      <ButtonWrapper>
        <WhiteButton onClick={handleClickSearchOpen}>
          絞り込み検索
          {!isSearchOpen ? <CloseIcon /> : <OpenIcon />}
        </WhiteButton>
      </ButtonWrapper>

      {isSearchOpen && (
        <>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所番号
              </Heading>
              <SmallInput
                type="text"
                name="searchOfficeId"
                value={searchOfficeId}
                onChange={(e) => handleChangeSearchOfficeId(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所名
              </Heading>
              <Input
                type="text"
                name="searchOfficeName"
                value={searchOfficeName}
                onChange={(e) => handleChangeSearchOfficeName(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                事業所フリガナ
              </Heading>
              <Input
                type="text"
                name="searchOfficeNameKane"
                value={searchOfficeNameKana}
                onChange={(e) => handleChangeSearchOfficeNameKana(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                個人番号
              </Heading>
              <SmallInput
                type="text"
                name="searchMemberId"
                value={searchMemberId}
                onChange={(e) => handleChangeSearchMemberId(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                会員氏名
              </Heading>
              <MediumInput
                type="text"
                name="searchName"
                value={searchName}
                onChange={(e) => handleChangeSearchName(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                会員フリガナ
              </Heading>
              <MediumInput
                type="text"
                name="searchNameKana"
                value={searchNameKana}
                onChange={(e) => handleChangeSearchNameKana(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                メールアドレス
              </Heading>
              <Input
                type="email"
                name="searchEmail"
                value={searchEmail}
                onChange={(e) => handleChangeSearchEmail(e)}
              />
            </InputField>
            <InputField>
              <Heading type="blockTitle" tag="span">
                ステータス
              </Heading>
              <SelectBox
                name="searchStatus"
                value={searchStatus}
                options={[
                  { label: '', value: '' },
                  { label: '未ログイン', value: 'notLoggedIn' },
                  { label: '会員', value: 'active' },
                  { label: '退会', value: 'inactive' },
                ]}
                onChange={(e) => handleChangeSearchStatus(e)}
              />
            </InputField>
          </SearchInputWrapper>
          <SearchInputWrapper>
            <InputField>
              <Heading type="blockTitle" tag="span">
                利用開始日From
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchActivatedAtFrom"
                  value={searchActivatedAtFrom}
                  onChange={handleChangeSearchActivatedAtFrom}
                />
              </DatePickerWrapper>
            </InputField>
            ～
            <InputFieldMarginLeft>
              <Heading type="blockTitle" tag="span">
                利用開始日To
              </Heading>
              <DatePickerWrapper>
                <DatePicker
                  name="searchActivatedAtTo"
                  value={searchActivatedAtTo}
                  onChange={handleChangeSearchActivatedAtTo}
                />
              </DatePickerWrapper>
            </InputFieldMarginLeft>
          </SearchInputWrapper>
          <TwoButtonWrapper>
            <WhiteButton onClick={handleClickSearchReset}>リセット</WhiteButton>
            <Button onClick={handleClickSearch} disabled={isSearching}>
              {isSearching ? <PartialLoader /> : '検索'}
            </Button>
          </TwoButtonWrapper>
        </>
      )}
      <div style={{ textAlign: 'end', marginTop: '72px' }}>
        {filtered && <>{filtered.length}件 該当しました</>}
      </div>
      <Wrapper>
        <ScrollTable>
          <TableHead>
            <Row>
              <ThCellSmall>事業所番号</ThCellSmall>
              <ThCell>事業所名</ThCell>
              <ThCell>事業所フリガナ</ThCell>
              <ThCellSmall>個人番号</ThCellSmall>
              <ThCell>会員氏名</ThCell>
              <ThCell>会員フリガナ</ThCell>
              <ThCell>会員電話番号</ThCell>
              <ThCellLarge>メールアドレス</ThCellLarge>
              <ThCell>会員郵便番号</ThCell>
              <ThCell>会員住所</ThCell>
              <ThCell>ステータス</ThCell>
            </Row>
          </TableHead>
          {isSearching ? (
            <div style={{ margin: '20px auto 20px calc(50%/2)' }}>
              <PartialLoader />
            </div>
          ) : (
            filtered && <TBody users={filtered} offices={offices} />
          )}
        </ScrollTable>
      </Wrapper>
    </>
  )
}

type Props = { users: User[]; offices: Office[] }
const areEqual = (prev: Props, next: Props) =>
  prev.users.length === next.users.length &&
  prev.users.length > 0 &&
  next.users.length > 0 &&
  prev.users[0].memberId === next.users[0].memberId

const TBody = React.memo<Props>((props) => {
  const history = useHistory()
  const { getOfficeName, getOfficeNameKana, getUserStatusLabel } = usePresenter(
    props.offices,
  )

  return (
    <TableBody>
      {props.users.map((user, index) => (
        <ClickableRow
          key={index}
          onClick={() =>
            history.push({
              pathname: `${PATH.ADMIN_MEMBERS}/${user.memberId}`,
              state: { searchQuery: history.location.search },
            })
          }
        >
          <TdCellSmall>{user.memberId.split('-')[0]}</TdCellSmall>
          <TdCell>{getOfficeName(user.memberId)}</TdCell>
          <TdCell>{getOfficeNameKana(user.memberId)}</TdCell>
          <TdCellSmall>{user.memberId.split('-')[1]}</TdCellSmall>
          <TdCell>{user.name}</TdCell>
          <TdCell>{user.nameKana}</TdCell>
          <TdCell>{user.tel}</TdCell>
          <TdCellLarge>{user.email}</TdCellLarge>
          <TdCell>{user.postalCode}</TdCell>
          <TdCell>{user.address}</TdCell>
          <TdCell>{getUserStatusLabel(user)}</TdCell>
        </ClickableRow>
      ))}
    </TableBody>
  )
}, areEqual)

const Wrapper = styled.div`
  display: block;
  overflow-x: scroll;
  box-shadow: rgb(51 51 51 / 15%) 0px 0px 4px 0px;
  border-radius: 6px;
`
const ScrollTable = styled(Table)`
  width: 2148px;
`
const TableHead = styled(Head)`
  display: block;
`
const TableBody = styled(Body)`
  display: block;
  overflow-y: scroll;
  max-height: 500px;
`
const ClickableRow = styled(Row)`
  cursor: pointer;
  &:hover > td {
    background-color: rgba(67, 154, 137, 0.1);
  }
`
const ThCell = styled(Cell)`
  text-align: center;
  background: rgba(67, 154, 137, 0.25);
  padding: 16px 0;
  font-weight: bold;
  width: 205px;
`
const ThCellSmall = styled(ThCell)`
  width: 120px;
`
const ThCellLarge = styled(ThCell)`
  width: 250px;
`
const TdCell = styled(Cell)`
  text-align: center;
  height: 48px;
  width: 205px;
`
const TdCellSmall = styled(TdCell)`
  width: 120px;
`
const TdCellLarge = styled(TdCell)`
  width: 250px;
`
const ButtonWrapper = styled.div`
  margin-top: 40px;
`
const TwoButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  width: 18rem;
  justify-content: space-between;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const SearchInputWrapper = styled.div`
  display: inline-block;
  width: 900px;
  margin-top: 24px;
`
const InputField = styled.div`
  display: inline-block;
  margin-right: 32px;
`
const SmallInput = styled(Input)`
  > input[type='text'] {
    width: 120px;
  }
`
const MediumInput = styled(Input)`
  > input[type='text'] {
    width: 200px;
  }
`
const WhiteButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #439a89;
  font-weight: normal;
  position: relative;
  display: inline-block;
`
const SelectBox = styled(Select)`
  flex-grow: 1;
  display: inline-block;
  width: 160px;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.05);
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  font-size: 16px;
`
const DatePickerWrapper = styled.div`
  input[type='text'] {
    width: 140px;
  }
`
const InputFieldMarginLeft = styled(InputField)`
  margin-left: 32px;
`
export function getUserStatus(
  member: User,
): 'notLoggedIn' | 'active' | 'inactive' {
  if (!member.firstLoggedInAt.length && !member.deactivatedAt.length) {
    return 'notLoggedIn'
  }
  return !!member.firstLoggedInAt.length ? 'active' : 'inactive'
}

export default List
