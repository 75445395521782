import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'

import { Heading } from '../../../ui/Heading'
import { MemberInfoSection } from './inputParts'
import { FamilySection } from '../../../layout/inputParts/FamilySection'
import { useFormContext } from 'react-hook-form'
import { MemberAuthContext } from '../../../../auth/MemberAuthProvider'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'

type Props = {
  disabled: boolean
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const { loginInfo } = useContext(MemberAuthContext)
  const methods = useFormContext()

  useEffect(() => {
    if (!props.disabled) {
      methods.reset({
        ...methods.watch(),
        previousFamily: loginInfo.user.family,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼変更届 - 同居家族
        </Heading>
      </HeadingArea>

      {Object.keys(methods.errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <FamilySection disabled={true} isInput={false} />

      <FamilySection disabled={props.disabled} isInput={true} />

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
export const Section = styled.section`
  margin-top: 72px;
`

export const BlankSpace = styled.div`
  height: 24px;
`
const ErrorMessage = styled.div`
  color: red;
`
export default Component
