import React, { useContext } from 'react'
import styled from 'styled-components'

import { ModalContext } from './ModalWrapper'

export const ModalTrigger: React.VFC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { onClickTrigger } = useContext(ModalContext)
  return (
    <Wrapper onClick={onClickTrigger} aria-haspopup="dialog">
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
`
