import React, { useState } from 'react'
import { MessageModal } from '../../../ui/Modal'
import { PartialLoader } from '../../../ui/Loader'
import { useHistory } from 'react-router-dom'

type ModalOption = {
  actionLabel?: string
  redirectToWhenComplete?: string
}

export const useStepModal = (
  requestCallback: () => Promise<Response>,
  options?: ModalOption,
) => {
  const history = useHistory()

  // Modal state
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const showResult = () => {
    setIsConfirmModalOpen(false)
    setIsCompleteModalOpen(true)
  }

  // Request state
  const [isLoading, setIsLoading] = useState(false)
  const [isResultOk, setIsResultOk] = useState(false)
  const doRequest = async () => {
    setIsLoading(true)
    const res = await requestCallback()
    setIsResultOk(res.status < 300)
    setIsLoading(false)
  }

  const handleRequest = async () => {
    await doRequest()
    showResult()
  }

  const handleCloseComplete = () => {
    setIsCompleteModalOpen(false)
    if (
      typeof options !== 'undefined' &&
      typeof options.redirectToWhenComplete !== 'undefined'
    ) {
      history.push(options.redirectToWhenComplete)
    }
  }

  // presenter
  const getActionLabel = (param: any) =>
    typeof param === 'string' ? param : '処理' // default文言は処理で担保
  const label = getActionLabel(options?.actionLabel)

  const renderModal = () => (
    <>
      <MessageModal
        isOpen={isConfirmModalOpen}
        title={`${label}しますか？`}
        closeText={isLoading ? <PartialLoader /> : 'OK'}
        onClickClose={handleRequest}
        onClickCancelButton={() => setIsConfirmModalOpen(false)}
        onClickOverlay={() => setIsConfirmModalOpen(false)}
        onPressEscape={() => setIsConfirmModalOpen(false)}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isCompleteModalOpen}
        title={isResultOk ? `${label}しました` : `${label}に失敗しました`}
        description={
          isResultOk ? undefined : (
            <p>
              しばらく経ってから再度お試しいただくか運営にお問い合わせください。
            </p>
          )
        }
        closeText="OK"
        onClickClose={handleCloseComplete}
        onClickOverlay={handleCloseComplete}
        onPressEscape={handleCloseComplete}
        id="dialog-message"
      />
    </>
  )
  return [() => setIsConfirmModalOpen(true), renderModal] as const
}
