import React from 'react'
import { useFormContext } from 'react-hook-form'
import { AdminLoginInfo } from '../../../../types/AdminLoginInfo'
import { Heading } from '../../../ui/Heading'
import { InputField, Section } from '../../account/Account'
import { Controller } from 'react-hook-form'
import { isMailAddress } from '../../../../util/validation'
import { Input } from '../../../ui/Input'
import styled from 'styled-components'
import { RadioButtonLabel } from '../../../ui/RadioButtonLabel'

type Props = {
  readonly?: boolean
}

const Component: React.FC<Props> = (props) => {
  const { control, errors } = useFormContext<AdminLoginInfo['admin']>()

  const roles = [
    { label: '局長', value: 'finalApprover' },
    { label: '次長', value: 'approver' },
    { label: '確認者', value: 'confirmer' },
  ]

  return (
    <Section>
      {/* 氏名 */}
      <InputField>
        <Heading type="blockTitle" tag="span">
          氏名
        </Heading>
        <Controller
          control={control}
          name="name"
          rules={{
            required: '氏名を入力してください',
          }}
          render={(renderProps) => (
            <Input
              type="text"
              defaultValue={renderProps.value}
              onChange={renderProps.onChange}
              onBlurCapture={renderProps.onBlur}
              disabled={props.readonly}
            />
          )}
        />
      </InputField>
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

      {/* メアド */}
      <InputField>
        <Heading type="blockTitle" tag="span">
          メールアドレス
        </Heading>
        <Controller
          control={control}
          name="email"
          rules={{
            required: 'メールアドレスを入力してください',
            validate: { isMailAddress },
          }}
          render={(renderProps) => (
            <Input
              type="text"
              defaultValue={renderProps.value}
              onChange={renderProps.onChange}
              onBlurCapture={renderProps.onBlur}
              disabled={props.readonly}
            />
          )}
        />
      </InputField>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      {errors.email?.type === 'isMailAddress' && (
        <ErrorMessage>メールアドレスの形式で入力してください</ErrorMessage>
      )}

      {/* 権限 */}
      <InputField>
        <Heading type="blockTitle" tag="span">
          権限
        </Heading>
        <Controller
          control={control}
          name="role"
          rules={{
            required: '権限を設定してください',
          }}
          render={(renderProps) => (
            <RadioButtonWrapper>
              {roles.map((role, index) => (
                <RadioButtonArea key={index}>
                  <RadioButtonLabel
                    label={role.label}
                    value={role.value}
                    checked={role.value === renderProps.value}
                    onChange={renderProps.onChange}
                    disabled={props.readonly}
                  />
                </RadioButtonArea>
              ))}
            </RadioButtonWrapper>
          )}
        />
      </InputField>
      {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>}
    </Section>
  )
}

const ErrorMessage = styled.div`
  color: red;
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const RadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 110px;
`

export default Component
