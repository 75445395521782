import React from 'react'
import styled from 'styled-components'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { Companion } from '../../../../../types/formData'

import { Heading } from '../../../../ui/Heading'
import { Section } from '../ShougaiMimaiKin'
import { Input } from '../../../../ui/Input'
import { RadioButtonLabel } from '../../../../ui/RadioButtonLabel'
import { Table, Body, Head, Row, Cell } from '../../../../ui/Table'
import { mediaQuery } from '../../../../../themes/size'
import { isSp } from 'libs/ua'
import { isMemberId, isZenKatakana } from '../../../../../util/validation'

type Props = {
  disabled: boolean
}

const initData: Companion[] = [
  {
    companionType: 'member',
    companionId: '',
    companionName: '',
    companionNameKana: '',
    relationship: '',
  },
]

export const CompanionSection: React.FC<Props> = (props) => {
  const { control, reset, watch, errors, getValues } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companions',
  })

  React.useEffect(() => {
    reset({
      companions: initData,
    })
  }, [reset])

  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■同伴利用者情報
      </Heading>
      <Wrapper>
        <Table>
          <TableHead>
            <TableHeadRow>
              <Cell>会員 / 家族</Cell>
              <Cell>会員番号</Cell>
              <Cell>氏名</Cell>
              <Cell>フリガナ</Cell>
              <Cell>続柄</Cell>
            </TableHeadRow>
          </TableHead>
          <Body>
            {fields.map((field, index) => (
              <Row key={field.id}>
                {isSp && index >= 1 && <Border />}
                <TdCell dataLabel={'会員 / 家族'}>
                  <CheckboxLabel>
                    <Controller
                      control={control}
                      name={`companions[${index}].companionType`}
                      defaultValue={
                        props.disabled
                          ? getValues(`companions[${index}].companionType`)
                          : 'member'
                      }
                      render={({ onChange, value }) => (
                        <RadioButtonWrapper>
                          <RadioButtonArea>
                            <RadioButtonLabel
                              label="会員"
                              value="member"
                              checked={value === 'member'}
                              onChange={onChange}
                              disabled={props.disabled}
                            />
                          </RadioButtonArea>
                          <RadioButtonArea>
                            <RadioButtonLabel
                              label="家族"
                              value="family"
                              checked={value === 'family'}
                              onChange={onChange}
                              disabled={props.disabled}
                            />
                          </RadioButtonArea>
                        </RadioButtonWrapper>
                      )}
                    />
                  </CheckboxLabel>
                </TdCell>
                <TdCell dataLabel={'会員番号'}>
                  {watch(`companions[${index}].companionType`) === 'member' && (
                    <Controller
                      control={control}
                      name={`companions[${index}].companionId`}
                      defaultValue={field.companionId}
                      rules={{
                        required: '会員番号を入力してください',
                        validate: { isMemberId },
                      }}
                      render={({ onChange, value, onBlur }) => (
                        <TableInput
                          type="text"
                          onChange={onChange}
                          value={value}
                          disabled={
                            watch(`companions[${index}].companionType`) ===
                              'family' || props.disabled
                          }
                          onBlurCapture={onBlur}
                        />
                      )}
                    />
                  )}
                  {watch(`companions[${index}].companionType`) === 'family' && (
                    <Controller
                      control={control}
                      name={`companions[${index}].companionId`}
                      defaultValue={field.companionId}
                      render={({ onChange }) => (
                        <TableInput
                          type="text"
                          onChange={onChange}
                          value=""
                          disabled
                        />
                      )}
                    />
                  )}
                  {watch(`companions[${index}].companionType`) === 'member' &&
                    errors?.['companions']?.[index]?.['companionId'] && (
                      <ErrorMessage>
                        {
                          errors?.['companions']?.[index]?.['companionId']?.[
                            'message'
                          ]
                        }
                      </ErrorMessage>
                    )}
                  {watch(`companions[${index}].companionType`) === 'member' &&
                    errors?.['companions']?.[index]?.['companionId']?.type ===
                      'isMemberId' && (
                      <ErrorMessage>
                        半角数字+半角ハイフンで入力してください
                      </ErrorMessage>
                    )}
                </TdCell>
                <TdCell dataLabel={'氏名'}>
                  <Controller
                    control={control}
                    name={`companions[${index}].companionName`}
                    defaultValue={field.companionName}
                    rules={{ required: '氏名を入力してください' }}
                    render={({ onChange, value, onBlur }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled={props.disabled}
                        onBlurCapture={onBlur}
                      />
                    )}
                  />
                  {errors?.['companions']?.[index]?.['companionName'] && (
                    <ErrorMessage>
                      {
                        errors?.['companions']?.[index]?.['companionName']?.[
                          'message'
                        ]
                      }
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'フリガナ'}>
                  <Controller
                    control={control}
                    name={`companions[${index}].companionNameKana`}
                    defaultValue={field.companionNameKana}
                    rules={{
                      required: 'フリガナを入力してください',
                      validate: { isZenKatakana },
                    }}
                    render={({ onChange, value, onBlur }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled={props.disabled}
                        onBlurCapture={onBlur}
                      />
                    )}
                  />
                  {errors?.['companions']?.[index]?.['companionNameKana']
                    ?.type === 'isZenKatakana' && (
                    <ErrorMessage>全角カナで入力してください</ErrorMessage>
                  )}
                  {errors?.['companions']?.[index]?.['companionNameKana'] && (
                    <ErrorMessage>
                      {
                        errors?.['companions']?.[index]?.[
                          'companionNameKana'
                        ]?.['message']
                      }
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'続柄'}>
                  <Controller
                    control={control}
                    name={`companions[${index}].relationship`}
                    defaultValue={field.relationship}
                    rules={{ required: '続柄を入力してください' }}
                    render={({ onChange, value, onBlur }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled={props.disabled}
                        onBlurCapture={onBlur}
                      />
                    )}
                  />
                  {errors?.['companions']?.[index]?.['relationship'] && (
                    <ErrorMessage>
                      {
                        errors?.['companions']?.[index]?.['relationship']?.[
                          'message'
                        ]
                      }
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell>
                  {!props.disabled && (
                    <DeleteButton type="button" onClick={() => remove(index)}>
                      削除
                    </DeleteButton>
                  )}
                </TdCell>
              </Row>
            ))}
          </Body>
        </Table>
        {!props.disabled && (
          <RowWrapper>
            <AddButtonWrapper>
              <AddButton
                type="button"
                onClick={() => {
                  append({})
                }}
                disabled={props.disabled}
              >
                <label htmlFor={'＋同伴利用者を追加する'}>
                  <Prefix></Prefix>
                  ＋同伴利用者を追加する
                </label>
              </AddButton>
            </AddButtonWrapper>
          </RowWrapper>
        )}
      </Wrapper>
    </Section>
  )
}

const Wrapper = styled.div`
  display: block;
  margin-top: 32px;
`
const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`
const TableInput = styled(Input)`
  > input[type='text'] {
    width: 232px;
  }
  > input[type='checkbox'] {
    width: 32px;
    cursor: pointer;
  }
`
const CheckboxLabel = styled.label`
  font-size: 16px;
  cursor: pointer;
`
const AddButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 450px;
  margin: 0 auto;
  text-align: center;
  > input[type='file'] {
    position: absolute;
    height: 100%;
    left: -10px;
    top: 0;
    margin: 0;
    font-size: 128px !important;
    opacity: 0;
    appearance: none;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  &:hover {
    > button {
      background-color: rgba(67, 154, 137, 0.5);
      color: rgb(255, 255, 255);
    }
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    &:hover {
      > button {
        background-color: #fff;
        color: #439a89;
      }
    }

    &:active {
      > button {
        background-color: rgba(67, 154, 137, 0.5);
        color: rgb(255, 255, 255);
      }
    }
  }
`
const Prefix = styled.span`
  display: inline-flex;
`
const DeleteButton = styled.button`
  margin-left: 8px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  padding: 0 16px;
  height: 45px;
  transition: all 0.3s ease-out 0s;
  &:hover {
    background-color: rgb(242, 242, 242);
  }
`
const AddButton = styled.button`
  font-family: inherit;
  font-weight: bold;
  border-radius: 27.5px;
  color: #439a89;
  background-color: #fff;
  border: solid 1px #439a89;
  font-size: 16px;
  height: 40px;
  padding: 0 46px;
  transition: all 0.3s ease-out 0s;
  > label {
    display: flex;
    align-items: center;
  }
  &.prefix {
    justify-content: left;
  }
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
  }
`
const RadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 80px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 24px;
    :last-of-type {
      margin-top: 8px;
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
`
const TableHead = styled(Head)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: none;
  }
`
const TableHeadRow = styled(Row)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
const TdCell = styled(Cell)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    text-align: right;
    width: 100%;

    &:before {
      content: attr(data-label);
      float: left;
      margin-right: 10px;
    }
  }
`
const Border = styled.hr`
  margin-bottom: 32px;
`
