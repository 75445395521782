import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { Heading } from '../../ui/Heading'
import { Textarea } from '../../ui/Textarea'
import { Section } from '../../main/applications/form/SeijinIwaiKin'
import { mediaQuery } from 'themes/size'

type Props = {
  disabled: boolean
}

export const MessageColumnSection: React.FC<Props> = (props) => {
  const { control } = useFormContext()

  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■通信欄
      </Heading>
      <TextareaField>
        <Controller
          control={control}
          name="messageColumn"
          render={({ onChange, value, onBlur }) => (
            <Textarea
              value={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </TextareaField>
    </Section>
  )
}

const TextareaField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
