import * as FormDataType from '../../types/formData'
import * as CreateRequestType from '../../types/requestBody/ApplicationCreateRequest'
import { FORM_TYPE_IDS } from '../../const/formTypeIds'
import { convertToDateTime } from '../../presenter/dateUtil'
import {
  ApplicantRequestParts,
  AttachmentRequestParts,
  BankAccountRequestParts,
  OfficeApplicantRequestPart,
  OfficeInfoRequestPart,
} from '../../types/requestBody/ApplicationCreateRequest'
import { Family } from '../../types/LoginInfo'
import { WithdrawalMember } from '../../types/formData'

export const mapToShukuhakuRiyouHojoKinRequest = async (
  formData: FormDataType.ShukuhakuRiyouHojoKinData['formData'],
): Promise<CreateRequestType.ShukuhakuRiyouHojoKinCreateRequest> => {
  const companions = (() => {
    const { companions: data } = formData
    return !data
      ? []
      : data.map((companion) => ({
          companionType: companion.companionType,
          companionId: companion.companionId,
          companionName: companion.companionName,
          companionNameKana: companion.companionNameKana,
          relationship: companion.relationship,
        }))
  })()
  return {
    formType: FORM_TYPE_IDS.SHUKUHAKURIYOUHOJOKIN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      facilityName: formData.facilityName,
      useDate: convertToDateTime(formData.useDate),
      stayDays: formData.stayDays,
      numOfUsers: formData.numOfUsers,
      numOfBunkinMembers: formData.numOfBunkinMembers,
      numOfFamilyUsers: formData.numOfFamilyUsers,
      amount: formData.amount,
      companions: companions,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToShibouChouiKinrequest = async (
  formData: FormDataType.ShibouChouiKinData['formData'],
): Promise<CreateRequestType.ShibouChouiKinCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.SHIBOUCHOUIKIN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      deadPerson: formData.deadPerson,
      deadPersonName: formData.deadPersonName,
      deadPersonNameKana: formData.deadPersonNameKana,
      deathDate: convertToDateTime(formData.deathDate),
      birthDay: convertToDateTime(formData.birthDay),
      appliedPersonName: formData.appliedPersonName,
      relationship: formData.relationship,
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToShougaiMimaiKinRequest = async (
  formData: FormDataType.ShougaiMimaiKinData['formData'],
): Promise<CreateRequestType.ShougaiMimaiKinCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.SHOUGAIMIMAIKIN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      birthDay: convertToDateTime(formData.birthDay),
      disabilityCertificateDate: formData.disabilityCertificateDate
        ? convertToDateTime(formData.disabilityCertificateDate)
        : null,
      disabilityGrade: formData.disabilityGrade,
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToJutakuSaigaimimaiKinRequest = async (
  formData: FormDataType.JutakuSaigaiMimaiKinData['formData'],
): Promise<CreateRequestType.JutakuSaigaiMimaiKinCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.JUTAKUSAIGAIMIMAIKIN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      disasterType: formData.disasterType,
      disasterDate: formData.disasterDate
        ? convertToDateTime(formData.disasterDate)
        : null,
      disasterLevel: formData.disasterLevel,
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToNyuinMimaiKinRequest = async (
  formData: FormDataType.NyuinMimaiKinData['formData'],
): Promise<CreateRequestType.NyuinMimaiKinCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.NYUINMIMAIKIN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      hospitalizedDate: convertToDateTime(formData.hospitalizedDate),
      dischargedDate: convertToDateTime(formData.dischargedDate),
      hospitalizedDays: formData.hospitalizedDays,
      diseaseName: formData.diseaseName,
      hospitalName: formData.hospitalName,
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToKekkonGinkonKinkonRequest = async (
  formData: FormDataType.KekkonGinkonKinkonData['formData'],
): Promise<CreateRequestType.KekkonGinkonKinkonCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.KEKKONGINKONKINKON,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      type: formData.type,
      spouseName: formData.spouseName,
      spouseNameKana: formData.spouseNameKana,
      marriageDate: convertToDateTime(formData.marriageDate),
      sex: formData.sex,
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToShussanRequest = async (
  formData: FormDataType.ShussanData['formData'],
): Promise<CreateRequestType.ShussanCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.SHUSSAN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      birthName: formData.birthName,
      birthNameKana: formData.birthNameKana,
      sex: formData.sex,
      relationship: formData.relationship,
      birthDay: convertToDateTime(formData.birthDay),
      birthOrder: formData.birthOrder,
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToShugakuIwaiKinRequest = async (
  formData: FormDataType.ShugakuIwaiKinData['formData'],
): Promise<CreateRequestType.ShugakuIwaiKinCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.SHUGAKUIWAIKIN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      schoolInfo: formData.schoolInfo,
      studentName: formData.studentName,
      studentNameKane: formData.studentNameKane,
      relationship: formData.relationship,
      birthDay: convertToDateTime(formData.birthDay),
      schoolName: formData.schoolName,
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToSeijinIwaiKinRequest = async (
  formData: FormDataType.SeijinIwaiKinData['formData'],
): Promise<CreateRequestType.SeijinIwaiKinCreateRequest> => {
  return {
    formType: FORM_TYPE_IDS.SEIJINIWAIKIN,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
      attachedFileObject: await mapToAttachedFileRequestParts(
        formData.attachedFileObject,
      ),
      birthDay: convertToDateTime(formData.birthDay),
      amount: formData.amount,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToKaiinjouhouHenkoutodokeRequest = (
  formData: FormDataType.KaiinjouhouHenkoutodokeData['formData'],
): CreateRequestType.KaiinjouhouHenkoutodokeCreateRequest => {
  return {
    formType: FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      name: formData.name,
      nameKana: formData.nameKana,
      address: formData.address,
      postalCode: formData.postalCode,
      tel: formData.tel,
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToDoukyokazokuHenkoutodokeRequest = (
  formData: FormDataType.DoukyoKazokuHenkoutodokeData['formData'],
): CreateRequestType.DoukyokazokuHenkotodokeCreateRequest => {
  const toFamilyRequest = (family: Family) => ({
    name: family.name,
    nameKana: family.nameKana,
    birthDay: convertToDateTime(family.birthDay),
    relationship: family.relationship,
    sex: family.sex,
  })
  return {
    formType: FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE,
    data: {
      applicant: mapToApplicantRequestParts(formData.applicant),
      previousFamily: formData.previousFamily
        ? formData.previousFamily.map(toFamilyRequest)
        : [],
      family: formData.family ? formData.family.map(toFamilyRequest) : [],
      messageColumn: formData.messageColumn,
    },
  }
}

export const mapToOfficeTeikikenkoshindanJoseikinRequest = async (
  formData: FormDataType.OfficeTeikiKenkoshindanJoseikinData['formData'],
): Promise<CreateRequestType.OfficeTeikikenkoshindanJoseikinCreateRequest> => ({
  formType: FORM_TYPE_IDS.OFFICE_TEIKIKENKOSHINDAN_JOSEIKIN,
  data: {
    officeInfo: mapToOfficeInfoRequestParts(formData.officeInfo),
    applicant: mapToOfficeApplicantRequestParts(formData.applicant),
    facilityName: formData.facilityName,
    numOfUsers: formData.numOfUsers,
    firstUseDate: convertToDateTime(formData.firstUseDate),
    amount: formData.amount,
    attachedFileObject: await mapToAttachedFileRequestParts(
      formData.attachedFileObject,
    ),
    bankAccount: mapToBankAccountRequestParts(formData.bankAccount),
    messageColumn: formData.messageColumn,
  },
})

export const mapToOfficeKaiinjouhouHenkoutodokeCreateRequest = (
  formData: FormDataType.OfficeKaiinjouhouHenkoutodokeData['formData'],
): CreateRequestType.OfficeKaiinjouhouHenkoutodokeCreateRequest => ({
  formType: FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE,
  data: {
    officeInfo: mapToOfficeInfoRequestParts(formData.officeInfo),
    applicant: mapToOfficeApplicantRequestParts(formData.applicant),
    name: formData.name,
    nameKana: formData.nameKana,
    representativeName: formData.representativeName,
    representativeNameKana: formData.representativeNameKana,
    clerkName: formData.clerkName,
    clerkNameKana: formData.clerkNameKana,
    postalCode: formData.postalCode,
    address: formData.address,
    tel: formData.tel,
    paymentMethod: formData.paymentMethod,
    messageColumn: formData.messageColumn,
  },
})

export const mapToOfficeIchibutaikaitodokeCreateRequest = (
  formData: FormDataType.OfficeIchibutaikaitodokeData['formData'],
): CreateRequestType.OfficeIchibutaikaitodokeCreateRequest => {
  const toWithdrawalMemberRequest = (member: WithdrawalMember) => ({
    memberId: member.memberId,
    name: member.name,
    nameKana: member.nameKana,
    withdrawalDate: member.withdrawalDate
      ? convertToDateTime(member.withdrawalDate)
      : null,
    withdrawalReason: member.withdrawalReason,
  })
  return {
    formType: FORM_TYPE_IDS.OFFICE_ICHIBUTAIKAITODOKE,
    data: {
      officeInfo: mapToOfficeInfoRequestParts(formData.officeInfo),
      applicant: mapToOfficeApplicantRequestParts(formData.applicant),
      WithdrawalMembers: formData.WithdrawalMembers.map(
        toWithdrawalMemberRequest,
      ),
      messageColumn: formData.messageColumn,
    },
  }
}

const mapToApplicantRequestParts = (
  applicantData: FormDataType.MemberInfo,
): ApplicantRequestParts => ({
  id: applicantData.id,
  officeName: applicantData.officeName,
  officeNameKana: applicantData.officeNameKana,
  name: applicantData.name,
  nameKana: applicantData.nameKana,
  postalCode: applicantData.postalCode,
  address: applicantData.address,
  tel: applicantData.tel,
  email: applicantData.email,
})

const mapToOfficeInfoRequestParts = (
  officeInfoData: FormDataType.OfficeInfo,
): OfficeInfoRequestPart => ({
  officeId: officeInfoData.officeId,
  name: officeInfoData.name,
  nameKana: officeInfoData.nameKana,
  postalCode: officeInfoData.postalCode,
  representativeName: officeInfoData.representativeName,
  representativeNameKana: officeInfoData.representativeNameKana,
  clerkName: officeInfoData.clerkName,
  clerkNameKana: officeInfoData.clerkNameKana,
  address: officeInfoData.address,
  tel: officeInfoData.tel,
  paymentMethod: officeInfoData.paymentMethod,
})

const mapToOfficeApplicantRequestParts = (
  officeApplicantData: FormDataType.OfficeApplicant,
): OfficeApplicantRequestPart => ({
  name: officeApplicantData.name,
  nameKana: officeApplicantData.nameKana,
  department: officeApplicantData.department,
  tel: officeApplicantData.tel,
  email: officeApplicantData.email,
})

const mapToBankAccountRequestParts = (
  bankAccountData: FormDataType.BankAccount,
): BankAccountRequestParts => ({
  bankName: bankAccountData.bankName,
  branchName: bankAccountData.branchName,
  accountType: bankAccountData.accountType,
  accountNumber: bankAccountData.accountNumber,
  accountOwnerName: bankAccountData.accountOwnerName,
  accountOwnerNameKana: bankAccountData.accountOwnerNameKana,
})

export const mapToAttachedFileRequestParts = async (
  attachedFileData: FormDataType.FileInputType,
): Promise<AttachmentRequestParts> => {
  const attachedFiles = attachedFileData.map(async (file) => ({
    name: file.name,
    base64: await toBase64(file),
  }))
  return Promise.all(attachedFiles)
}

// https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
const toBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}
