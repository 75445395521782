import React, { VFC, useCallback } from 'react'
import styled from 'styled-components'

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  mixed?: boolean
}

export const CheckBox: VFC<Props> = ({
  mixed = false,
  className = '',
  onChange,
  ...props
}) => {
  const { disabled } = props
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e)
    },
    [onChange],
  )

  return (
    <Wrapper className={`${className}`}>
      <Input
        {...props}
        type="checkbox"
        onChange={handleChange}
        disabled={disabled}
      />
    </Wrapper>
  )
}

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  line-height: 1;
  box-sizing: border-box;
`
const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
`
