import React from 'react'
import styled from 'styled-components'

import { ShibouChouiKinData } from '../../../../types/formData'

import { Heading } from '../../../ui/Heading'
import { Input } from '../../../ui/Input'
import { RadioButtonLabel } from '../../../ui/RadioButtonLabel'
import { DatePicker } from '../../../ui/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CONFIG, PATH } from '../../../../const'

import {
  BankAccountSection,
  FileSection,
  MemberInfoSection,
} from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { FORM_TYPE_IDS } from '../../../../const/formTypeIds'
import { mediaQuery } from '../../../../themes/size'
import {
  isAmountYen,
  isYYYYMMDD,
  isZenKatakana,
} from '../../../../util/validation'

type Props = {
  disabled: boolean
  canAdminUpload?: boolean
  handleSaveImage?: (files: File) => Promise<void>
  isUploading?: boolean
  handleDeleteImage?: (fileId: string) => Promise<void>
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const methods = useFormContext<ShibouChouiKinData['formData']>()
  const { control, errors, watch, getValues } = methods

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼給付金申請 - 死亡弔慰金
        </Heading>
      </HeadingArea>

      {Object.keys(methods.errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■申請情報
        </Heading>
        <InputFieldFourRadio>
          <Heading type="blockTitle" tag="span">
            死亡者選択
          </Heading>
          <Controller
            control={control}
            name="deadPerson"
            defaultValue={props.disabled ? getValues('deadPerson') : 'person'}
            render={({ onChange, value }) => (
              <RadioButtonWrapper>
                <RadioButtonArea>
                  <RadioButtonLabel
                    label="本人"
                    value="person"
                    checked={value === 'person'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </RadioButtonArea>
                <RadioButtonArea>
                  <RadioButtonLabel
                    label="配偶者"
                    value="spouse"
                    checked={value === 'spouse'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </RadioButtonArea>
                <RadioButtonArea>
                  <RadioButtonLabel
                    label="親"
                    value="parent"
                    checked={value === 'parent'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </RadioButtonArea>
                <WideRadioButtonArea>
                  <RadioButtonLabel
                    label="子（流産の場合は７ヶ月以上）"
                    value="child"
                    checked={value === 'child'}
                    onChange={onChange}
                    disabled={props.disabled}
                  />
                </WideRadioButtonArea>
              </RadioButtonWrapper>
            )}
          />
        </InputFieldFourRadio>
        {errors.deadPerson && (
          <ErrorMessage>{errors.deadPerson.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            死亡者氏名
          </Heading>
          <Controller
            control={control}
            name="deadPersonName"
            rules={{ required: '死亡者氏名を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.deadPersonName && (
          <ErrorMessage>{errors.deadPersonName.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            フリガナ
            <br />
            (全角カナ)
          </Heading>
          <Controller
            control={control}
            name="deadPersonNameKana"
            rules={{
              required: 'フリガナを入力してください',
              validate: { isZenKatakana },
            }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.deadPersonNameKana && (
          <ErrorMessage>{errors.deadPersonNameKana.message}</ErrorMessage>
        )}
        {errors.deadPersonNameKana?.type === 'isZenKatakana' && (
          <ErrorMessage>全角カナで入力してください</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            死亡日
          </Heading>
          <Controller
            control={control}
            name="deathDate"
            rules={{
              required: '死亡日を入力してください',
              validate: { isYYYYMMDD },
            }}
            render={({ onChange, value, onBlur }) => (
              <DatePicker
                onChange={onChange}
                value={value}
                disabled={props.disabled}
                onBlur={onBlur}
              />
            )}
          />
        </InputField>
        {errors.deathDate && (
          <ErrorMessage>{errors.deathDate.message}</ErrorMessage>
        )}
        {errors.deathDate?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        {watch('deadPerson', 'person') === 'person' && (
          <InputField>
            <Heading type="blockTitle" tag="span">
              生年月日
            </Heading>
            <Controller
              control={control}
              name="birthDay"
              rules={{
                required: '生年月日を入力してください',
                validate: { isYYYYMMDD },
              }}
              render={({ onChange, value, onBlur }) => (
                <DatePicker
                  onChange={onChange}
                  value={value}
                  disabled={props.disabled}
                  openToDate={true}
                  onBlur={onBlur}
                />
              )}
            />
          </InputField>
        )}
        {errors.birthDay && (
          <ErrorMessage>{errors.birthDay.message}</ErrorMessage>
        )}
        {errors.birthDay?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        {watch('deadPerson', 'person') === 'person' && (
          <InputField>
            <Heading type="blockTitle" tag="span">
              給付金申請者氏名
            </Heading>
            <Controller
              control={control}
              name="appliedPersonName"
              rules={{ required: '給付金申請者氏名を入力してください' }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
          </InputField>
        )}
        {errors.appliedPersonName && (
          <ErrorMessage>{errors.appliedPersonName.message}</ErrorMessage>
        )}
        {watch('deadPerson', 'person') === 'person' && (
          <InputField>
            <Heading type="blockTitle" tag="span">
              請求者続柄
            </Heading>
            <Controller
              control={control}
              name="relationship"
              rules={{ required: '請求者続柄を入力してください' }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
          </InputField>
        )}
        {errors.relationship && (
          <ErrorMessage>{errors.relationship.message}</ErrorMessage>
        )}
        {watch('deadPerson', 'person') !== 'person' && (
          <InputField>
            <Heading type="blockTitle" tag="span">
              請求金額
            </Heading>
            <LabelWrapper>
              <Controller
                control={control}
                name="amount"
                defaultValue="10,000"
                render={({ onChange, value }) => (
                  <Input
                    type="text"
                    onChange={onChange}
                    value={value}
                    disabled
                  />
                )}
              />
              <InputLabel>円</InputLabel>
            </LabelWrapper>
          </InputField>
        )}
        {watch('deadPerson', 'person') === 'person' && (
          <>
            <InputField>
              <Heading type="blockTitle" tag="span">
                請求金額
                <br />
                (半角数字)
              </Heading>
              <LabelWrapper>
                <Controller
                  control={control}
                  name="amount"
                  defaultValue=""
                  rules={{
                    required: '請求金額を入力してください',
                    validate: { isAmountYen },
                  }}
                  render={({ onChange, value, onBlur }) => (
                    <Input
                      type="text"
                      onChange={onChange}
                      value={value}
                      disabled={props.disabled}
                      onBlurCapture={onBlur}
                    />
                  )}
                />
                <InputLabel>円</InputLabel>
              </LabelWrapper>
            </InputField>
            {errors.amount && (
              <ErrorMessage>{errors.amount.message}</ErrorMessage>
            )}
            {errors.amount?.type === 'isAmountYen' && (
              <ErrorMessage>半角数字で入力してください</ErrorMessage>
            )}
          </>
        )}
        {watch('deadPerson', 'person') === 'person' && (
          <LinkHeading type="sectionTitle" tag="h2">
            請求金額は
            <AnchorLink href={CONFIG.KYUFUKIN_SHOSAI_URL} target="_blank">
              こちら
            </AnchorLink>
            を御覧ください
          </LinkHeading>
        )}
        {props.shouldShowOtherFormLinks &&
          watch('deadPerson', 'person') !== 'person' && (
            <Heading type="sectionTitle" tag="h2">
              ※同居家族情報を変更する場合は
              <TextLink
                href={`${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE}`}
                target="_blank"
              >
                こちら
              </TextLink>
            </Heading>
          )}
      </Section>

      <FileSection
        headingLabel="受取人と死亡した人の関係が証明できる戸籍謄本（死亡記載のあるもの）"
        disabled={props.disabled}
        canAdminUpload={props.canAdminUpload}
        handleSaveImage={props.handleSaveImage}
        isUploading={props.isUploading}
        handleDeleteImage={props.handleDeleteImage}
      />

      <BankAccountSection disabled={props.disabled} isMember={true} />

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
// @fixme 子コンポーネントから共通で参照できるように一旦exportしました
export const Section = styled.section`
  margin-top: 72px;
`
// @fixme 子コンポーネントから共通で参照できるように一旦exportしました
export const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
export const InputFieldFourRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 765px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
  }
`
const RadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 110px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 8px;
  }
`
const WideRadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 256px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 8px;
  }
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const InputLabel = styled.label`
  margin-left: 8px;
  font-size: 16px;
`
const LinkHeading = styled(Heading)`
  margin-top: 32px;
`
const AnchorLink = styled.a`
  color: #439a89;
  &:hover {
    text-decoration: none;
  }
`
const TextLink = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`

export default Component
