export const getStepLabel = (currentStep: string) => {
  const PREFIX = ' - '
  let label = ''
  switch (currentStep) {
    case 'input':
      label = `${PREFIX}入力`
      break
    case 'confirm':
      label = `${PREFIX}確認`
      break
  }
  return label
}
