import React, { FC, TextareaHTMLAttributes, useEffect, useRef } from 'react'
import styled from 'styled-components'

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: boolean
  width?: number | string
  autoFocus?: boolean
}

export const Textarea: FC<Props> = ({
  autoFocus,
  maxLength,
  width,
  ...props
}) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (autoFocus && ref && ref.current) {
      ref.current.focus()
    }
  }, [autoFocus])

  return (
    <>
      <StyledTextarea {...props} ref={ref} />
    </>
  )
}

const StyledTextarea = styled.textarea`
  padding: 0.5em;
  font-size: 16px;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 240px;
  background-color: rgba(33, 33, 33, 0.08);
  border: 1px solid rgb(214, 211, 208);
  box-sizing: border-box;
  &[disabled] {
    background-color: rgb(190, 190, 190);
    pointer-events: none;
  }
`
