import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { OfficeInfoSection } from '../../layout/inputParts/OfficeInfoSection'
import { Heading } from '../../ui/Heading'
import { Button } from '../../ui/Button'
import { PartialLoader } from '../../ui/Loader'
import { MessageModal } from '../../ui/Modal'
import { API, PATH } from '../../../const'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { FormDataInterface, OfficeInfo } from '../../../types/formData'
import { useMultiStepState } from '../../../hooks/useMultiStepState'
import { InputField } from '../applications/form/ShougaiMimaiKin'
import { Input } from '../../ui/Input'
import { BrowserBackWarning } from '../../util'

const Component: React.FC = () => {
  const initData: OfficeInfo & { password: string } = {
    officeId: '',
    name: '',
    nameKana: '',
    postalCode: '',
    representativeName: '',
    representativeNameKana: '',
    clerkName: '',
    clerkNameKana: '',
    address: '',
    tel: '',
    paymentMethod: 'cache',
    password: '',
  }

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initData,
  })

  const history = useHistory()

  const apiPath = `${API.ADMIN_OFFICES}/`

  const handleConfirm = async (): Promise<boolean> => {
    document?.querySelector('main')?.scrollTo(0, 0)
    return await methods.trigger()
  }

  const handleSend = async (): Promise<void> => {
    await methods.handleSubmit(async (data: FormDataInterface['formData']) => {
      const requestBody = data
      const res = await fetch(apiPath, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        method: 'post',
        mode: 'cors',
        body: JSON.stringify(requestBody),
      })
      if (res.status >= 400) {
        setIsComplete(false)
        return
      }
      methods.reset()
    })()
  }

  const formState = useMultiStepState()
  const shouldFormDisabled = formState.currentStep === 'confirm'

  const { bearerToken } = useContext(AdminAuthContext)

  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(true)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleConfirmClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    const isValid = await handleConfirm()
    if (isValid) {
      formState.nextStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
    setButtonDisabled(false)
  }

  const handleSendClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    await handleSend()
    setIsCompleteModalOpen(true)
    setButtonDisabled(false)
  }

  const handleModalClose = (): void => {
    setIsCompleteModalOpen(false)
    if (isComplete) {
      const officeId = methods.watch('officeId')
      history.push(`${PATH.ADMIN_OFFICES}/${officeId}`)
    }
  }

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (formState.currentStep === 'input') {
      history.push(PATH.ADMIN_OFFICES)
    } else if (formState.currentStep === 'confirm') {
      formState.prevStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <BrowserBackWarning
          isDirty={methods.formState.isDirty}
          message="事業所が作成されていません。本当に戻りますか？"
        />
        <Heading type="screenTitle" tag="h1">
          新規登録
        </Heading>

        <OfficeInfoSection
          officeInfo={initData}
          currentStep={formState.currentStep}
          disabled={shouldFormDisabled}
          isCreate={true}
          isAdmin={true}
        />

        <InputFieldWrapper>
          <Heading type="blockTitle" tag="span">
            初期パスワード
          </Heading>
          <Controller
            control={methods.control}
            name="password"
            rules={{
              required: '初期パスワードを入力してください',
              minLength: { value: 8, message: '8文字以上で入力してください' },
            }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                value={value}
                onChange={onChange}
                disabled={shouldFormDisabled}
                placeholder="※8文字以上で入力してください"
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputFieldWrapper>
        {methods.errors.password && (
          <ErrorMessage>{methods.errors.password.message}</ErrorMessage>
        )}

        <ButtonWrapper>
          {formState.currentStep === 'input' && (
            <Button
              type="button"
              onClick={handleConfirmClick}
              disabled={buttonDisabled}
            >
              確認
            </Button>
          )}
          {formState.currentStep === 'confirm' && (
            <Button
              type="button"
              onClick={handleSendClick}
              disabled={buttonDisabled}
            >
              {buttonDisabled ? <PartialLoader /> : '登録'}
            </Button>
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          <BackButton type="button" onClick={handleBackClick}>
            戻る
          </BackButton>
        </ButtonWrapper>
        <MessageModal
          isOpen={isCompleteModalOpen}
          title={isComplete ? '登録しました' : '登録に失敗しました'}
          description={
            isComplete ? undefined : (
              <p>しばらく経ってから再度お試しください。</p>
            )
          }
          closeText="OK"
          onClickClose={handleModalClose}
          onClickOverlay={handleModalClose}
          onPressEscape={handleModalClose}
          id="dialog-message"
        />
      </FormProvider>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`
const InputFieldWrapper = styled(InputField)`
  margin-top: 40px;
`
const ErrorMessage = styled.div`
  color: red;
`

export default Component
