import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import { Link, useLocation } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { drawerWidth, LoginType } from '../UserLayout'
import {
  memberSidebar,
  officeSidebar,
  adminSidebar,
} from '../../../data/sidebar'
import { PATH } from '../../../const'
import { ReactNode } from 'react'
import { isSp } from 'libs/ua'

type Props = {
  handleDrawerClose: () => void
  isDrawerOpen: boolean
  handleLogout?: (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<JSX.Element>
  loginType: LoginType
}

const Sidebar: React.FC<Props> = (props) => {
  const classes = useStyles()
  const currentPath = useLocation().pathname
  const isActivePath = (path: string) => {
    // 前方一致だとtop系(`/`とか`/office`とか)が全部ひっかかるため
    const regex = [PATH.TOP, PATH.OFFICE_TOP, PATH.ADMIN_TOP].includes(path)
      ? `^${path}$`
      : `^${path}*`
    return currentPath.match(new RegExp(regex))
  }
  const sidebarList = (() => {
    switch (props.loginType) {
      case 'member':
        return memberSidebar
      case 'office':
        return officeSidebar
      case 'admin':
        return adminSidebar
    }
  })()
  const LinkOrAnchor = (props: {
    to: string
    className: string
    children: ReactNode
  }): JSX.Element => {
    const isExternal = props.to.startsWith('http')
    return isExternal ? (
      <a
        href={props.to}
        className={props.className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    ) : (
      <Link to={props.to} className={props.className}>
        {props.children}
      </Link>
    )
  }
  return (
    <Drawer
      variant={isSp ? 'temporary' : 'permanent'}
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !props.isDrawerOpen && classes.drawerPaperClose,
        ),
      }}
      open={props.isDrawerOpen}
      onClose={props.handleDrawerClose}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronLeftIcon htmlColor="white" />
        </IconButton>
      </div>
      <Divider />
      {sidebarList.map((sidebar, index) => (
        <List key={index}>
          <LinkOrAnchor to={sidebar.linkPath} className={classes.link}>
            <ListItem
              button
              className={
                isActivePath(sidebar.linkPath)
                  ? classes.activeItem
                  : classes.inactiveItem
              }
            >
              <ListItemText>
                <Typography align="center">{sidebar.label}</Typography>
              </ListItemText>
            </ListItem>
          </LinkOrAnchor>
        </List>
      ))}
      {isSp && (
        <LinkOrAnchor to={''} className={classes.link}>
          <ListItem button className={classes.inactiveItem}>
            <ListItemText>
              <Typography align="center" onClick={props.handleLogout}>
                ログアウト
              </Typography>
            </ListItemText>
          </ListItem>
        </LinkOrAnchor>
      )}
    </Drawer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      backgroundColor: '#333',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      [theme.breakpoints.up('sm')]: {
        width: 0,
      },
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
    activeItem: {
      color: 'black',
      backgroundColor: 'white',
    },
    inactiveItem: {
      color: 'white',
    },
  }),
)

export default Sidebar
