import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Heading } from '../../../../ui/Heading'
import { Section } from '../../../applications/form/ShougaiMimaiKin'
import styled from 'styled-components'
import { InputFile as InputFileBase } from '../../../../ui/InputFile'
import { CONFIG } from '../../../../../const'
import { MessageModal } from '../../../../ui/Modal'

type Props = {
  headingLabel: string
  disabled: boolean
  canAdminUpload?: boolean
  handleSaveImage?: (files: File) => Promise<void>
  isUploading?: boolean
  handleDeleteImage?: (fileId: string) => Promise<void>
}

export const FileSection: React.FC<Props> = (props) => {
  const { control, setValue, watch, errors, clearErrors } = useFormContext()

  const checkFileCount = (addFiles: File[]) => {
    let result = true

    // 管理者はファイル数制限なし
    if (props.canAdminUpload) {
      return result
    }

    // 一気に11個以上ファイルをアップした場合
    if (!watch('attachedFileObject') && addFiles.length > 10) {
      result = false
    }

    // 少しずつアップして11個以上になった場合
    if (
      watch('attachedFileObject') &&
      watch('attachedFileObject').length + addFiles.length > 10
    ) {
      result = false
    }

    return result
  }

  const checkFileSize = (files: File[]) => {
    let result = true

    files.forEach((file) => {
      if (file.size >= CONFIG.UPLOAD_FILE_SIZE_LIMIT) {
        result = false
      }
    })

    return result
  }

  const [isOverCountOpen, setIsOverCountOpen] = useState(false)
  const overCountModalOpen = () => setIsOverCountOpen(true)
  const onClickOverCountClose = () => {
    setIsOverCountOpen(false)
  }

  const [isOverSizeOpen, setIsOverSizeOpen] = useState(false)
  const overSizeModalOpen = () => setIsOverSizeOpen(true)
  const onClickOverSizeClose = () => {
    setIsOverSizeOpen(false)
  }

  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■添付書類
      </Heading>
      <InputFileField>
        <Heading type="blockTitle" tag="span">
          {props.headingLabel}
        </Heading>
        <Controller
          control={control}
          name="attachedFileObject"
          rules={{ required: 'ファイルを選択してください' }}
          render={() => (
            <InputFile
              label="アップロード"
              onAdd={async (addFiles) => {
                if (!checkFileCount(addFiles)) {
                  // ファイルは10個まで
                  overCountModalOpen()
                  return
                }

                // ファイルサイズのチェック
                if (!checkFileSize(addFiles)) {
                  overSizeModalOpen()
                } else {
                  let currentFiles = watch('attachedFileObject')

                  if (currentFiles) {
                    currentFiles = currentFiles.concat(addFiles)
                  } else {
                    currentFiles = addFiles
                  }

                  clearErrors('attachedFileObject')

                  if (props.canAdminUpload && props.handleSaveImage) {
                    await props.handleSaveImage(
                      currentFiles[currentFiles.length - 1],
                    )
                  } else {
                    setValue('attachedFileObject', currentFiles, {
                      shouldDirty: true,
                    })
                  }
                }
              }}
              onDelete={async (index) => {
                let files = watch('attachedFileObject') ?? []
                if (props.disabled) {
                  files = files.concat(watch('attachedFileResponse'))
                }
                const targetFile = files[index]
                files.splice(index, 1)

                if (props.canAdminUpload && props.handleDeleteImage) {
                  await props.handleDeleteImage(targetFile.id)
                } else {
                  setValue(
                    'attachedFileObject',
                    files.length === 0 ? undefined : files,
                    { shouldDirty: true },
                  )
                }
              }}
              files={watch('attachedFileObject')}
              fileResponse={watch('attachedFileResponse')}
              multiple
              accept=".jpg, .jpeg, .png, .pdf"
              disabled={props.disabled}
              isUploading={props.isUploading}
              canAdminUpload={props.canAdminUpload}
            />
          )}
        />
      </InputFileField>
      {errors.attachedFileObject && (
        <ErrorMessage>{errors.attachedFileObject.message}</ErrorMessage>
      )}
      <Paragraph>
        ※アップロードサイズの上限は10MBです。
        <br />
        ※アップロードファイルはPDF / JPG / PNGのいずれかとなります。
      </Paragraph>
      <MessageModal
        isOpen={isOverCountOpen}
        title="添付ファイルは最大10個までです"
        description={<p>不要なファイルは削除してください。</p>}
        closeText="OK"
        onClickClose={onClickOverCountClose}
        onClickOverlay={onClickOverCountClose}
        onPressEscape={onClickOverCountClose}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isOverSizeOpen}
        title="ファイルサイズが10MBを超えています"
        description={<p>アップロードしたファイルが10MBを超えています。</p>}
        closeText="OK"
        onClickClose={onClickOverSizeClose}
        onClickOverlay={onClickOverSizeClose}
        onPressEscape={onClickOverSizeClose}
        id="dialog-message"
      />
    </Section>
  )
}

const InputFileField = styled.div`
  margin-top: 16px;
`
const InputFile = styled(InputFileBase)`
  margin-top: 50px;
`
const Paragraph = styled.p`
  margin-top: 24px;
  font-size: 12px;
`
const ErrorMessage = styled.div`
  color: red;
`
