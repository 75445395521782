import { useParams } from 'react-router-dom'
import { getFormComponentType } from './form'
import { FormProvider, useForm } from 'react-hook-form'
import { FormDataInterface } from '../../../types/formData'
import React, { useContext } from 'react'
import FormStepLayout from './layout/FormStepLayout'
import { mapToApplicationCreateRequestObject } from '../../../mapper/request'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import { FormType } from '../../../types/formType'
import { API } from '../../../const'
import { BrowserBackWarning } from '../../util'

export type ParamType = {
  formType: FormType
}

const Create: React.FC = () => {
  const { formType } = useParams<ParamType>()

  const { loginInfo, bearerToken, revalidate } = useContext(OfficeAuthContext)
  const { office: officeInfo } = loginInfo

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { officeInfo },
  })

  const FormComponentType = getFormComponentType(formType)

  const handleConfirm = async (): Promise<boolean> => {
    document?.querySelector('main')?.scrollTo(0, 0)
    return await methods.trigger()
  }

  const handleSend = async (): Promise<void> => {
    await methods.handleSubmit(async (data: FormDataInterface['formData']) => {
      data.attachedFileObject =
        (await methods.watch('attachedFileObject')) ?? []
      const requestBody = await mapToApplicationCreateRequestObject(
        formType,
        data,
      )

      const res = await fetch(API.APPLICATIONS, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        method: 'post',
        mode: 'cors',
        body: JSON.stringify(requestBody),
      })
      if (res.status >= 400) {
        throw new Error('申請が完了しませんでした')
      }
      methods.reset(data)
    })()
    await revalidate()
  }

  return (
    <FormProvider {...methods}>
      <BrowserBackWarning
        isDirty={methods.formState.isDirty}
        message="申請が保存されていません。本当に戻りますか？"
      />
      <FormStepLayout
        FormComponentType={FormComponentType}
        handleConfirm={handleConfirm}
        handleSend={handleSend}
        formType={formType}
      />
    </FormProvider>
  )
}

export default Create
