import { AdminLoginInfo } from '../../types/AdminLoginInfo'

export default function toAdminLoginInfo(json: any): AdminLoginInfo {
  return {
    uid: json.uid,
    admin: {
      adminId: json.admin.adminId,
      email: json.admin.email,
      name: json.admin.name,
      role: json.admin.role,
    },
  }
}
