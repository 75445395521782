import { ReactNode, VFC, useCallback, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { FocusTrap } from './FocusTrap'
import { ModalPositionProvider } from './ModalPositionProvider'
import { isSp } from 'libs/ua'

export type ModalContentInnerProps = {
  onClickOverlay?: () => void
  onPressEscape?: () => void
  isOpen: boolean
  top?: number
  right?: number
  bottom?: number
  left?: number
  id?: string
  ariaLabel?: string
  ariaLabelledby?: string
  children: ReactNode
}

export const ModalContentInner: VFC<ModalContentInnerProps> = ({
  onClickOverlay,
  onPressEscape = () => {},
  isOpen,
  id,
  ariaLabel,
  ariaLabelledby,
  children,
  ...props
}) => {
  const domRef = useRef(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const focusTarget = useRef<HTMLDivElement>(null)

  const handleClickOverlay = useCallback(() => {
    if (!isOpen) {
      return
    }
    onClickOverlay && onClickOverlay()
  }, [isOpen, onClickOverlay])

  return (
    <ModalPositionProvider top={props.top} bottom={props.bottom}>
      <CSSTransition
        nodeRef={domRef}
        className="wrapper"
        classNames="wrapper"
        in={isOpen}
        timeout={{
          appear: 500,
          enter: 300,
          exit: 300,
        }}
        appear
        unmountOnExit
        id={id}
        role="dialog"
        aria-modal="true"
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
      >
        <Wrapper ref={domRef}>
          <Background onClick={handleClickOverlay} />
          <FocusTrap>
            <Inner
              ref={innerRef}
              role="dialog"
              aria-modal="true"
              {...props}
              isSp={isSp}
            >
              <div ref={focusTarget} tabIndex={-1} aria-label={ariaLabel}></div>
              {children}
            </Inner>
          </FocusTrap>
          <ScrollSuppressing />
        </Wrapper>
      </CSSTransition>
    </ModalPositionProvider>
  )
}

const Wrapper = styled.div`
  z-index: 10000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  &.wrapper-appear {
    opacity: 0;
  }
  &.wrapper-appear-active {
    transition: opacity 500ms;
    opacity: 1;
  }
  &.wrapper-enter {
    opacity: 0;
  }
  &.wrapper-enter-active {
    transition: opacity 300ms;
    opacity: 1;
  }
  &.wrapper-exit {
    opacity: 1;
  }
  &.wrapper-exit-active {
    transition: opacity 300ms;
    opacity: 0;
  }
`

const Inner = styled.div<{ isSp: boolean }>`
  position: absolute;
  z-index: 10000;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  background-color: #fff;
  box-shadow: rgb(51 51 51 / 30%) 0px 4px 10px 0px;
  transform: translate(-50%, -50%);

  ${(props) =>
    props.isSp &&
    `
    width: calc(100vw - 32px);
  `}
`
const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`
const ScrollSuppressing = createGlobalStyle`
  body {
    overflow: hidden;
  }
`
