import React, { FC, ReactNode, useContext } from 'react'
import styled from 'styled-components'

import { TableGroupContext } from './Table'

export type Props = {
  colSpan?: number
  rowSpan?: number
  highlighted?: boolean
  nullable?: boolean
  children?: ReactNode
  className?: string
  onClick?: () => void
  dataLabel?: string
}

export const Cell: FC<Props> = ({
  className = '',
  children,
  onClick,
  colSpan,
  rowSpan,
  highlighted = false,
  nullable = false,
  dataLabel = '',
}) => {
  const { group } = useContext(TableGroupContext)
  const classNames = [
    className,
    highlighted && 'highlighted',
    nullable && 'nullable',
  ]
    .filter((c) => !!c)
    .join(' ')
  const props = {
    children,
    onClick,
    colSpan,
    rowSpan,
    className: classNames,
  }

  if (group === 'head') {
    return <Th {...props} />
  } else if (group === 'body') {
    return <Td {...props} data-label={dataLabel} />
  } else {
    return null
  }
}

const Th = styled.th`
  text-align: left;
  line-height: 1.5;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
`
const Td = styled.td`
  width: 232px;
  height: 96px;
  line-height: 1.5;
  vertical-align: baseline;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #fafafa;
`
