import './App.css'
import { Top } from './components/main/top'
import { OfficeTop } from './components/main/officeTop'
import { AdminTop } from './components/main/adminTop'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Login } from './components/main/login'
import * as UserApplications from './components/main/applications'
import * as OfficeApplications from './components/main/officeApplications'
import { PATH } from './const'
import { NoLoginLayout, UserLayout } from './components/layout'
import React from 'react'
import { MemberAuthProvider } from './auth/MemberAuthProvider'
import { OfficeAuthProvider } from './auth/OfficeAuthProvider'
import { AdminAuthProvider } from './auth/AdminAuthProvider'
import { ActivationRequest } from './components/main/activationRequest'
import { ResetPassword } from './components/main/resetPassword'
import * as UserAccount from './components/main/account'
import * as OfficeAccount from './components/main/officeAccount'
import OfficeLogin from './components/main/officeLogin/OfficeLogin'
import { Callback } from './components/main/callback'
import * as OfficeMembers from './components/main/officeMembers'
import AdminLogin from './components/main/adminLogin/AdminLogin'
import * as AdminApplications from './components/main/adminApplications'
import * as AdminMembers from './components/main/adminMembers'
import * as AdminOffices from './components/main/adminOffices'
import * as AdminAdminUsers from './components/main/adminAdminUsers'
import * as AdminSetting from './components/main/adminSetting'

// prettier-ignore
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>

        {/* 事業所ページ */}
        <Route path={PATH.OFFICE_TOP}>
          <OfficeAuthProvider>
            <RouteLayoutWrapper exact path={PATH.OFFICE_LOGIN} component={OfficeLogin} layout={NoLoginLayout} />
            <RouteLayoutWrapper exact path={PATH.OFFICE_TOP} component={OfficeTop} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.OFFICE_APPLICATIONS_NEW} component={OfficeApplications.New} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.OFFICE_APPLICATIONS_NEW}/:formType`} component={OfficeApplications.Create} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.OFFICE_APPLICATIONS} component={OfficeApplications.List} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.OFFICE_APPLICATIONS}/:applicationId`} component={OfficeApplications.Single} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.OFFICE_ACCOUNT} component={OfficeAccount.Account} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.OFFICE_ACCOUNT}/password/edit`} component={OfficeAccount.AccountEdit} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.OFFICE_MEMBERS} component={OfficeMembers.List} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.OFFICE_MEMBERS}/:memberId`} component={OfficeMembers.Single} layout={UserLayout} />
          </OfficeAuthProvider>
        </Route>

        {/* 管理ページ */}
        <Route path={PATH.ADMIN_TOP}>
          <AdminAuthProvider>
            <RouteLayoutWrapper exact path={PATH.ADMIN_LOGIN} component={AdminLogin} layout={NoLoginLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_TOP} component={AdminTop} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_APPLICATIONS_MEMBER} component={AdminApplications.ListMember} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_APPLICATIONS_OFFICE} component={AdminApplications.ListOffice} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ADMIN_APPLICATIONS_MEMBER}/:applicationId`} component={AdminApplications.Single} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ADMIN_APPLICATIONS_OFFICE}/:applicationId`} component={AdminApplications.Single} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_MEMBERS} component={AdminMembers.List} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ADMIN_MEMBERS_CREATE}`} component={AdminMembers.Create} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ADMIN_MEMBERS}/:memberId`} component={AdminMembers.Single} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_OFFICES} component={AdminOffices.List} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_OFFICES_CREATE} component={AdminOffices.Create} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ADMIN_OFFICES}/:officeId`} component={AdminOffices.Single} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_ADMIN_USERS} component={AdminAdminUsers.List} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ADMIN_ADMIN_USERS}/:adminUserId`} component={AdminAdminUsers.Single} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ADMIN_ADMIN_USERS}/:adminUserId/edit`} component={AdminAdminUsers.Edit} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_ADMIN_USERS_CREATE} component={AdminAdminUsers.Create} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ADMIN_SETTING} component={AdminSetting.Setting} layout={UserLayout} />
          </AdminAuthProvider>
        </Route>

        {/* 会員ページ @note <Switch>内の最後にしないと全部ここにはいってきちゃう */}
        <Route path={PATH.TOP}>
          <MemberAuthProvider>
            <RouteLayoutWrapper exact path={PATH.LOGIN} component={Login} layout={NoLoginLayout} />
            <RouteLayoutWrapper exact path={PATH.ACTIVATION_REQUEST} component={ActivationRequest} layout={NoLoginLayout} />
            <RouteLayoutWrapper exact path={PATH.RESET_PASSWORD} component={ResetPassword} layout={NoLoginLayout} />
            <RouteLayoutWrapper exact path={PATH.CALLBACK} component={Callback} layout={NoLoginLayout} />
            <RouteLayoutWrapper exact path={PATH.TOP} component={Top} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.APPLICATIONS_NEW} component={UserApplications.New} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.APPLICATIONS_NEW}/:formType`} component={UserApplications.Create} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.APPLICATIONS} component={UserApplications.List} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.APPLICATIONS}/:applicationId`} component={UserApplications.Single} layout={UserLayout} />
            <RouteLayoutWrapper exact path={PATH.ACCOUNT} component={UserAccount.Account} layout={UserLayout} />
            <RouteLayoutWrapper exact path={`${PATH.ACCOUNT}/:type/edit`} component={UserAccount.AccountEdit} layout={UserLayout} />
          </MemberAuthProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

const RouteLayoutWrapper: React.FC<any> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export default App
