import { OfficeLoginInfo } from '../../types/LoginInfo'

export default function toOfficeLoginInfo(json: any): OfficeLoginInfo {
  return {
    uid: json.uid,
    office: {
      officeId: json.office.officeId,
      name: json.office.name,
      nameKana: json.office.nameKana,
      postalCode: json.office.postalCode,
      address: json.office.address,
      tel: json.office.tel,
      clerkName: json.office.clerkName,
      clerkNameKana: json.office.clerkNameKana,
      representativeName: json.office.representativeName,
      representativeNameKana: json.office.representativeNameKana,
      firstLoggedInAt: json.office.firstLoggedInAt,
      paymentMethod: json.office.paymentMethod,
    },
  }
}
