import { useState } from 'react'

const FORM_STEP = ['input', 'confirm', 'complete']

type FormStep = 'input' | 'confirm' | 'complete'

export function useMultiStepState() {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const currentStep = FORM_STEP[currentIndex] as FormStep

  const prevStep = () => setCurrentIndex(currentIndex - 1)
  const nextStep = () => setCurrentIndex(currentIndex + 1)

  return { currentStep, prevStep, nextStep }
}
