import React from 'react'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import { InputField, Section } from '../../main/account/Account'
import { MemberInfo } from '../../../types/formData'
import { PATH } from '../../../const'
import { FORM_TYPE_IDS } from '../../../const/formTypeIds'
import { fetchAddress } from '../../../util/fetchAddressByPostalCode'
import {
  isMailAddress,
  isMemberId,
  isPhoneNumber,
  isPostalCode,
  isZenKatakana,
} from '../../../util/validation'

type Props =
  | {
      applicant: MemberInfo
      currentStep?: string
      disabled: boolean
      isAdmin: false
    }
  | {
      applicant: MemberInfo
      currentStep?: string
      disabled: boolean
      isAdmin: true
      isActive: false
      handleChangeMemberId: () => void
    }
  | {
      applicant: MemberInfo & { email: string }
      currentStep?: string
      disabled: boolean
      isAdmin: true
      isActive: true
      handleChangeMemberId: () => void
    }

export const MemberInfoSection: React.FC<Props> = (props) => {
  const { control, errors, setValue } = useFormContext()

  const handleChangePostalCode = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputPostalCode = e.target.value
    if (inputPostalCode.length === 8) {
      const address = await fetchAddress(inputPostalCode)
      setValue('address', address, { shouldDirty: true })
    }
  }

  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■会員情報
      </Heading>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員番号
        </Heading>
        <Controller
          control={control}
          name="id"
          rules={{
            required: '会員番号を入力してください',
            validate: { isMemberId },
          }}
          render={({ onChange, onBlur }) => (
            <Input
              type="text"
              defaultValue={props.applicant.id}
              onChange={(e) => {
                onChange(e)
                props.isAdmin && props.handleChangeMemberId()
              }}
              disabled={!props.isAdmin || props.currentStep !== 'input'}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.id && <ErrorMessage>{errors.id.message}</ErrorMessage>}
      {errors.id?.type === 'isMemberId' && (
        <ErrorMessage>半角数字+半角ハイフンで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所名
        </Heading>
        <Controller
          control={control}
          name="officeName"
          render={({ value }) => (
            <Input
              type="text"
              defaultValue={props.applicant.officeName}
              value={value}
              disabled
            />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所フリガナ
        </Heading>
        <Controller
          control={control}
          name="officeNameKana"
          render={({ value }) => (
            <Input
              type="text"
              defaultValue={props.applicant.officeNameKana}
              value={value}
              disabled
            />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員氏名
        </Heading>
        <Controller
          control={control}
          name="name"
          rules={{ required: '会員氏名を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.applicant.name}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
          <br />
          (全角カナ)
        </Heading>
        <Controller
          control={control}
          name="nameKana"
          rules={{
            required: 'フリガナを入力してください',
            validate: { isZenKatakana },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.applicant.nameKana}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.nameKana && (
        <ErrorMessage>{errors.nameKana.message}</ErrorMessage>
      )}
      {errors.nameKana?.type === 'isZenKatakana' && (
        <ErrorMessage>全角カナで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員郵便番号
          <br />
          (半角数字+半角ハイフン)
        </Heading>
        <Controller
          control={control}
          name="postalCode"
          rules={{
            required: '会員郵便番号を入力してください',
            validate: { isPostalCode },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={(e) => {
                onChange(e)
                handleChangePostalCode(e)
              }}
              defaultValue={props.applicant.postalCode}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.postalCode && (
        <ErrorMessage>{errors.postalCode.message}</ErrorMessage>
      )}
      {errors.postalCode?.type === 'isPostalCode' && (
        <ErrorMessage>半角数字+半角ハイフンで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員住所
        </Heading>
        <Controller
          control={control}
          name="address"
          rules={{ required: '会員住所を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.applicant.address}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.address && <ErrorMessage>{errors.address.message}</ErrorMessage>}
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員電話番号
          <br />
          (半角数字)
        </Heading>
        <Controller
          control={control}
          name="tel"
          rules={{
            required: '会員電話番号を入力してください',
            validate: { isPhoneNumber },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              defaultValue={props.applicant.tel}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.tel && <ErrorMessage>{errors.tel.message}</ErrorMessage>}
      {errors.tel?.type === 'isPhoneNumber' && (
        <ErrorMessage>半角数字10桁か11桁で入力してください</ErrorMessage>
      )}
      {props.isAdmin && props.isActive && (
        <>
          <InputField>
            <Heading type="blockTitle" tag="span">
              会員メールアドレス
            </Heading>
            <Controller
              control={control}
              name="email"
              rules={{
                required: '会員メールアドレスを入力してください',
                validate: { isMailAddress },
              }}
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="text"
                  value={value}
                  onChange={onChange}
                  defaultValue={props.applicant.email}
                  disabled={props.disabled}
                  onBlurCapture={onBlur}
                />
              )}
            />
          </InputField>
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
          {errors.email?.type === 'isMailAddress' && (
            <ErrorMessage>メールアドレスの形式で入力してください</ErrorMessage>
          )}
        </>
      )}
      {!props.isAdmin && !props.currentStep && (
        <Heading type="sectionTitle" tag="h2">
          ※会員情報を変更する場合は
          <Link
            href={`${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE}`}
          >
            こちら
          </Link>
        </Heading>
      )}
    </Section>
  )
}

const Link = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`
const ErrorMessage = styled.div`
  color: red;
`
