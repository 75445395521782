import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/functions'
import { Heading } from '../../ui/Heading'
import styled from 'styled-components'
import { Input } from '../../ui/Input'
import { Button } from '../../ui/Button'
import { PATH } from '../../../const'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { MessageModal } from '../../ui/Modal'
import { PartialLoader } from '../../ui/Loader'
import { mediaQuery } from '../../../themes/size'

const Callback: React.FC = () => {
  const history = useHistory()

  const location = useLocation()
  const query = queryString.parse(location.search)

  useEffect(() => {
    if (typeof query.oobCode !== 'string') {
      alert('無効な操作です')
      history.push(PATH.LOGIN)
    }
  })

  const code = query.oobCode as string

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isExpirationModalOpen, setIsExpirationModalOpen] = useState<boolean>(
    false,
  )
  const [error, setError] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        await firebase.auth().verifyPasswordResetCode(code)
      } catch (e) {
        setIsExpirationModalOpen(true)
      }
    })()
  }, [code])

  const handleSubmit = async (e: any) => {
    setButtonDisabled(true)
    e.preventDefault()
    const { password, passwordConfirm } = e.target.elements
    if (password.value !== passwordConfirm.value) {
      setError('確認用に入力したパスワードが一致しません')
      setButtonDisabled(false)
      return
    }
    if (password.value.length < 8) {
      setError('パスワードは8文字以上で設定してください')
      setButtonDisabled(false)
      return
    }
    try {
      await firebase.auth().verifyPasswordResetCode(code)
      await firebase.auth().confirmPasswordReset(code, password.value)
      setIsModalOpen(true)
    } catch (err) {
      if (err.code === 'auth/weak-password') {
        setError(
          'パスワードが単純過ぎます。複雑なパスワードで再設定をお願いします。',
        )
      } else {
        setError(
          'ページが無効です。もう一度パスワード再設定メールの送信からやり直してください。',
        )
      }
    }
    setButtonDisabled(false)
  }

  const redirectTo =
    typeof query.loginUrl === 'string' ? query.loginUrl : PATH.LOGIN

  // 正常系
  const handleModalClose = () => {
    setIsModalOpen(false)
    history.push(redirectTo)
  }

  const handleExpirationModalClose = () => {
    setIsExpirationModalOpen(false)
    history.push(redirectTo)
  }

  return (
    <Wrapper>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          パスワード再設定
        </Heading>
      </HeadingArea>
      <Description type="sectionTitle" tag="h2">
        新しいパスワードを設定してください。
      </Description>
      <Section>
        <form onSubmit={handleSubmit}>
          <InputField>
            <Label type="blockTitle" tag="span">
              新しいパスワード
            </Label>
            <Input
              type="password"
              name="password"
              placeholder="※8 文字以上で入力してください"
            />
          </InputField>
          <InputField>
            <Label type="blockTitle" tag="span">
              新しいパスワード（確認）
            </Label>
            <Input
              type="password"
              name="passwordConfirm"
              placeholder="※8 文字以上で入力してください"
            />
          </InputField>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ButtonWrapper>
            <Button type="submit" disabled={buttonDisabled}>
              {buttonDisabled ? <PartialLoader /> : '設定する'}
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonWrapperLink to={PATH.TOP}>
              <LinkButton>戻る</LinkButton>
            </ButtonWrapperLink>
          </ButtonWrapper>
        </form>
        <MessageModal
          isOpen={isModalOpen}
          title={'パスワード再設定が完了しました'}
          description={<p>ログイン画面から再度ログインをお願いします。</p>}
          closeText="ログインする"
          onClickClose={handleModalClose}
          onClickOverlay={handleModalClose}
          onPressEscape={handleModalClose}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isExpirationModalOpen}
          title={'パスワード設定リンクの有効期限が切れました'}
          description={
            <p>パスワード再設定画面から再度お手続きをお願いいたします</p>
          }
          closeText="パスワードを再設定する"
          onClickClose={handleExpirationModalClose}
          onClickOverlay={handleExpirationModalClose}
          onPressEscape={handleExpirationModalClose}
          id="dialog-message"
        />
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`
const HeadingArea = styled.div`
  margin-top: 32px;
`
const Description = styled(Heading)`
  margin-top: 32px;
  line-height: 24px;
`
const Section = styled.div`
  margin-top: 72px;
`
const InputField = styled.div`
  display: block;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const Label = styled(Heading)`
  display: inline-block;
  margin-right: 16px;
  width: 200px;
  text-align: left;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    margin-right: 0;
  }
`
const ButtonWrapper = styled.div`
  margin: 32px 0 16px 0;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const ErrorMessage = styled.div`
  color: red;
`
export default Callback
