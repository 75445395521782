import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { Heading } from '../../ui/Heading'
import { Section } from '../../main/applications/form/ShougaiMimaiKin'
import { Input } from '../../ui/Input'
import { RadioButtonLabel } from '../../ui/RadioButtonLabel'
import { Table, Body, Head, Row, Cell } from '../../ui/Table'
import { DatePicker } from '../../ui/DatePicker'
import { mediaQuery } from '../../../themes/size'
import { isSp } from 'libs/ua'
import { isYYYYMMDD, isZenKatakana } from '../../../util/validation'

type Props = {
  disabled: boolean
  isInput: boolean
  adminStep?: string
}

const initData = [
  {
    name: '',
    nameKana: '',
    birthDay: null,
    relationship: '',
    sex: 'man',
  },
]

export const FamilySection: React.FC<Props> = (props) => {
  const nameKey = props.isInput ? 'family' : 'previousFamily'

  const { control, errors, reset } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: nameKey,
  })

  useEffect(() => {
    if (fields.length === 0) {
      reset({
        family: initData,
      })
    }
    // eslint-disable-next-line
  }, [reset])

  useEffect(() => {
    if (props.adminStep === 'input') {
      reset({
        family: fields,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.adminStep])

  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        {props.isInput ? (
          <span>■申請情報（同居家族情報）</span>
        ) : (
          <span>■同居家族情報</span>
        )}
      </Heading>
      {!props.adminStep && props.isInput && (
        <Paragraph>
          ※変更点を修正して申請してください。
          <br />
          表示された内容を削除して申請すると、登録されている同居家族情報が削除されます。
        </Paragraph>
      )}
      <Wrapper>
        <Table>
          <TableHead>
            <TableHeadRow>
              <Cell>氏名</Cell>
              <Cell>フリガナ(全角カナ)</Cell>
              <Cell>生年月日</Cell>
              <Cell>続柄</Cell>
              <Cell></Cell>
            </TableHeadRow>
          </TableHead>
          <Body>
            {fields.map((field, index) => (
              <Row key={nameKey + field.id}>
                {isSp && index >= 1 && <Border />}
                <TdCell dataLabel={'氏名'}>
                  <Controller
                    control={control}
                    name={`${nameKey}[${index}].name`}
                    defaultValue={field.name}
                    rules={
                      props.disabled
                        ? {}
                        : { required: '氏名を入力してください' }
                    }
                    render={({ onChange, value, onBlur }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled={props.disabled}
                        onBlurCapture={onBlur}
                      />
                    )}
                  />
                  {errors?.[nameKey]?.[index]?.['name'] && (
                    <ErrorMessage>
                      {errors?.[nameKey]?.[index]?.['name']?.['message']}
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'フリガナ'}>
                  <Controller
                    control={control}
                    name={`${nameKey}[${index}].nameKana`}
                    defaultValue={field.nameKana}
                    rules={
                      props.disabled
                        ? {}
                        : {
                            required: 'フリガナを入力してください',
                            validate: { isZenKatakana },
                          }
                    }
                    render={({ onChange, value, onBlur }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled={props.disabled}
                        onBlurCapture={onBlur}
                      />
                    )}
                  />
                  {errors?.[nameKey]?.[index]?.['nameKana'] && (
                    <ErrorMessage>
                      {errors?.[nameKey]?.[index]?.['nameKana']?.['message']}
                    </ErrorMessage>
                  )}
                  {errors?.[nameKey]?.[index]?.['nameKana']?.type ===
                    'isZenKatakana' && (
                    <ErrorMessage>全角カナで入力してください</ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'生年月日'}>
                  <Controller
                    control={control}
                    name={`${nameKey}[${index}].birthDay`}
                    defaultValue={field.birthDay}
                    rules={
                      props.disabled
                        ? {}
                        : {
                            required: '生年月日を入力してください',
                            validate: { isYYYYMMDD },
                          }
                    }
                    render={({ onChange, value }) => (
                      <DatePickerWrapper>
                        <DatePicker
                          onChange={onChange}
                          value={value}
                          withSuffix={true}
                          disabled={props.disabled}
                          openToDate={true}
                        />
                      </DatePickerWrapper>
                    )}
                  />
                  {errors?.[nameKey]?.[index]?.['birthDay'] && (
                    <ErrorMessage>
                      {errors?.[nameKey]?.[index]?.['birthDay']?.['message']}
                    </ErrorMessage>
                  )}
                  {errors?.[nameKey]?.[index]?.['birthDay'] ===
                    'isYYYYMMDD' && (
                    <ErrorMessage>
                      YYYY/MM/DDの形式で入力して下さい
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'続柄'}>
                  <Controller
                    control={control}
                    name={`${nameKey}[${index}].relationship`}
                    defaultValue={field.relationship}
                    rules={
                      props.disabled
                        ? {}
                        : { required: '続柄を入力してください' }
                    }
                    render={({ onChange, value, onBlur }) => (
                      <TableInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        disabled={props.disabled}
                        onBlurCapture={onBlur}
                      />
                    )}
                  />
                  {errors?.[nameKey]?.[index]?.['relationship'] && (
                    <ErrorMessage>
                      {
                        errors?.[nameKey]?.[index]?.['relationship']?.[
                          'message'
                        ]
                      }
                    </ErrorMessage>
                  )}
                </TdCell>
                <TdCell dataLabel={'性別'}>
                  <CheckboxLabel>
                    <Controller
                      control={control}
                      name={`${nameKey}[${index}].sex`}
                      defaultValue={field.sex}
                      render={({ onChange, value }) => (
                        <RadioButtonWrapper>
                          <RadioButtonArea>
                            <RadioButtonLabel
                              label="男性"
                              value="man"
                              checked={value === 'man'}
                              onChange={onChange}
                              disabled={props.disabled}
                            />
                          </RadioButtonArea>
                          <RadioButtonArea>
                            <RadioButtonLabel
                              label="女性"
                              value="woman"
                              checked={value === 'woman'}
                              onChange={onChange}
                              disabled={props.disabled}
                            />
                          </RadioButtonArea>
                        </RadioButtonWrapper>
                      )}
                    />
                  </CheckboxLabel>
                </TdCell>

                {props.disabled ? (
                  isSp ? null : (
                    <TdCell />
                  )
                ) : (
                  <TdCell>
                    <DeleteButton type="button" onClick={() => remove(index)}>
                      削除
                    </DeleteButton>
                  </TdCell>
                )}
              </Row>
            ))}
          </Body>
        </Table>
        {!props.disabled && (
          <RowWrapper>
            <AddButtonWrapper>
              <AddButton
                type="button"
                onClick={() => {
                  append(initData)
                }}
                disabled={props.disabled}
              >
                <label htmlFor={'＋同居家族を追加する'}>
                  <Prefix></Prefix>
                  ＋同居家族を追加する
                </label>
              </AddButton>
            </AddButtonWrapper>
          </RowWrapper>
        )}
      </Wrapper>
    </Section>
  )
}

const Wrapper = styled.div`
  display: block;
  margin-top: 32px;
`
const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`
const TableInput = styled(Input)`
  > input[type='text'] {
    width: 232px;
  }

  > input[type='checkbox'] {
    width: 32px;
    cursor: pointer;
  }
`
const DatePickerWrapper = styled.div`
  input[type='text'] {
    width: 232px;

    @media screen and (max-width: ${mediaQuery.SP}px) {
      width: 100%;
    }
  }
`
const CheckboxLabel = styled.label`
  font-size: 16px;
  cursor: pointer;
`
const AddButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 450px;
  margin: 0 auto;
  text-align: center;
  > input[type='file'] {
    position: absolute;
    height: 100%;
    left: -10px;
    top: 0;
    margin: 0;
    font-size: 128px !important;
    opacity: 0;
    appearance: none;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  &:hover {
    > button {
      background-color: rgba(67, 154, 137, 0.5);
      color: rgb(255, 255, 255);
    }
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    &:hover {
      > button {
        background-color: #fff;
        color: #439a89;
      }
    }

    &:active {
      > button {
        background-color: rgba(67, 154, 137, 0.5);
        color: rgb(255, 255, 255);
      }
    }
  }
`
const Prefix = styled.span`
  display: inline-flex;
`
const DeleteButton = styled.button`
  margin-left: 8px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  padding: 0 16px;
  height: 45px;
  transition: all 0.3s ease-out 0s;
  &:hover {
    background-color: rgb(242, 242, 242);
  }
`
const AddButton = styled.button`
  font-family: inherit;
  font-weight: bold;
  border-radius: 27.5px;
  color: #439a89;
  background-color: #fff;
  border: solid 1px #439a89;
  font-size: 16px;
  height: 40px;
  padding: 0 46px;
  transition: all 0.3s ease-out 0s;
  > label {
    display: flex;
    align-items: center;
  }
  &.prefix {
    justify-content: left;
  }
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
  cursor: default;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
  }
`
const RadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 80px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 24px;
    :last-of-type {
      margin-top: 8px;
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
`
const TableHead = styled(Head)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: none;
  }
`
const TableHeadRow = styled(Row)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
const TdCell = styled(Cell)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    text-align: right;
    width: 100%;

    &:before {
      content: attr(data-label);
      float: left;
      margin-right: 10px;
    }
  }
`
const Border = styled.hr`
  margin-bottom: 32px;
`
const Paragraph = styled.p`
  margin-top: 24px;
  font-size: 16px;
`
