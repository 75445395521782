import React, { useContext, useEffect } from 'react'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { API, PATH } from '../../../const'
import useSWR from 'swr'
import { AdminLoginInfo } from '../../../types/AdminLoginInfo'
import { PageLoader } from '../../ui/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { Heading } from '../../ui/Heading'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from './form'
import styled from 'styled-components'
import { Button } from '../../ui/Button'
import { useStepModal } from './hooks'

type ParamType = {
  adminUserId: string
}

const Single: React.FC = () => {
  const history = useHistory()
  const { loginInfo, bearerToken } = useContext(AdminAuthContext)
  const { adminUserId } = useParams<ParamType>()

  const methods = useForm({
    mode: 'onBlur',
  })

  const apiPath = `${API.ADMIN_ADMIN_USERS}/${adminUserId}`

  // 削除処理とモーダル
  const requestDelete = async () => {
    return await fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      method: 'delete',
      mode: 'cors',
    })
  }
  const [openDeleteModal, renderDeleteModal] = useStepModal(requestDelete, {
    actionLabel: '削除',
    redirectToWhenComplete: PATH.ADMIN_ADMIN_USERS,
  })

  // パスワードリセット処理とモーダル
  const requestSendPasswordResetMail = async () => {
    return await fetch(`${apiPath}/sendPasswordResetMail`, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      method: 'post',
      mode: 'cors',
    })
  }
  const [openPassResetModal, renderPassResetModal] = useStepModal(
    requestSendPasswordResetMail,
    {
      actionLabel: 'パスワードリセットメール送信',
    },
  )

  const fetcher = () =>
    fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())
  const { data: adminUser } = useSWR<AdminLoginInfo['admin']>(apiPath, fetcher)

  const loading = typeof adminUser === 'undefined'
  useEffect(() => {
    if (!loading && adminUser) {
      methods.reset(adminUser)
    }
    // eslint-disable-next-line
  }, [loading, adminUser])

  if (typeof adminUser === 'undefined') {
    return <PageLoader />
  }

  const isMe = loginInfo.uid === adminUserId

  return (
    <>
      <FormProvider {...methods}>
        <Heading type="screenTitle" tag="h1">
          管理者情報
        </Heading>
        <Form readonly />
      </FormProvider>
      <ButtonWrapper>
        <Button
          onClick={() =>
            history.push(`${PATH.ADMIN_ADMIN_USERS}/${adminUserId}/edit`)
          }
        >
          変更
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <DeleteButton onClick={openDeleteModal}>削除</DeleteButton>
      </ButtonWrapper>
      <ButtonWrapper>
        {isMe ? (
          <PasswordResetButton onClick={() => history.push(PATH.ADMIN_SETTING)}>
            パスワードを変更する
          </PasswordResetButton>
        ) : (
          <PasswordResetButton onClick={openPassResetModal}>
            パスワードリセットメールを送る
          </PasswordResetButton>
        )}
      </ButtonWrapper>
      <ButtonWrapper>
        <BackButton onClick={() => history.push(PATH.ADMIN_ADMIN_USERS)}>
          一覧に戻る
        </BackButton>
      </ButtonWrapper>
      {renderDeleteModal()}
      {renderPassResetModal()}
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 800px;
  text-align: center;
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`
const DeleteButton = styled(BackButton)`
  border: none;
  background-color: #ea1c1c;
  color: #fff;
  font-weight: bold;
  &:hover {
    background-color: rgba(254, 57, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
const PasswordResetButton = styled(DeleteButton)`
  background-color: #fff;
  color: #000;
  font-weight: normal;
  border: 1px solid #ea1c1c;
`

export default Single
