import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

export type Props = {
  children: ReactNode
  type?: HeadingTypes
  tag?: HeadingTagTypes
  className?: string
}

export type HeadingTypes =
  | 'screenTitle'
  | 'sectionTitle'
  | 'blockTitle'
  | 'subBlockTitle'
  | 'subSubBlockTitle'

export type HeadingTagTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'

export const Heading: FC<Props> = ({
  tag = 'h1' as HeadingTagTypes,
  type = 'screenTitle',
  className = '',
  children,
}) => {
  return (
    <Wrapper as={tag} className={`${type} ${className}`}>
      {children}
    </Wrapper>
  )
}

// TODO: 共通にできるスタイルはテーマに持たせたい

const Wrapper = styled.h1`
  display: block;
  margin: 0;
  padding: 0;
  line-height: 1;
  &.screenTitle {
    color: #000;
    font-size: 24px;
    font-weight: normal;
  }
  &.sectionTitle {
    color: #000;
    font-size: 16px;
    font-weight: normal;
  }
  &.blockTitle {
    color: #000;
    font-size: 16px;
    font-weight: normal;
  }
`
