import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import { useHistory, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { MemberInfoSection } from '../../layout/inputParts/MemberInfoSection'
import { FamilySection } from '../../layout/inputParts/FamilySection'
import { Heading } from '../../ui/Heading'
import { Button } from '../../ui/Button'
import { PageLoader, PartialLoader } from '../../ui/Loader'
import { MessageModal } from '../../ui/Modal'
import { Input } from '../../ui/Input'
import { API, PATH } from '../../../const'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { MemberInfo } from '../../../types/formData'
import { useMultiStepStateAdmin } from '../../../hooks/useMultiStepStateAdmin'
import { User, Office } from 'types/LoginInfo'
import { Section } from '../applications/form/SeijinIwaiKin'
import { getUserStatus } from './List'
import { BrowserBackWarning } from '../../util'
import { InputField } from '../../main/applications/form/ShougaiMimaiKin'
import { isBefore, toYYYYMMDD } from '../../../presenter/dateUtil'
import { DatePicker } from '../../ui/DatePicker'
import { Select } from '../../ui/Select'
import { mediaQuery } from '../../../themes'

type ParamType = {
  memberId: string
}

const Component: React.FC = () => {
  const methods = useForm({
    mode: 'onBlur',
  })

  const history = useHistory<{ searchQuery: string }>()

  const { memberId } = useParams<ParamType>()
  const officeId = memberId.split('-')[0]
  const memberApiPath = `${API.ADMIN_MEMBERS}/${memberId}`
  const officeApiPath = `${API.ADMIN_OFFICES}/${officeId}`

  const handleConfirm = async (): Promise<boolean> => {
    document?.querySelector('main')?.scrollTo(0, 0)
    return await methods.trigger()
  }

  const formState = useMultiStepStateAdmin()
  const shouldFormDisabled =
    formState.currentStep === 'confirm' || formState.currentStep === 'detail'

  const { bearerToken } = useContext(AdminAuthContext)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isDeleteOK, setIsDeleteOK] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false)
  const [
    isActivateCompleteModalOpen,
    setIsActivateCompleteModalOpen,
  ] = useState(false)
  const [isActivateOK, setIsActivateOK] = useState(true)
  const [activateResultMsg, setActivateResultMsg] = useState('')
  const [isActivating, setIsActivating] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const [inputEmail, setInputEmail] = useState('')
  const handleChangeInputEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(e.target.value)
  }

  const [deactivateFormState, setDeactivateFormState] = useState(
    defaultDeactivateForm,
  )

  const { data: member, revalidate } = useSWR<User>(memberApiPath, () =>
    fetch(memberApiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json()),
  )

  const [isSendOk, setIsSendOk] = useState(true)
  const handleSend = async (): Promise<void> => {
    await methods.handleSubmit(async (data: any) => {
      const requestBody = {
        withdrawalReason: member?.withdrawalReason,
        deactivatedAt: member?.deactivatedAt,
        ...data,
        memberId: data.id,
      }
      const res = await fetch(memberApiPath, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        method: 'put',
        mode: 'cors',
        body: JSON.stringify(requestBody),
      })
      if (res.status >= 400) {
        setIsSendOk(false)
        setButtonDisabled(false)
        return
      }
      setIsSendOk(true)
      setButtonDisabled(false)
      methods.reset({ ...methods.watch() })
    })()
  }

  const { data: office } = useSWR<Office>(officeApiPath, () =>
    fetch(officeApiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json()),
  )

  const setInitialFormData = (): void => {
    if (member && office) {
      let formData: MemberInfo | (MemberInfo & { email: string }) = {
        id: member.memberId,
        officeName: office.name,
        officeNameKana: office.nameKana,
        name: member.name,
        nameKana: member.nameKana,
        postalCode: member.postalCode,
        address: member.address,
        tel: member.tel,
        email: member.email,
      }
      if (isActive(member)) {
        formData = {
          ...formData,
          email: member.email,
        }
      }
      methods.reset(formData)
      methods.reset({ ...methods.watch(), family: member?.family })
      setDeactivateFormState({
        withdrawalReason: member.withdrawalReason ?? '',
        deactivatedAt: member.deactivatedAt ?? '',
      })
    }
  }

  const loading = typeof member === 'undefined' || typeof office === 'undefined'
  useEffect(() => {
    if (!loading) {
      setInitialFormData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (typeof member === 'undefined' || typeof office === 'undefined') {
    return <PageLoader />
  }

  const isActive = (member: User) => getUserStatus(member) === 'active'

  const formType: 'activate' | 'detail' = isActive(member)
    ? 'detail'
    : 'activate'

  const memberInfo: MemberInfo & { email: string } = {
    id: member.memberId,
    officeName: office.name,
    officeNameKana: office.nameKana,
    name: member.name,
    nameKana: member.nameKana,
    postalCode: member.postalCode,
    address: member.address,
    tel: member.tel,
    email: member.email,
  }

  const handleModifyClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    formState.nextStep()
    document?.querySelector('main')?.scrollTo(0, 0)
    setButtonDisabled(false)
  }

  const handleConfirmClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    const isValid = await handleConfirm()
    if (isValid) {
      formState.nextStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
    setButtonDisabled(false)
  }

  const handleSendClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setIsSending(true)
    e.preventDefault()
    await handleSend()
    setIsSendModalOpen(true)
    setIsSending(false)
  }

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (formState.currentStep === 'detail') {
      const prevSearchQuery = history.location.state?.searchQuery
      const searchQuery =
        typeof prevSearchQuery !== 'undefined' ? prevSearchQuery : ''
      history.push(`${PATH.ADMIN_MEMBERS}${searchQuery}`)
    } else if (formState.currentStep === 'input') {
      formState.prevStep()
      document?.querySelector('main')?.scrollTo(0, 0)
      setInitialFormData()
      methods.reset({ ...methods.watch(), family: member?.family })
      methods.clearErrors()
    } else if (formState.currentStep === 'confirm') {
      formState.prevStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
  }

  const handleDelete = async (): Promise<void> => {
    setIsDeleting(true)
    const shouldDeleteImmediately = isBefore(
      new Date(deactivateFormState.deactivatedAt),
      new Date(),
    )
    const res = shouldDeleteImmediately
      ? await fetch(memberApiPath, {
          headers: { Authorization: 'Bearer ' + bearerToken },
          mode: 'cors',
          method: 'delete',
          body: JSON.stringify(deactivateFormState),
        })
      : await fetch(memberApiPath, {
          headers: { Authorization: 'Bearer ' + bearerToken },
          mode: 'cors',
          method: 'put',
          body: JSON.stringify({ ...member, ...deactivateFormState }),
        })
    if (res.status >= 400) {
      setIsDeleteOK(false)
    }
    setIsDeleteModalOpen(false)
    setIsDeleting(false)
    setIsCompleteModalOpen(true)
    revalidate()
  }

  const handleActivate = async (): Promise<void> => {
    setIsActivating(true)
    const requestBody = { email: inputEmail }
    const res = await fetch(memberApiPath + '/activation', {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'put',
      body: JSON.stringify(requestBody),
    })
    if (res.status >= 400) {
      setIsActivateOK(false)
      setActivateResultMsg(
        (await res.json()).message ===
          'The email address is already in use by another account.'
          ? 'メールアドレスが既に登録済みです。'
          : 'しばらく経ってから再度お試し下さい。',
      )
    } else {
      setIsActivateOK(true)
      setActivateResultMsg('')
    }
    setIsActivating(false)
    setIsActivateModalOpen(false)
    setIsActivateCompleteModalOpen(true)
    revalidate()
  }

  const handleChangeMemberId = (): void => {
    const inputMemberId = methods.watch('id')
    const pattern = /^\d{4}-?\d{3}$/g
    if (!inputMemberId.match(new RegExp(pattern))) {
      methods.setValue('officeName', '')
      methods.setValue('officeNameKana', '')
      return
    }
    const officeId = inputMemberId.split('-')[0]
    const officeSingleApiPath = `${API.ADMIN_OFFICES}/${officeId}`
    fetch(officeSingleApiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((office) => {
        methods.setValue('officeName', office.name)
        methods.setValue('officeNameKana', office.nameKana)
      })
      .catch(() => {
        methods.setValue('officeName', '')
        methods.setValue('officeNameKana', '')
      })
  }

  const handleSentModalClose = () => {
    setIsSendModalOpen(false)

    if (isSendOk) {
      const newId = methods.watch('id')
      history.push(`${PATH.ADMIN_MEMBERS}/${newId}`)

      formState.detailStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <BrowserBackWarning
          isDirty={!shouldFormDisabled && methods.formState.isDirty} // 閲覧状態の画面で裏側でデータが変わった時は警告を出さない
          message="変更が保存されていません。本当に戻りますか？"
        />
        <Heading type="screenTitle" tag="h1">
          会員詳細
        </Heading>

        {Object.keys(methods.errors)?.length > 0 && (
          <Section>
            <ErrorMessage>
              入力内容に不備があります。各入力項目のメッセージを参照してください。
            </ErrorMessage>
          </Section>
        )}

        <MemberInfoSection
          applicant={memberInfo}
          currentStep={formState.currentStep}
          disabled={shouldFormDisabled}
          isAdmin={true}
          isActive={isActive(member)}
          handleChangeMemberId={handleChangeMemberId}
        />

        <FamilySection
          disabled={shouldFormDisabled}
          isInput={true}
          adminStep={formState.currentStep}
        />
        {formState.currentStep === 'detail' && (
          <>
            <Section>
              <Heading type="sectionTitle" tag="h2">
                ■利用状況
              </Heading>
              <InputField>
                <Heading type="blockTitle" tag="span">
                  利用開始日
                </Heading>
                <Input
                  type="text"
                  value={
                    getUserStatus(member) === 'active' && member.activatedAt
                      ? toYYYYMMDD(member.activatedAt)
                      : ''
                  }
                  disabled={true}
                />
              </InputField>
            </Section>
            <WithdrawalSection
              member={member}
              setDeactivateFormState={setDeactivateFormState}
              formState={deactivateFormState}
            />
          </>
        )}
        <ButtonWrapper>
          {formState.currentStep === 'detail' && (
            <ModifyButton
              type="button"
              onClick={handleModifyClick}
              disabled={buttonDisabled}
            >
              変更
            </ModifyButton>
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          {formState.currentStep === 'input' && (
            <Button
              type="button"
              onClick={handleConfirmClick}
              disabled={buttonDisabled}
            >
              確認
            </Button>
          )}
          {formState.currentStep === 'confirm' && (
            <Button
              type="button"
              onClick={handleSendClick}
              disabled={buttonDisabled || isSending}
            >
              {isSending ? <PartialLoader /> : '登録'}
            </Button>
          )}
          {formType === 'activate' && formState.currentStep === 'detail' && (
            <Button type="button" onClick={() => setIsActivateModalOpen(true)}>
              アクティベート
            </Button>
          )}
        </ButtonWrapper>
        {getUserStatus(member) !== 'inactive' &&
          formState.currentStep === 'detail' && (
            <ButtonWrapper>
              <DeleteButton
                type="button"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                退会
              </DeleteButton>
            </ButtonWrapper>
          )}
        <ButtonWrapper>
          <BackButton type="button" onClick={handleBackClick}>
            戻る
          </BackButton>
        </ButtonWrapper>
        <MessageModal
          isOpen={isDeleteModalOpen}
          title={'本当に退会しますか？'}
          description={
            <>
              {deactivateFormState.withdrawalReason === '' && (
                <p style={{ color: 'red' }}>退会理由を選択してください</p>
              )}
              {deactivateFormState.deactivatedAt === '' && (
                <p style={{ color: 'red' }}>退会日を選択してください</p>
              )}
            </>
          }
          isCloseButtonDisabled={
            isDeleting ||
            deactivateFormState.withdrawalReason === '' ||
            deactivateFormState.deactivatedAt === ''
          }
          closeText={
            isDeleting ? (
              <PartialLoader />
            ) : deactivateFormState.withdrawalReason === '' ||
              deactivateFormState.deactivatedAt === '' ? (
              'エラーを解消してください'
            ) : (
              'OK'
            )
          }
          onClickClose={handleDelete}
          onClickOverlay={() => setIsDeleteModalOpen(false)}
          onPressEscape={() => setIsDeleteModalOpen(false)}
          onClickCancelButton={() => setIsDeleteModalOpen(false)}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isCompleteModalOpen}
          title={isDeleteOK ? '処理が完了しました' : '処理に失敗しました'}
          description={
            isDeleteOK ? undefined : (
              <p>しばらく経ってから再度お試しください。</p>
            )
          }
          closeText="OK"
          onClickClose={() => setIsCompleteModalOpen(false)}
          onClickOverlay={() => setIsCompleteModalOpen(false)}
          onPressEscape={() => setIsCompleteModalOpen(false)}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isSendModalOpen}
          title={isSendOk ? '登録情報を変更しました' : '変更に失敗しました'}
          description={
            isSendOk
              ? undefined
              : 'メールアドレスが既に使われていないか確認して下さい'
          }
          closeText={isSendOk ? 'OK' : '閉じる'}
          onClickClose={handleSentModalClose}
          onClickOverlay={handleSentModalClose}
          onPressEscape={handleSentModalClose}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isActivateModalOpen}
          title={'アクティベート'}
          description={
            <>
              <ActivateModalMsg>
                メールアドレスを入力してください
              </ActivateModalMsg>
              <Input
                type="email"
                name="inputEmail"
                onChange={(e) => handleChangeInputEmail(e)}
              />
            </>
          }
          closeText={isActivating ? <PartialLoader /> : 'OK'}
          onClickClose={handleActivate}
          isCloseButtonDisabled={isActivating}
          onClickOverlay={() => setIsActivateModalOpen(false)}
          onPressEscape={() => setIsActivateModalOpen(false)}
          onClickCancelButton={() => setIsActivateModalOpen(false)}
          id="dialog-message"
        />
        <MessageModal
          isOpen={isActivateCompleteModalOpen}
          title={
            isActivateOK
              ? 'アクティベートしました'
              : 'アクティベートに失敗しました'
          }
          description={activateResultMsg}
          closeText="OK"
          onClickClose={() => setIsActivateCompleteModalOpen(false)}
          onClickOverlay={() => setIsActivateCompleteModalOpen(false)}
          onPressEscape={() => setIsActivateCompleteModalOpen(false)}
          id="dialog-message"
        />
      </FormProvider>
    </>
  )
}

type WithdrawalSectionProps = {
  member: User
  formState: typeof defaultDeactivateForm
  setDeactivateFormState: (data: typeof defaultDeactivateForm) => void
}
const WithdrawalSection: React.FC<WithdrawalSectionProps> = (props) => {
  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■退会申請
      </Heading>
      <InputField>
        <Heading type="blockTitle" tag="span">
          退会日
        </Heading>
        <DatePicker
          onChange={(data) =>
            props.setDeactivateFormState({
              ...props.formState,
              deactivatedAt: toYYYYMMDD(data ? data.toString() : ''),
            })
          }
          value={new Date(props.formState.deactivatedAt)}
          withSuffix={true}
          disabled={getUserStatus(props.member) === 'inactive'}
          openToDate={true}
          maxDate={new Date('2040/01/01')} // 未来を指定できるようにするためDatePicker側の仕様として設定している上限を便宜的に指定する
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          退会理由
        </Heading>
        <TableSelect
          value={props.formState.withdrawalReason}
          options={[
            { label: '', value: '' },
            { label: '退職', value: '退職' },
            { label: '死亡', value: '死亡' },
            { label: '人事異動', value: '人事異動' },
            { label: 'その他', value: 'その他' },
          ]}
          onChange={(e) => {
            props.setDeactivateFormState({
              ...props.formState,
              withdrawalReason: e.target.value,
            })
          }}
          disabled={getUserStatus(props.member) === 'inactive'}
        />
      </InputField>
    </Section>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const ModifyButton = styled(Button)`
  background-color: #fe6f32;
  &:hover {
    background-color: rgba(254, 111, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
const DeleteButton = styled(Button)`
  background-color: #ea1c1c;
  &:hover {
    background-color: rgba(254, 57, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`
const ActivateModalMsg = styled.p`
  text-align: center;
`
const ErrorMessage = styled.div`
  color: red;
`
const TableSelect = styled(Select)`
  height: 57px;
  flex-grow: 0.6;
  border: none;
  background: rgba(33, 33, 33, 0.08);
  padding: 8px;
  font-size: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`

const defaultDeactivateForm = {
  deactivatedAt: '',
  withdrawalReason: '',
}

export default Component
