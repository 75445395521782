import React, { useContext } from 'react'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import { API, PATH } from '../../../const'
import useSWR from 'swr'
import OfficeMembers from '../../../types/OfficeMembers'
import { PageLoader } from '../../ui/Loader'
import { useParams } from 'react-router-dom'
import { InputField, Section } from '../account/Account'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import styled from 'styled-components'
import { FORM_TYPE_IDS } from '../../../const/formTypeIds'
import { Button } from '../../ui/Button'
import { Link } from 'react-router-dom'
import { toYYYYMMDD } from '../../../presenter/dateUtil'

type ParamType = {
  memberId: string
}

const Single: React.FC = () => {
  const { loginInfo, bearerToken } = useContext(OfficeAuthContext)
  const { memberId } = useParams<ParamType>()
  const apiPath = API.OFFICE_MEMBERS.replace(
    ':officeId',
    loginInfo.office.officeId,
  ).concat(`/${memberId}`)
  const fetcher = () =>
    fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())
  const { data: officeMember } = useSWR<OfficeMembers[number]>(apiPath, fetcher)
  if (typeof officeMember === 'undefined') {
    return <PageLoader />
  }
  const deactivatedAt = getDeactivatedAt(officeMember)
  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■会員情報
      </Heading>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会員番号
        </Heading>
        <Input type="text" defaultValue={officeMember.memberId} disabled />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          氏名
        </Heading>
        <Input type="text" defaultValue={officeMember.name} disabled />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          利用開始日
        </Heading>
        <Input
          type="text"
          defaultValue={
            officeMember.activatedAt ? toYYYYMMDD(officeMember.activatedAt) : ''
          }
          disabled
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          退会日
        </Heading>
        <Input
          type="text"
          defaultValue={deactivatedAt ? toYYYYMMDD(deactivatedAt) : ''}
          disabled
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          ステータス
        </Heading>
        <Input
          type="text"
          defaultValue={
            officeMember.deactivatedAt?.length &&
            !officeMember.firstLoggedInAt?.length
              ? '退会済み'
              : '会員'
          }
          disabled
        />
      </InputField>
      <Heading type="sectionTitle" tag="h2">
        ※会員の退会申請は
        <TextLink
          href={`${PATH.OFFICE_APPLICATIONS_NEW}/${FORM_TYPE_IDS.OFFICE_ICHIBUTAIKAITODOKE}`}
        >
          こちら
        </TextLink>
      </Heading>
      <ButtonWrapper>
        <ButtonWrapperLink to={PATH.OFFICE_MEMBERS}>
          <LinkButton>戻る</LinkButton>
        </ButtonWrapperLink>
      </ButtonWrapper>
    </Section>
  )
}

const getDeactivatedAt = (member: OfficeMembers[number]) => {
  if (member.firstLoggedInAt || member.deactivatedAt === '') {
    return ''
  } else {
    return member.activatedAt > member.deactivatedAt ? '' : member.deactivatedAt
  }
}

const TextLink = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`
const ButtonWrapper = styled.div`
  margin: 32px 0 16px 0;
  text-align: center;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`

export default Single
