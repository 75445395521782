import React, {
  ChangeEvent,
  FC,
  SelectHTMLAttributes,
  useCallback,
} from 'react'
import styled from 'styled-components'
import ExpandMore from '@material-ui/icons/ExpandMore'

type Option = {
  value: string
} & Omit<React.OptionHTMLAttributes<HTMLOptionElement>, 'value'>
type Optgroup = {
  label: string
  options: Option[]
} & React.OptgroupHTMLAttributes<HTMLOptGroupElement>

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Array<Option | Optgroup>
  error?: boolean
  width?: number | string
  hasBlank?: boolean
  blankLabel?: string
}

export const Select: FC<Props> = ({
  options,
  onChange,
  error = false,
  width = 260,
  hasBlank = false,
  blankLabel = '選択してください',
  className = '',
  ...props
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (onChange) onChange(e)
    },
    [onChange],
  )

  return (
    <Wrapper className={className}>
      <Label>
        <SelectBox onChange={handleChange} {...props}>
          {hasBlank && <option value="">{blankLabel}</option>}
          {options.map((option) => {
            if ('value' in option) {
              return (
                <option key={option.value} {...option}>
                  {option.label}
                </option>
              )
            }

            const { options: groupedOptions, ...optgroup } = option

            return (
              <optgroup key={optgroup.label} {...optgroup}>
                {groupedOptions.map((groupedOption) => (
                  <option key={groupedOption.value} {...groupedOption}>
                    {groupedOption.label}
                  </option>
                ))}
              </optgroup>
            )
          })}
        </SelectBox>
        <SelectIcon />
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 160px;
  border-radius: 6px;
  border: 1px solid rgb(214, 211, 208);
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.3s ease-out 0s;
`
const SelectBox = styled.select`
  display: inline-block;
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  border-radius: 6px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #000;
  line-height: 1.6;
  outline: none;
  appearance: none;
  cursor: pointer;

  :disabled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(190, 190, 190);
    cursor: default;
  }
`
const SelectIcon = styled(ExpandMore)`
  position: absolute;
  top: 16px;
  right: 5px;
  cursor: pointer;
  pointer-events: none;

  :disabled {
    cursor: default;
  }
`
const Label = styled.label``
