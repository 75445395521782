import AppBar from '@material-ui/core/AppBar'
import clsx from 'clsx'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Link, useLocation } from 'react-router-dom'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { drawerWidth, LoginType } from '../UserLayout'
import React from 'react'
import { Button } from '../../ui/Button'
import Typography from '@material-ui/core/Typography'
import { PATH } from '../../../const'
import { isPc, isSp, isTablet } from '../../../libs/ua'

type Props = {
  handleDrawerOpen: () => void
  isDrawerOpen: boolean
  handleLogout?: (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<JSX.Element>
  loginType?: LoginType
}

const Header: React.FC<Props> = (props) => {
  const classes = useStyles()

  const currentPath = useLocation().pathname
  let logoLinkUrl = PATH.TOP
  if (currentPath.startsWith(PATH.OFFICE_TOP)) {
    logoLinkUrl = PATH.OFFICE_TOP
  } else if (currentPath.startsWith(PATH.ADMIN_TOP)) {
    logoLinkUrl = PATH.ADMIN_TOP
  }

  const isLoggedIn = typeof props.loginType !== 'undefined'
  const loginTypeLabel = (() => {
    let label = ''
    if (props.loginType === 'member') {
      label = '会員用ページ'
    } else if (props.loginType === 'office') {
      label = '事業所用ページ'
    } else if (props.loginType === 'admin') {
      label = '管理者用ページ'
    }
    return label
  })()
  return (
    <AppBar
      position="absolute"
      className={clsx(
        classes.appBar,
        props.isDrawerOpen && classes.appBarShift,
      )}
    >
      <Toolbar className={classes.toolbar}>
        {isLoggedIn && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              props.isDrawerOpen && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
        )}
        <div>
          <Link to={logoLinkUrl}>
            <img
              src="https://bunkin.jp/bunkin-wp/wp-content/themes/bunkin20200628/img/logo_width.png"
              alt="文京区勤労者共済会"
              className={classes.headerImg}
            />
          </Link>
          {isSp && (
            <div className={classes.wrapperTypo}>
              <Typography className={classes.headerTypo}>
                申請システム
              </Typography>
              <div className={classes.wrapperRight}>
                {isLoggedIn && <Typography>{loginTypeLabel}</Typography>}
              </div>
            </div>
          )}
        </div>

        {(isPc || isTablet) && (
          <>
            <Typography className={classes.headerTypo}>申請システム</Typography>
            <div className={classes.wrapperRight}>
              {isLoggedIn && <Typography>{loginTypeLabel}</Typography>}
              {props.handleLogout && (
                <div className={classes.buttonWrapper}>
                  <Button type="button" onClick={props.handleLogout}>
                    ログアウト
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      // MEMO: transitionでstrictmodeのエラーが出るが、MaterialUIの既存バグで改善されていない
      // 本番では起こらないエラーなので無視するが解決できる時にしたい
      // https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '100%',
    },
    appBarShift: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    wrapperTypo: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    headerTypo: {
      marginLeft: 16,
      [theme.breakpoints.down('md')]: {
        width: 180,
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '50%',
      },
    },
    buttonWrapper: {},
    wrapperRight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 320,
      margin: '0 0 0 auto',
      '& > p': {
        minWidth: 72,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        margin: 0,
        width: '50%',
      },
    },
    headerImg: {
      [theme.breakpoints.down('sm')]: {
        width: 240,
      },
    },
  }),
)

export default Header
