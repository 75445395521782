// API のパス

const apiHost = process.env.REACT_APP_API_HOST
const apiBasePath = `${apiHost}/api`

// user
export const APPLICATIONS = `${apiBasePath}/applications`
export const ABOUT_ME = `${apiBasePath}/about_me`
export const ACTIVATIONS = `${apiBasePath}/activations`
export const ACCOUNT = `${apiBasePath}/account`
export const SEND_PASSWORD_RESET_MAIL = `${apiBasePath}/sendPasswordResetMail`
export const OFFICE_MEMBERS = `${apiBasePath}/office/:officeId/members`

//admin
const adminApiBasePath = `${apiBasePath}/admin`
export const ADMIN_ABOUT_ME = `${adminApiBasePath}/about_me`
export const ADMIN_APPLICATIONS_MEMBER = `${adminApiBasePath}/applications-member`
export const ADMIN_APPLICATIONS_OFFICE = `${adminApiBasePath}/applications-office`
export const ADMIN_OFFICES = `${adminApiBasePath}/offices`
export const ADMIN_MEMBERS = `${adminApiBasePath}/members`
export const ADMIN_ADMIN_USERS = `${adminApiBasePath}/adminUsers`
