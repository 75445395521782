import React from 'react'
import { Heading } from '../../../../ui/Heading'
import { Input } from '../../../../ui/Input'
import { InputField, Section } from '../../../applications/form/ShougaiMimaiKin'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import {
  isMailAddress,
  isPhoneNumber,
  isZenKatakana,
} from '../../../../../util/validation'

type Props = {
  disabled: boolean
}

export const ApplicantSection: React.FC<Props> = (props) => {
  const { control, errors } = useFormContext()
  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■申請者情報
      </Heading>
      <Paragraph>
        本申請のご担当者情報をご入力ください。
        <br />
        申請内容についてのご確認のためご連絡する場合がございます。
      </Paragraph>
      <InputField>
        <Heading type="blockTitle" tag="span">
          担当者名
        </Heading>
        <Controller
          control={control}
          name="applicant.name"
          rules={{ required: '担当者名を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.applicant?.name && (
        <ErrorMessage>{errors.applicant.name.message}</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
          <br />
          (全角カナ)
        </Heading>
        <Controller
          control={control}
          name="applicant.nameKana"
          rules={{
            required: 'フリガナを入力してください',
            validate: { isZenKatakana },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.applicant?.nameKana && (
        <ErrorMessage>{errors.applicant.nameKana.message}</ErrorMessage>
      )}
      {errors.applicant?.nameKana?.type === 'isZenKatakana' && (
        <ErrorMessage>全角カナで入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          所属・部署名
        </Heading>
        <Controller
          control={control}
          name="applicant.department"
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.applicant?.department && (
        <ErrorMessage>{errors.applicant.department.message}</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          担当者連絡先
          <br />
          (半角数字)
        </Heading>
        <Controller
          control={control}
          name="applicant.tel"
          rules={{
            required: '担当者連絡先を入力してください',
            validate: { isPhoneNumber },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.applicant?.tel && (
        <ErrorMessage>{errors.applicant.tel.message}</ErrorMessage>
      )}
      {errors.applicant?.tel?.type === 'isPhoneNumber' && (
        <ErrorMessage>半角数字10桁か11桁で入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          メールアドレス
          <br />
          (半角英数字+記号)
        </Heading>
        <Controller
          control={control}
          name="applicant.email"
          rules={{
            required: 'メールアドレスを入力してください',
            validate: { isMailAddress },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.applicant?.email && (
        <ErrorMessage>{errors.applicant.email.message}</ErrorMessage>
      )}
      {errors.applicant?.email?.type === 'isMailAddress' && (
        <ErrorMessage>メールアドレスの形式で入力してください</ErrorMessage>
      )}
    </Section>
  )
}

const Paragraph = styled.p`
  margin-top: 24px;
  font-size: 16px;
`
const ErrorMessage = styled.div`
  color: red;
`
