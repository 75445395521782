import { MemberLoginInfo } from '../../types/LoginInfo'

export default function toMemberLoginInfo(json: any): MemberLoginInfo {
  return {
    uid: json.uid,
    user: {
      memberId: json.user.memberId,
      name: json.user.name,
      nameKana: json.user.nameKana,
      email: json.user.email,
      tel: json.user.tel,
      postalCode: json.user.postalCode,
      address: json.user.address,
      activatedAt: json.user.activatedAt,
      deactivatedAt: json.user.deactivatedAt,
      firstLoggedInAt: json.user.firstLoggedInAt,
      family: json.user.family.map((item: any) => ({
        name: item.name,
        nameKana: item.nameKana,
        birthDay: new Date(item.birthDay),
        relationship: item.relationship,
        sex: item.sex,
      })),
    },
    office: {
      officeId: json.office.officeId,
      name: json.office.name,
      nameKana: json.office.nameKana,
      address: json.office.address,
    },
  }
}
