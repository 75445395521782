import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { API, PATH } from '../../../const'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import { Button } from '../../ui/Button'
import { MessageModal } from '../../ui/Modal'
import { PartialLoader } from '../../ui/Loader'
import { isMailAddress } from '../../../util/validation'

const ResetPassword: React.FC = () => {
  const [error, setError] = useState('')

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (e: any) => {
    setButtonDisabled(true)
    e.preventDefault()
    const { email } = e.target.elements

    if (!isMailAddress(email.value)) {
      setError('メールアドレスの形式で入力してください')
      setButtonDisabled(false)
      return
    }

    try {
      await fetch(API.SEND_PASSWORD_RESET_MAIL, {
        method: 'post',
        body: JSON.stringify({ email: email.value }),
      })
    } catch (e) {
      setError('メール送信に失敗しました。')
      setButtonDisabled(false)
      return
    }
    modalOpen()
    setButtonDisabled(false)
  }

  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const modalOpen = () => setIsOpen(true)
  const onClickClose = () => {
    setIsOpen(false)
    history.push(PATH.TOP)
  }

  return (
    <>
      <Wrapper>
        <Heading type="screenTitle" tag="h1">
          ▼パスワードリセット
        </Heading>
        <Description type="sectionTitle" tag="h2">
          登録いただいたメールアドレスを入力してください。
          <br />
          メールが届かなかった場合は、異なるメールアドレスでの登録がないかご確認ください。{' '}
          <br />
          その他、不明点がございましたら、事務局までご連絡ください。
        </Description>
        <Section>
          <form onSubmit={handleSubmit}>
            <InputField>
              <Input type="email" name="email" placeholder="メールアドレス" />
            </InputField>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <ButtonWrapper>
              <Button type="submit" disabled={buttonDisabled}>
                {buttonDisabled ? <PartialLoader /> : '送信'}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonWrapperLink to={PATH.TOP}>
                <LinkButton>戻る</LinkButton>
              </ButtonWrapperLink>
            </ButtonWrapper>
          </form>
        </Section>
      </Wrapper>
      <MessageModal
        isOpen={isOpen}
        title="リセット用メールを送信しました"
        description={
          <p>
            ご入力されたメールアドレスにリセット用リンクを送信しました。記載された内容に従って、パスワードの再設定を行ってください。メールが届かなかった場合は、異なるメールアドレスでのご登録がないかご確認ください。
          </p>
        }
        closeText="OK"
        onClickClose={onClickClose}
        onClickOverlay={onClickClose}
        onPressEscape={onClickClose}
        id="dialog-message"
      />
    </>
  )
}

const Wrapper = styled.div`
  margin-top: 64px;
  text-align: center;
`
const Description = styled(Heading)`
  margin-top: 32px;
  line-height: 24px;
`
const Section = styled.div`
  margin-top: 72px;
`
const InputField = styled.div`
  display: block;
  margin-top: 16px;
`
const ButtonWrapper = styled.div`
  margin: 32px 0 16px 0;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const ErrorMessage = styled.div`
  color: red;
`

export default ResetPassword
