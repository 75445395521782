import React, { ChangeEvent, FC, InputHTMLAttributes, useCallback } from 'react'
import styled from 'styled-components'

export type Props = InputHTMLAttributes<HTMLInputElement>

export const RadioButton: FC<Props> = ({
  className = '',
  onChange,
  ...props
}) => {
  const { checked, disabled } = props
  const boxClassName = `${checked ? 'active' : ''} ${
    disabled ? 'disabled' : ''
  }`
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e)
    },
    [onChange],
  )

  return (
    <Wrapper className={className}>
      <Input type="radio" onChange={handleChange} {...props} />
      <Box className={boxClassName} />
    </Wrapper>
  )
}

// TODO: 共通にできるスタイルはテーマに持たせたい

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  width: 1rem;
  height: 1rem;
`
const Box = styled.span`
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 1px #000;
  background-color: #fff;
  box-sizing: border-box;
  &.active {
    border-color: #000;
    background-color: #000;
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 50%;
      background-color: #fff;
      transform: translate(-50%, -50%);
      content: '';
      pointer-events: none;
    }
  }
  &.disabled {
    background-color: rgb(214, 211, 208);
    border-color: rgb(214, 211, 208);
    &.active {
      border-color: #000;
      &::before {
        background-color: #fff;
      }
    }
  }
`
const Input = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
  &:focus + span {
    box-shadow: rgb(0 119 199 / 50%) 0px 0px 0px 2px;
  }
`
