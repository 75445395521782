import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import { Header } from './header'
import React, { useContext, useState } from 'react'
import { Sidebar } from './sidebar'
import theme from './const/baseTheme'
import { MemberAuthContext } from '../../auth/MemberAuthProvider'
import { OfficeAuthContext } from '../../auth/OfficeAuthProvider'
import { AdminAuthContext } from '../../auth/AdminAuthProvider'
import { Redirect } from 'react-router-dom'
import { PATH } from './../../const'
import { isPc } from '../../libs/ua'

// @todo Headerと共通のためexport
export const drawerWidth = 240

export type LoginType = 'member' | 'office' | 'admin'

const UserLayout: React.FC = (props) => {
  const classes = useStyles()
  const [isDrawerOpen, setOpen] = useState(isPc)
  const handleDrawerOpen = (): void => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const { logout: memberLogout } = useContext(MemberAuthContext)
  const { loginInfo: officeLoginInfo, logout: officeLogout } = useContext(
    OfficeAuthContext,
  )
  const { loginInfo: adminLoginInfo, logout: adminLogout } = useContext(
    AdminAuthContext,
  )

  const loginType: LoginType = (() => {
    if (officeLoginInfo.uid.length > 0) {
      return 'office'
    } else if (adminLoginInfo.uid.length > 0) {
      return 'admin'
    }
    return 'member'
  })()
  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    let redirectTo = ''
    if (loginType === 'member') {
      await memberLogout()
      redirectTo = PATH.LOGIN
    } else if (loginType === 'office') {
      await officeLogout()
      redirectTo = PATH.OFFICE_LOGIN
    } else if (loginType === 'admin') {
      await adminLogout()
      redirectTo = PATH.ADMIN_LOGIN
    }
    return <Redirect to={redirectTo} />
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header
          handleDrawerOpen={handleDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          handleLogout={handleLogout}
          loginType={loginType}
        />
        <Sidebar
          handleDrawerClose={handleDrawerClose}
          isDrawerOpen={isDrawerOpen}
          handleLogout={handleLogout}
          loginType={loginType}
        />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <>{props.children}</>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
  }),
)

export default UserLayout
