import React from 'react'
import ReactLoading from 'react-loading'

type Props = {
  height?: number
  width?: number
}

const PageLoader: React.FC<Props> = (props) => {
  return (
    <ReactLoading
      type="spin"
      color="#333"
      height={props.height ?? 20}
      width={props.width ?? 20}
    />
  )
}

export default PageLoader
