import React, { ChangeEvent, useContext, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Application,
  Applications as ApplicationsType,
} from '../../../types/application'
import { API, PATH } from '../../../const'
import { Heading } from '../../ui/Heading'
import { Body, Cell, Head, Row, Table } from '../../ui/Table'
import { Select } from '../../ui/Select'
import { MemberAuthContext } from '../../../auth/MemberAuthProvider'
import { toYYYYMMDDHHmm } from '../../../presenter/dateUtil'
import { getCategoryAndForm } from '../../../presenter/categorisedForms'
import { getUserApprovalStatusLabel } from '../../../presenter/getApprovalStatusLabel'
import { PageLoader } from '../../ui/Loader'
import { mediaQuery } from '../../../themes/size'
import { isSp } from 'libs/ua'

const List: React.FC = () => {
  const { loginInfo, bearerToken } = useContext(MemberAuthContext)
  const history = useHistory()

  const location = useLocation()
  let param = ''

  if (location.search) {
    const qs = queryString.parseUrl(location.search).query
    param = qs.filterStatus as string
  }

  const [filterStatus, setFilterStatus] = useState(param)

  const handleChangeFilterStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterStatus(e.currentTarget.value)
  }

  const fetcher = () =>
    fetch(API.APPLICATIONS, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())

  const { data: applications } = useSWR<ApplicationsType>(
    API.APPLICATIONS,
    fetcher,
  )
  if (typeof applications === 'undefined') {
    return <PageLoader />
  }

  const filterApplication = () => {
    if (filterStatus === 'all' || filterStatus === '') {
      return applications
    }

    return applications.filter(
      (application) => application.approvalStatus === filterStatus,
    )
  }

  // @todo move to presenter and write test code
  const orderByCreatedAtDesc = (a: Application, b: Application): number =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ {loginInfo.user.name}様 (会員番号{loginInfo.user.memberId})
      </Heading>
      <BlankSpace></BlankSpace>
      <Heading type="sectionTitle" tag="h2">
        過去にご申請いただいた内容は、下記の通りです。
      </Heading>
      <SelectWrapper>
        <SearchSelect
          name="filterStatus"
          value={filterStatus}
          options={[
            { label: 'すべて', value: 'all' },
            { label: '申請中', value: 'applying' },
            { label: '審査中', value: 'inspecting' },
            { label: '取消', value: 'rejected' },
            { label: '受理', value: 'accepted' },
          ]}
          onChange={(e) => handleChangeFilterStatus(e)}
        />
      </SelectWrapper>
      <Wrapper>
        <Table>
          <TableHead>
            <TableHeadRow>
              <ThCell>申請日時</ThCell>
              <ThCell>申請ID</ThCell>
              <ThCell>申請種別</ThCell>
              <ThCell>ステータス</ThCell>
            </TableHeadRow>
          </TableHead>
          <Body>
            {filterApplication()
              .sort(orderByCreatedAtDesc)
              .map((application, index) => (
                <ClickableRow
                  key={index}
                  onClick={() =>
                    history.push(`${PATH.APPLICATIONS}/${application.id}`)
                  }
                >
                  {isSp && <TdCell>申請 {index + 1}</TdCell>}
                  <TdCell dataLabel={'申請日時'}>
                    {toYYYYMMDDHHmm(application.createdAt)}
                  </TdCell>
                  <TdCell dataLabel={'申請ID'}>{application.id}</TdCell>
                  <TdCell dataLabel={'申請種別'}>
                    {getCategoryAndForm(application.formType).formName}
                  </TdCell>
                  <TdCell dataLabel={'ステータス'}>
                    {getUserApprovalStatusLabel(application.approvalStatus)}
                  </TdCell>
                </ClickableRow>
              ))}
          </Body>
        </Table>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: block;
  margin-top: 72px;
`
const SelectWrapper = styled.div`
  display: block;
  margin-top: 32px;
`
const BlankSpace = styled.div`
  height: 24px;
`
const ThCell = styled(Cell)`
  text-align: center;
  background: rgba(67, 154, 137, 0.25);
  padding: 16px 0;
  font-weight: bold;
`
const TdCell = styled(Cell)`
  text-align: center;
  height: 48px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    text-align: right;
    width: 100%;

    &:first-child {
      background: rgba(67, 154, 137, 0.25);
      font-weight: bold;
      text-align: center;
    }
    &:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      margin-right: 10px;
    }
  }
`
const ClickableRow = styled(Row)`
  cursor: pointer;
  &:hover > td {
    background-color: rgba(67, 154, 137, 0.1);
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
const TableHead = styled(Head)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: none;
  }
`
const TableHeadRow = styled(Row)`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
const SearchSelect = styled(Select)`
  width: 224px;
  height: 57px;
  flex-grow: 1;
  display: inline-block;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  font-size: 16px;
`
export default List
