import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import { Header } from './header'
import theme from './const/baseTheme'
import React, { useContext } from 'react'
import { MemberAuthContext } from '../../auth/MemberAuthProvider'
import { Redirect } from 'react-router-dom'
import { PATH } from '../../const'

const NoLoginLayout: React.FC = (props) => {
  const classes = useStyles()

  const { loginInfo } = useContext(MemberAuthContext)
  // ログイン済みならトップへリダイレクト
  if (loginInfo.uid.length > 0) {
    return <Redirect to={PATH.TOP} />
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header
          // @fixme 後でやる
          handleDrawerOpen={() => null}
          isDrawerOpen={false}
        />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <>{props.children}</>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
  }),
)

export default NoLoginLayout
