import React, { FC, ReactNode, createContext } from 'react'
import styled from 'styled-components'

export const TableGroupContext = createContext<{
  group: 'head' | 'body'
}>({
  group: 'body',
})

type Props = {
  children?: ReactNode
  className?: string
}

export const Table: FC<Props> = ({ children, className = '' }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead th {
    padding-bottom: 18px;
  }
`
