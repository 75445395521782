import React, { useState } from 'react'
import queryString from 'query-string'
import moment from 'moment'

const defaultDate = {
  from: moment().add(-1, 'months').toDate(),
  to: moment().toDate(),
}

const useCsvDownloadCriteria = (initialDownloadType: string) => {
  // 種別
  const [downloadFormType, setDownloadFormType] = useState(initialDownloadType)
  const handleChangeDownloadFormType = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setDownloadFormType(e.target.value)
  }
  // 出力期間 from
  const [acceptedAtFrom, setAcceptedAtFrom] = useState<Date | null>(
    defaultDate.from,
  )
  // 出力期間 to
  const [acceptedAtTo, setAcceptedAtTo] = useState<Date | null>(defaultDate.to)

  return {
    downloadFormType: {
      get: () => downloadFormType,
      set: (e: React.ChangeEvent<HTMLSelectElement>) =>
        handleChangeDownloadFormType(e),
    },
    acceptedAtFrom: {
      get: () => acceptedAtFrom,
      set: (date: Date | null) => setAcceptedAtFrom(date),
    },
    acceptedAtTo: {
      get: () => acceptedAtTo,
      set: (date: Date | null) => setAcceptedAtTo(date),
    },
    buildDownLoadLink: (apiPath: string) =>
      queryString.stringifyUrl({
        url: apiPath,
        query: {
          downloadFormType,
          acceptedAtFrom: moment(acceptedAtFrom).format('YYYY-MM-DD'),
          acceptedAtTo: moment(acceptedAtTo).format('YYYY-MM-DD'),
        },
      }),
  }
}

export default useCsvDownloadCriteria
