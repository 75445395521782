import React from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <Background>
        <Inner>
          <ReactLoading type="bars" color="#333" />
        </Inner>
      </Background>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  z-index: 10000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`
const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
`
const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default PageLoader
