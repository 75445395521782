import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { TableGroupContext } from './Table'

export type Props = {
  bulkActionArea?: ReactNode
  children?: ReactNode
  className?: string
}

export const Head: FC<Props> = ({
  bulkActionArea,
  className = '',
  children,
}) => {
  return (
    <thead className={className}>
      <TableGroupContext.Provider value={{ group: 'head' }}>
        {children}
      </TableGroupContext.Provider>
      {bulkActionArea && (
        <tr>
          <BulkActionTD colSpan={1000}>{bulkActionArea}</BulkActionTD>
        </tr>
      )}
    </thead>
  )
}

const BulkActionTD = styled.td`
  padding: 0.5rem 1rem;
  font-size: 16px;
`
