import React, { useContext } from 'react'
import { MemberAuthContext } from '../../../auth/MemberAuthProvider'
import { useParams } from 'react-router-dom'
import ChangeEmailForm from './form/Email'
import ChangePasswordForm from './form/Password'
import { Heading } from '../../ui/Heading'
import { Section } from './Account'

type ParamType = {
  type: 'email' | 'password'
}

const AccountEdit: React.FC = () => {
  const { loginInfo } = useContext(MemberAuthContext)
  const { type } = useParams<ParamType>()

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ {loginInfo.user.name}様（会員番号{loginInfo.user.memberId}）
      </Heading>
      <Section>
        {type === 'email' && (
          <ChangeEmailForm currentEmail={loginInfo.user.email} />
        )}
        {type === 'password' && <ChangePasswordForm />}
      </Section>
    </>
  )
}

export default AccountEdit
