import React from 'react'
import { Prompt } from 'react-router-dom'

type Props = {
  isDirty?: boolean
  message: string
}

const Component: React.FC<Props> = (props) => {
  return <Prompt when={props.isDirty ?? true} message={props.message} />
}

export default Component
