import React, { useState } from 'react'
import { Button } from '../../../ui/Button'
import { MessageModal } from '../../../ui/Modal'
import { useMultiStepState } from '../../../../hooks/useMultiStepState'
import { getStructuredLabel } from '../../../../presenter/categorisedForms'
import { FormType } from '../../../../types/formType'
import { useHistory } from 'react-router-dom'
import { PATH } from '../../../../const'

import styled from 'styled-components'
import { PartialLoader } from '../../../ui/Loader'
import { mediaQuery } from 'themes/size'

type Props = {
  FormComponentType: React.ElementType
  formType: FormType
  handleConfirm: () => Promise<boolean>
  handleSend: () => Promise<void>
}

const Component: React.FC<Props> = (props) => {
  const formState = useMultiStepState()
  const shouldFormDisabled = formState.currentStep === 'confirm'

  const history = useHistory()

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleConfirmClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    const isValid = await props.handleConfirm()
    if (isValid) {
      formState.nextStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
    setButtonDisabled(false)
  }

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (formState.currentStep === 'input') {
      history.push(PATH.APPLICATIONS_NEW)
    } else if (formState.currentStep === 'confirm') {
      formState.prevStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
  }

  const handleSendClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    await props.handleSend()
    modalOpen()
    setButtonDisabled(false)
  }

  const { FormComponentType } = props

  const [isOpen, setIsOpen] = useState(false)
  const modalOpen = () => setIsOpen(true)
  const onClickClose = () => {
    setIsOpen(false)
    history.push(PATH.TOP)
  }

  return (
    <form>
      <FormComponentType
        disabled={shouldFormDisabled}
        shouldShowOtherFormLinks={true}
      />
      <NextButtonWrapper>
        {formState.currentStep === 'input' && (
          <NextButton
            type="button"
            onClick={handleConfirmClick}
            disabled={buttonDisabled}
          >
            確認
          </NextButton>
        )}
        {formState.currentStep === 'confirm' && (
          <NextButton
            type="button"
            onClick={handleSendClick}
            disabled={buttonDisabled}
          >
            {buttonDisabled ? <PartialLoader /> : '送信'}
          </NextButton>
        )}
      </NextButtonWrapper>
      <BackButtonWrapper>
        <BackButton type="button" onClick={handleBackClick}>
          戻る
        </BackButton>
      </BackButtonWrapper>
      <MessageModal
        isOpen={isOpen}
        title={'「' + getStructuredLabel(props.formType) + '」を申請しました'}
        description={
          <p>
            申請内容は申請一覧からご確認ください。
            <br />
            自動送信される内容確認メールが届かない場合は、メールアドレスが誤っていた可能性がございます。その場合は事務局までご連絡ください。
          </p>
        }
        closeText="OK"
        onClickClose={onClickClose}
        onClickOverlay={onClickClose}
        onPressEscape={onClickClose}
        id="dialog-message"
      />
    </form>
  )
}

const NextButtonWrapper = styled.div`
  margin-top: 70px;
  text-align: center;
`
const NextButton = styled(Button)`
  width: 183px;
  height: 51px;
`
const BackButtonWrapper = styled(NextButtonWrapper)`
  margin-top: 45px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 16px;
  }
`
const BackButton = styled(NextButton)`
  font-weight: normal;
  color: #000;
  border: 1px solid #439a89;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #fff;
`

export default Component
