import React, { VFC } from 'react'
import styled from 'styled-components'

import { useOffsetHeight } from './modalHelper'

export type BaseProps = {
  title: string
  description?: React.ReactNode
  closeText: string | JSX.Element // '閉じる(など)' or PartialLoader
}

export type MessageModalContentInnerProps = BaseProps & {
  onClickClose: () => void
  isCloseButtonDisabled?: boolean
  onClickCancelButton?: () => void
}

export const MessageModalContentInner: VFC<MessageModalContentInnerProps> = ({
  title,
  description,
  closeText,
  onClickClose,
  isCloseButtonDisabled,
  onClickCancelButton,
}) => {
  const { titleRef, bottomRef } = useOffsetHeight()

  return (
    <>
      <Title ref={titleRef}>{title}</Title>
      <Description>{description}</Description>
      <Bottom ref={bottomRef}>
        <Button onClick={onClickClose} disabled={isCloseButtonDisabled}>
          {closeText}
        </Button>
      </Bottom>
      {typeof onClickCancelButton !== 'undefined' && (
        <CancelBottom ref={bottomRef}>
          <CancelButton onClick={onClickCancelButton}>戻る</CancelButton>
        </CancelBottom>
      )}
    </>
  )
}

const Title = styled.p`
  margin: 0 auto;
  padding: 1.75rem 1.5rem;
  font-size: 22px;
  line-height: 1;
  text-align: center;
`
const Description = styled.div`
  max-height: calc(100vh - 204px);
  overflow: auto;
  padding: 0px 2rem;
  font-size: 16px;
  line-height: 1.5;
`
const Button = styled.button`
  cursor: pointer;
  font-family: inherit;
  font-weight: bold;
  border-radius: 27.5px;
  color: #439a89;
  background-color: #fff;
  border: solid 1px #439a89;
  font-size: 16px;
  height: 40px;
  padding: 0 46px;
  transition: all 0.3s ease-out 0s;
  > label {
    display: flex;
    align-items: center;
  }
  &.prefix {
    justify-content: left;
  }
  &:hover {
    background-color: rgba(67, 154, 137, 0.5);
    color: rgb(255, 255, 255);
  }
`
const CancelButton = styled(Button)`
  color: #000;
  background-color: #f2f2f2;
  border: none;
  font-weight: normal;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
  }
`
const Bottom = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 1rem 1.5rem;
`
const CancelBottom = styled(Bottom)`
  padding: 1rem 1.5rem 2rem;
`
