import React, { useContext } from 'react'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import ChangePasswordForm from './form/Password'
import { Heading } from '../../ui/Heading'
import { Section } from '../account/Account'

const AccountEdit: React.FC = () => {
  const { loginInfo } = useContext(OfficeAuthContext)

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ {loginInfo.office.name}様（事業所番号{loginInfo.office.officeId}）
      </Heading>
      <Section>
        <ChangePasswordForm />
      </Section>
    </>
  )
}

export default AccountEdit
