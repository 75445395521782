import React, { useContext } from 'react'
import styled from 'styled-components'

import { ModalContentContext } from './ModalContent'

export const ModalCloser: React.VFC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { onClickClose } = useContext(ModalContentContext)
  return <Wrapper onClick={onClickClose}>{children}</Wrapper>
}

const Wrapper = styled.div`
  display: inline-block;
`
