import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { NyuinMimaiKinData } from '../../../../types/formData'

import { Heading } from '../../../ui/Heading'
import { Input } from '../../../ui/Input'
import { DatePicker } from '../../../ui/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'
import {
  BankAccountSection,
  FileSection,
  MemberInfoSection,
} from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { mediaQuery } from '../../../../themes/size'
import { isYYYYMMDD } from '../../../../util/validation'

type Props = {
  disabled: boolean
  canAdminUpload?: boolean
  handleSaveImage?: (files: File) => Promise<void>
  isUploading?: boolean
  handleDeleteImage?: (fileId: string) => Promise<void>
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const methods = useFormContext<NyuinMimaiKinData['formData']>()
  const { control, errors, setValue, getValues } = methods

  const handleChangeHospitalizedDate = (hospitalizedDate: Date | null) => {
    // 入院日セット
    setValue('hospitalizedDate', hospitalizedDate, { shouldValidate: true })

    const dischargedDate = getValues('dischargedDate')

    // 入院日と退院日に入力がある場合は入院日数計算
    if (hospitalizedDate && dischargedDate) {
      calcHospitalizedDays(hospitalizedDate, dischargedDate)
    } else {
      // 入院日数クリア
      setValue('hospitalizedDays', '')
    }
  }

  const handleChangeDischargedDate = (dischargedDate: Date | null) => {
    // 退院日セット
    setValue('dischargedDate', dischargedDate, { shouldValidate: true })

    const hospitalizedDate = getValues('hospitalizedDate')

    // 入院日と退院日に入力がある場合は入院日数計算
    if (dischargedDate && hospitalizedDate) {
      calcHospitalizedDays(hospitalizedDate, dischargedDate)
    } else {
      // 入院日数クリア
      setValue('hospitalizedDays', '')
    }
  }

  const calcHospitalizedDays = (
    hospitalizedDate: Date,
    dischargedDate: Date,
  ) => {
    // 入院日数クリア
    setValue('hospitalizedDays', '')

    // 退院日 - 入院日 + 1 = 入院日数
    const hospitalizedDays =
      moment(dischargedDate).diff(moment(hospitalizedDate), 'days') + 1

    if (hospitalizedDays >= 1) {
      // 入院日数セット
      setValue('hospitalizedDays', hospitalizedDays)

      // 請求金額セット
      if (hospitalizedDays >= 30) {
        setValue('amount', '20,000')
      } else if (hospitalizedDays >= 14) {
        setValue('amount', '10,000')
      } else if (hospitalizedDays >= 7) {
        setValue('amount', '5,000')
      } else {
        setValue('amount', '0')
      }
    }
  }

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼給付金申請 - 入院見舞金
        </Heading>
      </HeadingArea>

      {Object.keys(methods.errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■申請情報
        </Heading>
        <InputField>
          <Heading type="blockTitle" tag="span">
            入院日
          </Heading>
          <Controller
            control={control}
            name="hospitalizedDate"
            rules={{
              required: '入院日を入力してください',
              validate: { isYYYYMMDD },
            }}
            render={({ value, onBlur }) => (
              <DatePicker
                onChange={handleChangeHospitalizedDate}
                value={value}
                disabled={props.disabled}
                onBlur={onBlur}
              />
            )}
          />
        </InputField>
        {errors.hospitalizedDate && (
          <ErrorMessage>{errors.hospitalizedDate.message}</ErrorMessage>
        )}
        {errors.hospitalizedDate?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            退院日
          </Heading>
          <Controller
            control={control}
            name="dischargedDate"
            rules={{
              required: '退院日を入力してください',
              validate: { isYYYYMMDD },
            }}
            render={({ value, onBlur }) => (
              <DatePicker
                onChange={handleChangeDischargedDate}
                value={value}
                disabled={props.disabled}
                onBlur={onBlur}
              />
            )}
          />
        </InputField>
        {errors.dischargedDate && (
          <ErrorMessage>{errors.dischargedDate.message}</ErrorMessage>
        )}
        {errors.dischargedDate?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            入院日数
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="hospitalizedDays"
              render={({ onChange, value }) => (
                <Input
                  type="number"
                  min="0"
                  onChange={onChange}
                  value={value}
                  disabled
                />
              )}
            />
            <InputLabel>日</InputLabel>
          </LabelWrapper>
        </InputField>
        <InputField>
          <Heading type="blockTitle" tag="span">
            疾病名
          </Heading>
          <Controller
            control={control}
            name="diseaseName"
            rules={{ required: '疾病名を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.diseaseName && (
          <ErrorMessage>{errors.diseaseName.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            病院名
          </Heading>
          <Controller
            control={control}
            name="hospitalName"
            rules={{ required: '病院名を入力してください' }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {errors.hospitalName && (
          <ErrorMessage>{errors.hospitalName.message}</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            請求金額
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="amount"
              defaultValue="0"
              render={({ onChange, value, onBlur }) => (
                <Input
                  type="text"
                  onChange={onChange}
                  value={value}
                  disabled
                  onBlurCapture={onBlur}
                />
              )}
            />
            <InputLabel>円</InputLabel>
          </LabelWrapper>
        </InputField>
      </Section>

      <FileSection
        headingLabel="領収書等"
        disabled={props.disabled}
        canAdminUpload={props.canAdminUpload}
        handleSaveImage={props.handleSaveImage}
        isUploading={props.isUploading}
        handleDeleteImage={props.handleDeleteImage}
      />

      <BankAccountSection disabled={props.disabled} isMember={true} />

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
const Section = styled.section`
  margin-top: 72px;
`
const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;
    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const InputLabel = styled.label`
  margin-left: 8px;
  font-size: 16px;
`
export default Component
