import { FormDataInterface } from '../../types/formData'
import { ApplicationCreateRequest } from '../../types/requestBody/ApplicationCreateRequest'
import { FORM_TYPE_IDS } from '../../const/formTypeIds'
import * as mapper from './applicationRequestMapper'

export const mapToApplicationCreateRequestObject = async (
  formType: typeof FORM_TYPE_IDS[keyof typeof FORM_TYPE_IDS],
  formData: FormDataInterface['formData'],
): Promise<ApplicationCreateRequest> => {
  // 疑似オーバーロード
  switch (formType) {
    case FORM_TYPE_IDS.SHUKUHAKURIYOUHOJOKIN:
      return mapper.mapToShukuhakuRiyouHojoKinRequest(formData)
    case FORM_TYPE_IDS.SHIBOUCHOUIKIN:
      return mapper.mapToShibouChouiKinrequest(formData)
    case FORM_TYPE_IDS.SHOUGAIMIMAIKIN:
      return mapper.mapToShougaiMimaiKinRequest(formData)
    case FORM_TYPE_IDS.JUTAKUSAIGAIMIMAIKIN:
      return mapper.mapToJutakuSaigaimimaiKinRequest(formData)
    case FORM_TYPE_IDS.NYUINMIMAIKIN:
      return mapper.mapToNyuinMimaiKinRequest(formData)
    case FORM_TYPE_IDS.KEKKONGINKONKINKON:
      return mapper.mapToKekkonGinkonKinkonRequest(formData)
    case FORM_TYPE_IDS.SHUSSAN:
      return mapper.mapToShussanRequest(formData)
    case FORM_TYPE_IDS.SHUGAKUIWAIKIN:
      return mapper.mapToShugakuIwaiKinRequest(formData)
    case FORM_TYPE_IDS.SEIJINIWAIKIN:
      return mapper.mapToSeijinIwaiKinRequest(formData)
    case FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE:
      return mapper.mapToKaiinjouhouHenkoutodokeRequest(formData)
    case FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE:
      return mapper.mapToDoukyokazokuHenkoutodokeRequest(formData)
    case FORM_TYPE_IDS.OFFICE_TEIKIKENKOSHINDAN_JOSEIKIN:
      return mapper.mapToOfficeTeikikenkoshindanJoseikinRequest(formData)
    case FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE:
      return mapper.mapToOfficeKaiinjouhouHenkoutodokeCreateRequest(formData)
    case FORM_TYPE_IDS.OFFICE_ICHIBUTAIKAITODOKE:
      return mapper.mapToOfficeIchibutaikaitodokeCreateRequest(formData)
    default:
      throw new Error(
        `Couldn't find convert function of application type: ${formType}`,
      )
  }
}
