import React from 'react'
import styled from 'styled-components'

import { KaiinjouhouHenkoutodokeData } from '../../../../types/formData'

import { Heading } from '../../../ui/Heading'
import { Input } from '../../../ui/Input'
import { Controller, useFormContext } from 'react-hook-form'
import { MemberInfoSection } from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { mediaQuery } from '../../../../themes/size'
import { fetchAddress } from '../../../../util/fetchAddressByPostalCode'
import {
  isPhoneNumber,
  isPostalCode,
  isZenKatakana,
  isNotAllEmpty as isNotAllEmptyBase,
} from '../../../../util/validation'

type Props = {
  disabled: boolean
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const methods = useFormContext<KaiinjouhouHenkoutodokeData['formData']>()
  const { control } = methods

  const handleChangePostalCode = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputPostalCode = e.target.value
    if (inputPostalCode.length === 8) {
      const address = await fetchAddress(inputPostalCode)
      methods.setValue('address', address, { shouldDirty: true })
    }
  }

  const isNotAllEmpty = isNotAllEmptyBase(methods, [
    'name',
    'nameKana',
    'postalCode',
    'address',
    'tel',
  ])

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼変更届 - 会員情報
        </Heading>
      </HeadingArea>

      {Object.keys(methods.errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■申請情報（会員情報）
        </Heading>
        <BlankSpace />
        <Heading type="sectionTitle" tag="h2">
          ※変更点のみご入力ください。
        </Heading>
        <BlankSpace />
        {methods.errors.name?.type === 'isNotAllEmpty' && (
          <ErrorMessage>一つ以上の項目をご入力ください。</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            会員氏名
          </Heading>
          <Controller
            control={control}
            name="name"
            rules={{ validate: { isNotAllEmpty } }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value.length === 0) {
                    methods.setValue('name', undefined)
                  }
                  handleChangePostalCode(e)
                }}
                value={value}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        <InputField>
          <Heading type="blockTitle" tag="span">
            フリガナ
            <br />
            (全角カナ)
          </Heading>
          <Controller
            control={control}
            name="nameKana"
            rules={{
              validate: {
                isZenKatakana,
                isNotAllEmpty,
              },
            }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value.length === 0) {
                    methods.setValue('nameKana', undefined)
                  }
                }}
                value={value}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {methods.errors.nameKana?.type === 'isZenKatakana' && (
          <ErrorMessage>全角カナで入力してください</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            郵便番号
            <br />
            (半角数字+半角ハイフン)
          </Heading>
          <Controller
            control={control}
            name="postalCode"
            rules={{
              validate: { isPostalCode, isNotAllEmpty },
            }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value.length === 0) {
                    methods.setValue('postalCode', undefined)
                  }
                  handleChangePostalCode(e)
                }}
                value={value}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {methods.errors.postalCode?.type === 'isPostalCode' && (
          <ErrorMessage>半角数字+半角ハイフンで入力してください</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            会員住所
          </Heading>
          <Controller
            control={control}
            name="address"
            rules={{ validate: { isNotAllEmpty } }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value.length === 0) {
                    methods.setValue('address', undefined)
                  }
                  handleChangePostalCode(e)
                }}
                value={value}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        <InputField>
          <Heading type="blockTitle" tag="span">
            会員電話番号
            <br />
            (半角数字)
          </Heading>
          <Controller
            control={control}
            name="tel"
            rules={{
              validate: {
                isPhoneNumber,
                isNotAllEmpty,
              },
            }}
            render={({ onChange, value, onBlur }) => (
              <Input
                type="text"
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value.length === 0) {
                    methods.setValue('tel', undefined)
                  }
                }}
                value={value}
                disabled={props.disabled}
                onBlurCapture={onBlur}
              />
            )}
          />
        </InputField>
        {methods.errors.tel?.type === 'isPhoneNumber' && (
          <ErrorMessage>半角数字10桁か11桁で入力してください</ErrorMessage>
        )}
      </Section>

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
export const Section = styled.section`
  margin-top: 72px;
`
export const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
export const BlankSpace = styled.div`
  height: 24px;
`
const ErrorMessage = styled.div`
  color: red;
`
export default Component
