import firebase from 'firebase/app'
import 'firebase/functions'
import React, { createContext, useEffect, useState } from 'react'
import app from '../base'
import { API, PATH } from '../const'
import { OfficeLoginInfo } from '../types/LoginInfo'
import { Redirect, useLocation } from 'react-router-dom'
import { toOfficeLoginInfo } from '../mapper/response'
import { PageLoader } from '../components/ui/Loader'

type AuthContextType = {
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  revalidate: () => Promise<void>
  bearerToken: string
  loginInfo: OfficeLoginInfo
}

const login = async (email: string, password: string): Promise<void> => {
  await app.auth().signInWithEmailAndPassword(email, password)
}

const logout = async (): Promise<void> => {
  try {
    await app.auth().signOut()
  } catch (error) {
    console.error(error)
  }
}

const initialValue: AuthContextType = {
  login,
  logout,
  revalidate: async () => {
    return
  },
  bearerToken: '',
  loginInfo: {
    uid: '',
    office: {
      officeId: '',
      name: '',
      nameKana: '',
      postalCode: '',
      address: '',
      tel: '',
      clerkName: '',
      clerkNameKana: '',
      representativeName: '',
      representativeNameKana: '',
      firstLoggedInAt: '',
      paymentMethod: 'withdrawal',
    },
  },
}

export const OfficeAuthContext = createContext<AuthContextType>(initialValue)

export const OfficeAuthProvider: React.FC = (props) => {
  const currentPath = useLocation().pathname

  // フロントで直接firebase authentication を照会して、セッションがなかったらリダイレクトしたいのでここで状態を持つ
  const [loadState, setLoadState] = useState<'loading' | 'noUser' | 'loggedIn'>(
    'loading',
  )
  const [loginInfo, setLoginInfo] = useState<OfficeLoginInfo | null>()
  useEffect(() => {
    const unsubscribed = app.auth().onAuthStateChanged(async (user) => {
      setLoginInfo(undefined)
      if (!user) {
        setLoadState('noUser')
      } else {
        setLoadState('loggedIn')
      }
    })
    return () => {
      unsubscribed()
    }
  }, [])

  const [bearerToken, setBearerToken] = useState<string>('')
  const [isMemberUser, setIsMemberUser] = useState<boolean>(false)

  const setAboutMe = async () => {
    const token = await firebase.auth().currentUser?.getIdToken()
    if (typeof token === 'undefined') {
      return
    }
    setBearerToken(token)
    const json = await fetch(API.ABOUT_ME, {
      headers: { Authorization: 'Bearer ' + token },
      mode: 'cors',
    }).then((res) => res.json())
    // @todo adminログインしている場合、ここが404になるので/adminにリダイレクトして差し上げたい
    if (json.user) {
      setIsMemberUser(true)
    } else {
      setLoginInfo(toOfficeLoginInfo(json))
    }
    return
  }

  useEffect(() => {
    switch (loadState) {
      case 'loading':
        // nothing to do (still undefined)
        break
      case 'noUser':
        setLoginInfo(null)
        break
      case 'loggedIn':
        ;(async () => {
          await setAboutMe()
        })()
        break
      default:
        // https://zenn.dev/suin/scraps/9f3cfc934e98fe#comment-a39a91c1d4a4e9
        throw new Error(`Unexpected state: ${loadState}`)
    }
  }, [loadState])

  if (typeof loginInfo === 'undefined') {
    if (isMemberUser) {
      return <Redirect to={PATH.TOP} />
    }
    return <PageLoader />
  }

  // ログインしていないユーザの場合
  if (loginInfo === null) {
    if (PATH.NO_LOGIN_PATHS.includes(currentPath)) {
      // ログイン不要の画面にアクセスしてきたらそのまま表示して差し上げる
      return <>{props.children}</>
    } else {
      // ログイン必須画面に非ログインでアクセスしてきたら /login にリダイレクト
      return <Redirect to={PATH.OFFICE_LOGIN} />
    }
  }

  // ログインしてんのに非ログイン前提の画面にアクセスしてきたらトップにリダイレクトする
  if (PATH.NO_LOGIN_PATHS.includes(currentPath)) {
    return <Redirect to={PATH.OFFICE_TOP} />
  }

  return (
    <OfficeAuthContext.Provider
      value={{
        login,
        logout,
        revalidate: setAboutMe,
        bearerToken,
        loginInfo: loginInfo,
      }}
    >
      {props.children}
    </OfficeAuthContext.Provider>
  )
}
