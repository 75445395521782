import React from 'react'
import useSWR from 'swr'
import { Applications as ApplicationsType } from '../../../types/application'
import { Link } from 'react-router-dom'
import { API, PATH } from '../../../const'
import { useContext } from 'react'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import { PageLoader } from '../../ui/Loader'
import { Heading } from '../../ui/Heading'
import styled from 'styled-components'
import { Button } from '../../ui/Button'
import { mediaQuery } from '../../../themes/size'

const OfficeTop: React.FC = () => {
  const { loginInfo, bearerToken } = useContext(OfficeAuthContext)

  const fetcher = () =>
    fetch(API.APPLICATIONS, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())

  const { data: applications } = useSWR<ApplicationsType>(
    API.APPLICATIONS,
    fetcher,
  )
  if (typeof applications === 'undefined') {
    return <PageLoader />
  }

  const allApplicationsLength = applications.length

  const applyingApplicationsLength = () => {
    const applying = applications.filter((application) => {
      return application.approvalStatus === 'applying'
    })

    return applying.length
  }

  const inspectingApplicationsLength = () => {
    const inspecting = applications.filter((application) => {
      return application.approvalStatus === 'inspecting'
    })

    return inspecting.length
  }

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■ {loginInfo.office.name}様 (事業所番号{loginInfo.office.officeId})
      </Heading>
      <BlankSpace />
      <Heading type="sectionTitle" tag="h2">
        現在の申請状況は、下記の通りです。
      </Heading>
      <Wrapper>
        <ApplicationButtonWrapper>
          <ButtonWrapperLink
            to={`${PATH.OFFICE_APPLICATIONS}/?filterStatus=applying`}
          >
            <LinkButtonGray>
              申請中 <br />
              {applyingApplicationsLength()}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink
            to={`${PATH.OFFICE_APPLICATIONS}/?filterStatus=inspecting`}
          >
            <LinkButtonGray>
              審査中 <br />
              {inspectingApplicationsLength()}件
            </LinkButtonGray>
          </ButtonWrapperLink>
          <ButtonWrapperLink to={PATH.OFFICE_APPLICATIONS}>
            <LinkButtonGray>
              全ての申請
              <br />
              {allApplicationsLength}件
            </LinkButtonGray>
          </ButtonWrapperLink>
        </ApplicationButtonWrapper>
      </Wrapper>
      <Wrapper>
        <Heading type="sectionTitle" tag="h2">
          ■メニュー
        </Heading>
        <ButtonWrapper>
          <ButtonWrapperLink to={PATH.OFFICE_APPLICATIONS_NEW}>
            <LinkButtonGrn>新規申請を行う</LinkButtonGrn>
          </ButtonWrapperLink>
          <ButtonWrapperLink to={PATH.OFFICE_APPLICATIONS}>
            <LinkButtonGrn>過去の申請を見る</LinkButtonGrn>
          </ButtonWrapperLink>
        </ButtonWrapper>
        <ButtonWrapper>
          <ButtonWrapperLink to={PATH.OFFICE_ACCOUNT}>
            <LinkButtonGrn>アカウント情報</LinkButtonGrn>
          </ButtonWrapperLink>
        </ButtonWrapper>
      </Wrapper>
    </>
  )
}

const BlankSpace = styled.div`
  height: 24px;
`
const Wrapper = styled.div`
  margin-top: 32px;
  min-width: 1000px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    min-width: initial;
  }
`
const ButtonWrapper = styled.div`
  margin-top: 32px;
  > :nth-child(2) {
    margin-left: 64px;
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    text-align: center;
    margin-top: 0;

    > :nth-child(2) {
      margin-left: 0;
    }
  }
`
const ApplicationButtonWrapper = styled.div`
  margin-top: 32px;
  > :nth-child(n + 2) {
    margin-left: 64px;
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    text-align: center;
    margin-top: 0;

    > :nth-child(n + 2) {
      margin-left: 0;
    }
  }
`
const LinkButtonGray = styled(Button)`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background: #f1f0ee;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 48px 0;
  width: 272px;

  &:hover {
    background-color: rgba(241, 240, 238, 0.3);
    color: #000;
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 16px;
  }
`
const LinkButtonGrn = styled(Button)`
  font-size: 16px;
  font-weight: normal;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 48px 0;
  width: 272px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 16px;
  }
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`

export default OfficeTop
