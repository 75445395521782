import { ReactNode, VFC, useCallback, useEffect, useRef, useState } from 'react'

type Props = {
  children: ReactNode
}

export const FocusTrap: VFC<Props> = ({ children }) => {
  const [triggerElement, setTriggerElement] = useState<Element | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key !== 'Tab' || ref.current === null) {
      return
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    setTriggerElement(document.activeElement)
    setTimeout(() => {
      // delay focus on the first element so that is occurs last
      if (ref.current === null) {
        return
      }
    }, 1)
  }, [])

  useEffect(() => {
    return () => {
      if (triggerElement instanceof HTMLElement) {
        triggerElement.focus()
      }
    }
  }, [triggerElement])

  return <div ref={ref}>{children}</div>
}
