import { FormType } from '../../../../types/formType'
import React from 'react'
import { FORM_TYPE_IDS } from '../../../../const/formTypeIds'
import KaiinnjouhouHenkoutodoke from './KaiinnjouhouHenkoutodoke'
import DoukyoKazokuHenkoutodoke from './DoukyoKazokuHenkoutodoke'
import ShukuhakuRiyouHojoKin from './ShukuhakuRiyouHojoKin'
import ShibouChouiKin from './ShibouChouiKin'
import ShougaiMimaiKin from './ShougaiMimaiKin'
import NyuinMimaiKin from './NyuinMimaiKin'
import JutakuSaigaiMimaiKin from './JutakuSaigaiMimaiKin'
import KekkonGinkonKinkon from './KekkonGinkonKinkon'
import Shussan from './Shussan'
import ShugakuIwaiKin from './ShugakuIwaiKin'
import SeijinIwaiKin from './SeijinIwaiKin'

export const getFormComponentType = (formType: FormType): React.ElementType => {
  switch (formType) {
    case FORM_TYPE_IDS.SHUKUHAKURIYOUHOJOKIN:
      return ShukuhakuRiyouHojoKin
    case FORM_TYPE_IDS.SHIBOUCHOUIKIN:
      return ShibouChouiKin
    case FORM_TYPE_IDS.SHOUGAIMIMAIKIN:
      return ShougaiMimaiKin
    case FORM_TYPE_IDS.NYUINMIMAIKIN:
      return NyuinMimaiKin
    case FORM_TYPE_IDS.JUTAKUSAIGAIMIMAIKIN:
      return JutakuSaigaiMimaiKin
    case FORM_TYPE_IDS.KEKKONGINKONKINKON:
      return KekkonGinkonKinkon
    case FORM_TYPE_IDS.SHUSSAN:
      return Shussan
    case FORM_TYPE_IDS.SHUGAKUIWAIKIN:
      return ShugakuIwaiKin
    case FORM_TYPE_IDS.SEIJINIWAIKIN:
      return SeijinIwaiKin
    case FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE:
      return KaiinnjouhouHenkoutodoke
    case FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE:
      return DoukyoKazokuHenkoutodoke
    default:
      throw new Error('閲覧できない申請種別です')
  }
}
