import React, { useContext, useEffect, useState } from 'react'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { useHistory, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { API, PATH } from '../../../const'
import useSWR from 'swr'
import { AdminLoginInfo } from '../../../types/AdminLoginInfo'
import { PageLoader, PartialLoader } from '../../ui/Loader'
import { Heading } from '../../ui/Heading'
import { Form } from './form'
import styled from 'styled-components'
import { Button } from '../../ui/Button'
import { useMultiStepState } from '../../../hooks/useMultiStepState'
import { MessageModal } from '../../ui/Modal'
import { getStepLabel } from './presenter'

type ParamType = {
  adminUserId: string
}

const Component: React.FC = () => {
  const formState = useMultiStepState()
  const history = useHistory()
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { bearerToken } = useContext(AdminAuthContext)
  const { adminUserId } = useParams<ParamType>()

  const methods = useForm({
    mode: 'onBlur',
  })

  const apiPath = `${API.ADMIN_ADMIN_USERS}/${adminUserId}`
  const fetcher = () =>
    fetch(apiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())

  const { data: adminUser } = useSWR<AdminLoginInfo['admin']>(apiPath, fetcher)

  const loading = typeof adminUser === 'undefined'
  useEffect(() => {
    if (!loading && adminUser) {
      methods.reset(adminUser)
    }
    // eslint-disable-next-line
  }, [loading])

  if (typeof adminUser === 'undefined') {
    return <PageLoader />
  }

  const handleModalClose = (): void => {
    setIsCompleteModalOpen(false)
    if (isComplete) {
      history.push(`${PATH.ADMIN_ADMIN_USERS}/${adminUserId}`)
    }
  }

  const handleSubmit = async (): Promise<void> => {
    await methods.handleSubmit(
      async (data: Omit<AdminLoginInfo, 'adminId'>) => {
        const requestBody = data
        const res = await fetch(apiPath, {
          headers: { Authorization: 'Bearer ' + bearerToken },
          method: 'put',
          mode: 'cors',
          body: JSON.stringify(requestBody),
        })
        if (res.status >= 400) {
          setIsComplete(false)
          return
        }
        setIsComplete(true)
        methods.reset(data)
      },
    )()
  }

  const handleClickNext = async () => {
    if (formState.currentStep === 'input') {
      // 入力から確認
      document?.querySelector('main')?.scrollTo(0, 0)
      const isValid = await methods.trigger()
      isValid && formState.nextStep()
    } else {
      // 確認から完了
      setIsLoading(true)
      await handleSubmit()
      setIsCompleteModalOpen(true)
      setIsLoading(false)
    }
  }

  const handleClickBack = async () => {
    if (formState.currentStep === 'input') {
      // 入力から詳細
      history.push(`${PATH.ADMIN_ADMIN_USERS}/${adminUserId}`)
    } else {
      // 確認から入力
      formState.prevStep()
    }
  }

  const getNextButtonLabel = () => {
    if (isLoading) {
      return <PartialLoader />
    }
    return formState.currentStep === 'input' ? '確認' : '保存'
  }

  return (
    <FormProvider {...methods}>
      <Heading type="screenTitle" tag="h1">
        管理者情報 編集
        {getStepLabel(formState.currentStep)}
      </Heading>
      <Form readonly={formState.currentStep === 'confirm'} />
      <ButtonWrapper>
        <Button onClick={handleClickNext} disabled={isLoading}>
          {getNextButtonLabel()}
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <BackButton onClick={handleClickBack}>戻る</BackButton>
      </ButtonWrapper>
      <MessageModal
        isOpen={isCompleteModalOpen}
        title={isComplete ? '登録しました' : '登録に失敗しました'}
        description={
          isComplete ? undefined : <p>しばらく経ってから再度お試しください。</p>
        }
        closeText="OK"
        onClickClose={handleModalClose}
        onClickOverlay={handleModalClose}
        onPressEscape={handleModalClose}
        id="dialog-message"
      />
    </FormProvider>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 800px;
  text-align: center;
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`

export default Component
