import moment from 'moment'

const YYYYMD_JA = 'YYYY年M月D日'
const YYYYMMDDHHmm = 'YYYY/MM/DD HH:mm'
const YYYYMMDD = 'YYYY/MM/DD'

export const toYYYYMD_JA = (dateString: string) =>
  moment(dateString).format(YYYYMD_JA)
export const toYYYYMMDDHHmm = (dateString: string) =>
  moment(dateString).format(YYYYMMDDHHmm)
export const toYYYYMMDD = (dateString: string) =>
  moment(dateString).format(YYYYMMDD)

export const convertToDateTime = (date: Date): string => moment(date).format()

export const getDate = {
  today: () => moment().toDate(),
  yearAgo: (year: number = 1) =>
    moment()
      .add(year * -1, 'year')
      .toDate(),
}

export const dateStringify = (date: Date | null) =>
  date ? date.toLocaleDateString() : ''

export const isBefore = (a: Date, b: Date) => moment(a).isBefore(b)
