import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Heading } from '../../../../ui/Heading'
import { Input } from '../../../../ui/Input'
import { RadioButtonLabel } from '../../../../ui/RadioButtonLabel'
import styled from 'styled-components'
import { InputField, Section } from '../../../applications/form/ShougaiMimaiKin'
import { mediaQuery } from '../../../../../themes/size'
import { isBankNumber, isZenKatakana } from '../../../../../util/validation'

type Props = {
  disabled: boolean
}

export const BankAccountSection: React.FC<Props> = (props) => {
  const { errors, control } = useFormContext()
  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■振込口座
      </Heading>
      <InputField>
        <Heading type="blockTitle" tag="span">
          金融機関名
        </Heading>
        <Controller
          control={control}
          name="bankAccount.bankName"
          rules={{ required: '金融機関名を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.bankAccount?.bankName && (
        <ErrorMessage>{errors.bankAccount.bankName.message}</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          支店名
        </Heading>
        <Controller
          control={control}
          name="bankAccount.branchName"
          rules={{ required: '支店名を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.bankAccount?.branchName && (
        <ErrorMessage>{errors.bankAccount.branchName.message}</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          口座種別
        </Heading>
        <Controller
          control={control}
          name="bankAccount.accountType"
          defaultValue="normal"
          render={({ onChange, value }) => (
            <RadioButtonWrapper>
              <RadioButtonArea>
                <RadioButtonLabel
                  label="普通"
                  value="normal"
                  checked={value === 'normal'}
                  onChange={onChange}
                  disabled={props.disabled}
                />
              </RadioButtonArea>
              <RadioButtonArea>
                <RadioButtonLabel
                  label="当座"
                  value="current"
                  checked={value === 'current'}
                  onChange={onChange}
                  disabled={props.disabled}
                />
              </RadioButtonArea>
            </RadioButtonWrapper>
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          口座番号
          <br />
          (半角数字)
        </Heading>
        <Controller
          control={control}
          name="bankAccount.accountNumber"
          rules={{
            required: '口座番号を入力してください',
            validate: { isBankNumber },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.bankAccount?.accountNumber && (
        <ErrorMessage>
          {errors.bankAccount?.accountNumber?.message}
        </ErrorMessage>
      )}
      {errors.bankAccount?.accountNumber?.type === 'isBankNumber' && (
        <ErrorMessage>半角数字7桁で入力してください</ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          口座名義
        </Heading>
        <Controller
          control={control}
          name="bankAccount.accountOwnerName"
          rules={{ required: '口座名義（会員名義）を入力してください' }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.bankAccount?.accountOwnerName && (
        <ErrorMessage>
          {errors.bankAccount?.accountOwnerName?.message}
        </ErrorMessage>
      )}
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
          <br />
          (全角カナ)
        </Heading>
        <Controller
          control={control}
          name="bankAccount.accountOwnerNameKana"
          rules={{
            required: 'フリガナを入力してください',
            validate: { isZenKatakana },
          }}
          render={({ onChange, value, onBlur }) => (
            <Input
              type="text"
              defaultValue={value}
              onChange={onChange}
              disabled={props.disabled}
              onBlurCapture={onBlur}
            />
          )}
        />
      </InputField>
      {errors.bankAccount?.accountOwnerNameKana && (
        <ErrorMessage>
          {errors.bankAccount.accountOwnerNameKana.message}
        </ErrorMessage>
      )}
      {errors.bankAccount?.accountOwnerNameKana?.type === 'isZenKatakana' && (
        <ErrorMessage>全角カナで入力してください</ErrorMessage>
      )}
    </Section>
  )
}

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
  }
`
const RadioButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 160px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    margin-top: 8px;
  }
`
const ErrorMessage = styled.div`
  color: red;
`
