import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import {
  AdminApplication as AdminApplicationType,
  ApprovalHistory,
} from '../../../types/application'
import { getFormComponentType } from '../applications/form'
import { getFormComponentType as getOfficeFormComponentType } from '../officeApplications/form'
import { Section } from '../applications/form/SeijinIwaiKin'
import { Button } from '../../ui/Button'
import { MessageModal } from '../../ui/Modal'
import { PageLoader, PartialLoader } from '../../ui/Loader'
import { Heading } from '../../ui/Heading'
import { Textarea } from '../../ui/Textarea'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { API, PATH } from '../../../const'
import { getAdminApprovalHistoryLabel } from '../../../presenter/getApprovalStatusLabel'
import { toYYYYMMDDHHmm } from '../../../presenter/dateUtil'
import {
  Approve1ButtonSection,
  Approve2ButtonSection,
  AccepteButtonSection,
} from './form/inputParts/'
import { TWO_STAGE_APPROVALS } from 'const/formTypeIds'
import { mapToAttachedFileRequestParts } from '../../../mapper/request/applicationRequestMapper'
import { FileResponseType } from '../../../types/formData'

type ParamType = {
  applicationId: string
}

const Single: React.FC = () => {
  const methods = useForm({
    mode: 'onBlur',
  })

  const history = useHistory<{ searchQuery: string }>()
  const path = useLocation().pathname
  const isMemberApplication = path.match(
    new RegExp(`^${PATH.ADMIN_APPLICATIONS_MEMBER}*`),
  )

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isCancelOK, setIsCancelOK] = useState(true)
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [isSaveOK, setIsSaveOK] = useState(true)
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false)
  const [isApprovalOK, setIsApprovalOK] = useState(true)
  const [
    isApprovalCompleteModalOpen,
    setIsApprovalCompleteModalOpen,
  ] = useState(false)
  const [isRemandModalOpen, setIsRemandModalOpen] = useState(false)
  const [isRemandOK, setIsRemandOK] = useState(true)
  const [isRemandCompleteModalOpen, setIsRemandCompleteModalOpen] = useState(
    false,
  )
  const [isApprovalStopModalOpen, setIsApprovalStopModalOpen] = useState(false)

  const [isModalHandlerProcessing, setIsModalHandlerProcessing] = useState(
    false,
  )
  const [isAdminRemarksSaving, setIsAdminRemarksSaving] = useState(false)
  const [
    isPrivateAdminRemarksSaving,
    setIsPrivateAdminRemarksSaving,
  ] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const { loginInfo, bearerToken } = useContext(AdminAuthContext)

  const { applicationId } = useParams<ParamType>()

  const apiPath = `${
    isMemberApplication
      ? API.ADMIN_APPLICATIONS_MEMBER
      : API.ADMIN_APPLICATIONS_OFFICE
  }/${applicationId}`
  const fetcher = (url: string) =>
    fetch(url, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    }).then((res) => res.json())
  const { data: application } = useSWR<AdminApplicationType>(apiPath, fetcher)
  const loading = typeof application === 'undefined'
  useEffect(() => {
    if (!loading && application) {
      methods.reset(application.formData)
    }
    // eslint-disable-next-line
  }, [loading])
  if (typeof application === 'undefined') {
    return <PageLoader />
  }

  let FormComponentType: React.ElementType
  try {
    FormComponentType = getFormComponentType(application.formType)
  } catch (e) {
    // @todo getFormComponentTypeを一つにまとめる
    FormComponentType = getOfficeFormComponentType(application.formType)
  }

  const role = loginInfo.admin.role
  const isTwoStagesApprove = TWO_STAGE_APPROVALS.includes(application.formType)

  const isConfirmerProcess: boolean =
    application.approvalStatus === 'applying' ||
    application.approvalStatus === 'remanded'

  const isApproverProcess: boolean = application.approvalStatus === 'approved1'

  const isFinalProcess: boolean = application.approvalStatus === 'approved2'

  const canFixApplication: boolean = (() => {
    if (isTwoStagesApprove) {
      // 2段承認の時は修正可能なタイミングで誰でも修正可能
      return isConfirmerProcess
    } else {
      // 3段承認の時は修正可能なタイミングで、かつ確認者のみ修正可能
      return isConfirmerProcess && loginInfo.admin.role === 'confirmer'
    }
  })()

  // 修正項目保存処理
  const saveAdminRemarks = async (): Promise<Response> =>
    fetch(`${apiPath}/adminRemarks`, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'put',
      body: JSON.stringify({
        adminRemarks: methods.watch(
          'application.adminRemarks',
          application.adminRemarks,
        ),
      }),
    })
  const handleAdminRemarksSave = async (): Promise<void> => {
    setIsAdminRemarksSaving(true)
    const res = await saveAdminRemarks()
    if (res.status >= 400) {
      setIsSaveOK(false)
    }
    setIsAdminRemarksSaving(false)
    setIsSaveModalOpen(true)
  }

  // 管理者備考保存
  const savePrivateAdminRemarks = async (): Promise<Response> =>
    fetch(`${apiPath}/privateAdminRemarks`, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'put',
      body: JSON.stringify({
        privateAdminRemarks: methods.watch(
          'application.privateAdminRemarks',
          application.privateAdminRemarks,
        ),
      }),
    })
  const handlePrivateAdminRemarksSave = async (): Promise<void> => {
    setIsPrivateAdminRemarksSaving(true)
    const res = await savePrivateAdminRemarks()
    if (res.status >= 400) {
      setIsSaveOK(false)
    }
    setIsPrivateAdminRemarksSaving(false)
    setIsSaveModalOpen(true)
  }

  // 取消
  const handleCancel = async (): Promise<void> => {
    setIsModalHandlerProcessing(true)
    const saveCancel = () =>
      fetch(apiPath, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        mode: 'cors',
        method: 'delete',
      })
    // 並列実行
    const results = await Promise.all([
      saveCancel(),
      saveAdminRemarks(),
      savePrivateAdminRemarks(),
    ])
    if (
      results.map((res) => res.status).filter((status) => status >= 400)
        .length > 0
    ) {
      setIsCancelOK(false)
    } else {
      application.approvalHistories = [
        ...(application.approvalHistories ?? []),
        {
          approvedStatus: 'rejected',
          approverName: loginInfo.admin.name,
          date: new Date(),
        },
      ]
    }

    setIsConfirmModalOpen(false)
    setIsModalHandlerProcessing(false)
    setIsCompleteModalOpen(true)
  }

  // 差戻し
  const handleRemand = async (): Promise<void> => {
    setIsModalHandlerProcessing(true)
    const saveRemanded = () =>
      fetch(`${apiPath}/approvalStatus`, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        mode: 'cors',
        method: 'put',
        body: JSON.stringify({
          approvalStatus: 'remanded',
        }),
      })
    // 並列実行
    const results = await Promise.all([
      saveRemanded(),
      saveAdminRemarks(),
      savePrivateAdminRemarks(),
    ])
    if (
      results.map((res) => res.status).filter((status) => status >= 400)
        .length > 0
    ) {
      setIsRemandOK(false)
    } else {
      application.approvalStatus = 'remanded'
      application.approvalHistories = [
        ...(application.approvalHistories ?? []),
        {
          approvedStatus: 'remanded',
          approverName: loginInfo.admin.name,
          date: new Date(),
        },
      ]
    }

    setIsRemandModalOpen(false)
    setIsModalHandlerProcessing(false)
    setIsRemandCompleteModalOpen(true)
  }

  // 申請ステータス更新
  const handleApproval = async (): Promise<void> => {
    setIsModalHandlerProcessing(true)
    // 1つ前の承認と同じ人は承認できない
    if (isSameApprover) {
      setIsApprovalModalOpen(false)
      setIsApprovalStopModalOpen(true)
      return
    }

    let nextStatus: AdminApplicationType['approvalStatus']
    if (isTwoStagesApprove) {
      switch (application.approvalStatus) {
        case 'applying':
          nextStatus = 'approved1'
          break
        case 'approved1':
          nextStatus = 'accepted'
          break
        case 'remanded':
          nextStatus = 'approved1'
          break
        default:
          throw new Error('承認ステータスエラー')
      }
    } else {
      switch (application.approvalStatus) {
        case 'applying':
          nextStatus = 'approved1'
          break
        case 'approved1':
          nextStatus = 'approved2'
          break
        case 'approved2':
          nextStatus = 'accepted'
          break
        case 'remanded':
          nextStatus = 'approved1'
          break
        default:
          throw new Error('承認ステータスエラー')
      }
    }

    const saveApproval = () =>
      fetch(`${apiPath}/approvalStatus`, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        mode: 'cors',
        method: 'put',
        body: JSON.stringify({ approvalStatus: nextStatus }),
      })
    // 並列実行
    const results = await Promise.all([
      saveApproval(),
      saveAdminRemarks(),
      savePrivateAdminRemarks(),
    ])
    if (
      results.map((res) => res.status).filter((status) => status >= 400)
        .length > 0
    ) {
      setIsApprovalOK(false)
    } else {
      application.approvalStatus = nextStatus
      application.approvalHistories = [
        ...(application.approvalHistories ?? []),
        {
          approvedStatus: nextStatus,
          approverName: loginInfo.admin.name,
          date: new Date(),
        },
      ]
    }

    setIsApprovalModalOpen(false)
    setIsModalHandlerProcessing(false)
    setIsApprovalCompleteModalOpen(true)
  }

  const getRecentApprover = (approvalHistories: ApprovalHistory[]): string => {
    let recentApprover = ''

    const recentApprovalHistory = (approvalHistories ?? [])
      .sort((a, b) => (a.date > b.date ? 1 : 0))
      .slice(-1)[0]

    if (recentApprovalHistory) {
      if (
        recentApprovalHistory.approvedStatus === 'approved1' ||
        recentApprovalHistory.approvedStatus === 'approved2'
      ) {
        recentApprover = recentApprovalHistory.approverName
      }
    }

    return recentApprover
  }

  const handleSaveImage = async (file: File): Promise<void> => {
    setIsUploading(true)
    const res = await fetch(`${apiPath}/attachedFiles`, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'post',
      body: JSON.stringify((await mapToAttachedFileRequestParts([file]))[0]),
    }).then((res) => res.json())
    application.formData.attachedFileResponse.push(res)
    setIsUploading(false)
  }

  const handleDeleteImage = async (fileId: string): Promise<void> => {
    await fetch(`${apiPath}/attachedFiles/${fileId}`, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
      method: 'delete',
    })
    const targetIndex: number = application.formData.attachedFileResponse.findIndex(
      (element: FileResponseType[number]) => element.id === fileId,
    )
    application.formData.attachedFileResponse.splice(targetIndex, 1)
    methods.setValue(
      'attachedFileResponse',
      application.formData.attachedFileResponse,
    )
  }

  const isSameApprover =
    loginInfo.admin.name === getRecentApprover(application.approvalHistories)

  return (
    <FormProvider {...methods}>
      <FormComponentType
        disabled={true}
        canAdminUpload={canFixApplication}
        handleSaveImage={handleSaveImage}
        isUploading={isUploading}
        handleDeleteImage={handleDeleteImage}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■備考
        </Heading>
        <InputWrapper>
          <Heading type="sectionTitle" tag="h2">
            修正項目
            <HeadingSubText>※申請者に表示されます</HeadingSubText>
          </Heading>
          <TextareaField>
            <Controller
              control={methods.control}
              name="application.adminRemarks"
              render={({ onChange, value, onBlur }) => (
                <Textarea
                  value={value}
                  onChange={onChange}
                  disabled={!canFixApplication}
                  onBlurCapture={onBlur}
                >
                  {application.adminRemarks}
                </Textarea>
              )}
            />
          </TextareaField>
        </InputWrapper>
        {canFixApplication && (
          <SaveButtonWrapper>
            <Button
              type="button"
              onClick={() => handleAdminRemarksSave()}
              disabled={isAdminRemarksSaving}
            >
              {isAdminRemarksSaving ? <PartialLoader /> : '修正項目保存'}
            </Button>
          </SaveButtonWrapper>
        )}
        <InputWrapper>
          <Heading type="sectionTitle" tag="h2">
            管理者備考
            <HeadingSubText>※管理者のみ閲覧できます</HeadingSubText>
          </Heading>
          <TextareaField>
            <Controller
              control={methods.control}
              name="application.privateAdminRemarks"
              render={({ onChange, value, onBlur }) => (
                <Textarea
                  value={value}
                  onChange={onChange}
                  onBlurCapture={onBlur}
                >
                  {application.privateAdminRemarks}
                </Textarea>
              )}
            />
          </TextareaField>
        </InputWrapper>
        <SaveButtonWrapper>
          <Button
            type="button"
            onClick={() => handlePrivateAdminRemarksSave()}
            disabled={isPrivateAdminRemarksSaving}
          >
            {isPrivateAdminRemarksSaving ? <PartialLoader /> : '備考保存'}
          </Button>
        </SaveButtonWrapper>
        {(application.approvalHistories ?? []).length !== 0 && (
          <InputWrapper>
            <Heading type="sectionTitle" tag="h2">
              承認履歴
            </Heading>
            <ApprovalHistoryArea>
              {application.approvalHistories.map((history, index) => {
                return (
                  <ApprovalHistoryText key={index}>
                    {`・【${getAdminApprovalHistoryLabel(
                      history.approvedStatus,
                    )}】　${toYYYYMMDDHHmm(history.date.toString())}　${
                      history.approverName
                    }`}
                  </ApprovalHistoryText>
                )
              })}
            </ApprovalHistoryArea>
          </InputWrapper>
        )}
      </Section>

      {isTwoStagesApprove && isConfirmerProcess && (
        <Approve1ButtonSection
          role={role}
          isTwoStagesApprove={isTwoStagesApprove}
          setIsApprovalModalOpen={setIsApprovalModalOpen}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
      )}
      {isTwoStagesApprove && isApproverProcess && (
        <AccepteButtonSection
          role={role}
          isSameApprover={isSameApprover}
          isTwoStagesApprove={isTwoStagesApprove}
          setIsApprovalModalOpen={setIsApprovalModalOpen}
          setIsRemandModalOpen={setIsRemandModalOpen}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
      )}

      {!isTwoStagesApprove && isConfirmerProcess && (
        <Approve1ButtonSection
          role={role}
          isTwoStagesApprove={isTwoStagesApprove}
          setIsApprovalModalOpen={setIsApprovalModalOpen}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
      )}
      {!isTwoStagesApprove && isApproverProcess && (
        <Approve2ButtonSection
          role={role}
          setIsApprovalModalOpen={setIsApprovalModalOpen}
          setIsRemandModalOpen={setIsRemandModalOpen}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
      )}
      {!isTwoStagesApprove && isFinalProcess && (
        <AccepteButtonSection
          role={role}
          isSameApprover={isSameApprover}
          isTwoStagesApprove={isTwoStagesApprove}
          setIsApprovalModalOpen={setIsApprovalModalOpen}
          setIsRemandModalOpen={setIsRemandModalOpen}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
      )}

      <ButtonWrapper>
        <BackButton
          type="button"
          onClick={() => {
            const backTo = isMemberApplication
              ? PATH.ADMIN_APPLICATIONS_MEMBER
              : PATH.ADMIN_APPLICATIONS_OFFICE
            const prevSearchQuery = history.location.state?.searchQuery
            const searchQuery =
              typeof prevSearchQuery !== 'undefined' ? prevSearchQuery : ''
            history.push(`${backTo}${searchQuery}`)
          }}
        >
          戻る
        </BackButton>
      </ButtonWrapper>
      <MessageModal
        isOpen={isConfirmModalOpen}
        title={'本当に取消しますか？'}
        closeText={isModalHandlerProcessing ? <PartialLoader /> : 'OK'}
        onClickClose={handleCancel}
        onClickOverlay={() => setIsConfirmModalOpen(false)}
        onPressEscape={() => setIsConfirmModalOpen(false)}
        onClickCancelButton={() => setIsConfirmModalOpen(false)}
        isCloseButtonDisabled={isModalHandlerProcessing}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isCompleteModalOpen}
        title={isCancelOK ? '取消しました' : '取消に失敗しました'}
        description={
          isCancelOK ? undefined : (
            <p>
              しばらく経ってから再度お試しいただくか運営にお問い合わせください。
            </p>
          )
        }
        closeText="OK"
        onClickClose={() => setIsCompleteModalOpen(false)}
        onClickOverlay={() => setIsCompleteModalOpen(false)}
        onPressEscape={() => setIsCompleteModalOpen(false)}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isSaveModalOpen}
        title={isSaveOK ? '保存しました' : '保存に失敗しました'}
        description={
          isSaveOK ? undefined : (
            <p>
              しばらく経ってから再度お試しいただくか運営にお問い合わせください。
            </p>
          )
        }
        closeText="OK"
        onClickClose={() => setIsSaveModalOpen(false)}
        onClickOverlay={() => setIsSaveModalOpen(false)}
        onPressEscape={() => setIsSaveModalOpen(false)}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isApprovalModalOpen}
        title={'承認しますか？'}
        closeText={isModalHandlerProcessing ? <PartialLoader /> : 'OK'}
        onClickClose={handleApproval}
        onClickOverlay={() => setIsApprovalModalOpen(false)}
        onPressEscape={() => setIsApprovalModalOpen(false)}
        onClickCancelButton={() => setIsApprovalModalOpen(false)}
        isCloseButtonDisabled={isModalHandlerProcessing}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isApprovalCompleteModalOpen}
        title={isApprovalOK ? '承認しました' : '承認に失敗しました'}
        description={
          isApprovalOK ? undefined : (
            <p>
              しばらく経ってから再度お試しいただくか運営にお問い合わせください。
            </p>
          )
        }
        closeText="OK"
        onClickClose={() => setIsApprovalCompleteModalOpen(false)}
        onClickOverlay={() => setIsApprovalCompleteModalOpen(false)}
        onPressEscape={() => setIsApprovalCompleteModalOpen(false)}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isRemandModalOpen}
        title={'差戻ししますか？'}
        closeText={isModalHandlerProcessing ? <PartialLoader /> : 'OK'}
        onClickClose={handleRemand}
        onClickOverlay={() => setIsRemandModalOpen(false)}
        onPressEscape={() => setIsRemandModalOpen(false)}
        onClickCancelButton={() => setIsRemandModalOpen(false)}
        isCloseButtonDisabled={isModalHandlerProcessing}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isRemandCompleteModalOpen}
        title={isRemandOK ? '差戻ししました' : '差戻しに失敗しました'}
        description={
          isRemandOK ? undefined : (
            <p>
              しばらく経ってから再度お試しいただくか運営にお問い合わせください。
            </p>
          )
        }
        closeText="OK"
        onClickClose={() => setIsRemandCompleteModalOpen(false)}
        onClickOverlay={() => setIsRemandCompleteModalOpen(false)}
        onPressEscape={() => setIsRemandCompleteModalOpen(false)}
        id="dialog-message"
      />
      <MessageModal
        isOpen={isApprovalStopModalOpen}
        title={'承認できません'}
        description={<p>上長に承認を依頼してください。</p>}
        closeText="OK"
        onClickClose={() => setIsApprovalStopModalOpen(false)}
        onClickOverlay={() => setIsApprovalStopModalOpen(false)}
        onPressEscape={() => setIsApprovalStopModalOpen(false)}
        id="dialog-message"
      />
    </FormProvider>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const SaveButtonWrapper = styled.div`
  margin-top: 40px;
  width: 800px;
  text-align: center;
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`
const InputWrapper = styled.div`
  margin-top: 48px;
`
const TextareaField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin-top: 16px;
`
const ApprovalHistoryArea = styled.div`
  width: 800px;
  margin: 16px 0 80px 0;
`
const ApprovalHistoryText = styled.p`
  font-size: 16px;
`
const HeadingSubText = styled.span`
  font-size: 12px;
`
export default Single
