import React, { useContext, useState } from 'react'
import { OfficeAuthContext } from '../../../auth/OfficeAuthProvider'
import { useHistory } from 'react-router-dom'
import { PATH, CONFIG } from '../../../const'
import { Link } from 'react-router-dom'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import { Button } from '../../ui/Button'
import styled from 'styled-components'
import { PartialLoader } from '../../ui/Loader'

const OfficeLogin: React.FC = () => {
  const { login } = useContext(OfficeAuthContext)
  const history = useHistory()

  const [error, setError] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (e: any) => {
    setButtonDisabled(true)
    e.preventDefault()
    const { officeNumber, password } = e.target.elements
    // 事業所は `事業所番号` をキーにログインする。擬似的にメアドとして扱うため、予め決められたドメインをここで入力値にappendする
    const email = `${officeNumber.value}${CONFIG.OFFICE_NUMBER_SUFFIX_DOMAIN}`
    try {
      await login(email, password.value)
      history.push(PATH.OFFICE_TOP)
    } catch (e) {
      setError('事業所番号かパスワードが間違っています。')
    }
    setButtonDisabled(false)
  }

  return (
    <Wrapper>
      <div>
        <Heading type="screenTitle" tag="h1">
          ＜事業所用＞
          <br />
          ログイン
        </Heading>
        <Section>
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <Input type="text" name="officeNumber" placeholder="事業所番号" />
            </InputWrapper>
            <InputWrapper>
              <Input type="password" name="password" placeholder="パスワード" />
            </InputWrapper>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <ButtonWrapper>
              <Button type="submit" disabled={buttonDisabled}>
                {buttonDisabled ? <PartialLoader /> : 'ログイン'}
              </Button>
            </ButtonWrapper>
          </form>
          <DescriptionWrapper>
            <Heading type="sectionTitle" tag="h2">
              パスワードを忘れた方は事務局までご連絡ください。
              <br />
              会員個別の申請については
              <TextLink to={PATH.LOGIN}>こちら</TextLink>
            </Heading>
          </DescriptionWrapper>
        </Section>
      </div>

      <UnderSection>
        <DescriptionWrapper>
          <Heading type="sectionTitle" tag="h2">
            「文京区勤労者共済会申請システム」については
            <AnchorLink
              href={CONFIG.ABOUT_SHINSEI_SYSTEM_PAGE_URL}
              target="_blank"
            >
              こちら
            </AnchorLink>
            をご確認ください。
          </Heading>
        </DescriptionWrapper>
        <DescriptionWrapper>
          <Heading type="sectionTitle" tag="h2">
            推奨動作環境については
            <AnchorLink
              href={CONFIG.SUPPORTING_ENVIRONMENT_PAGE_URL}
              target="_blank"
            >
              こちら
            </AnchorLink>
            をご確認ください。
          </Heading>
        </DescriptionWrapper>
      </UnderSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 64px;
  text-align: center;

  display: grid;
  grid-template-rows: 1fr auto;
  min-height: calc(100vh - 32px - 64px - 32px);

  @media (max-width: 748px) {
    margin-top: 0;
    min-height: calc(96vh - 32px - 64px - 32px);
  }
`
const Section = styled.section`
  margin-top: 16px;
`
const InputWrapper = styled.div`
  display: block;

  > :nth-child(1) {
    margin-top: 32px;
  }
`
const ButtonWrapper = styled.div`
  margin: 32px 0 16px 0;
`
const DescriptionWrapper = styled.div`
  margin-top: 8px;
`
const TextLink = styled(Link)`
  text-decoration: underline;
  color: #439a89;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`
const UnderSection = styled.section`
  margin-top: 80px;

  @media (max-width: 748px) {
    margin-top: 24px;
  }
`
const ErrorMessage = styled.div`
  color: red;
`
const AnchorLink = styled.a`
  color: #439a89;
  &:hover {
    text-decoration: none;
  }
`
export default OfficeLogin
