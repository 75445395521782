import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { MemberAuthContext } from '../../../auth/MemberAuthProvider'
import { Link } from 'react-router-dom'
import { MemberInfo } from '../../../types/formData'
import { MemberInfoSection } from '../../layout/inputParts/MemberInfoSection'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import { Button } from '../../ui/Button'
import { PATH } from '../../../const'
import { FamilySection } from '../../layout/inputParts/FamilySection'
import { FORM_TYPE_IDS } from '../../../const/formTypeIds'
import { mediaQuery } from '../../../themes/size'

const Account: React.FC = () => {
  const methods = useForm({
    mode: 'onBlur',
  })

  const { loginInfo } = useContext(MemberAuthContext)

  const applicant: MemberInfo = {
    id: loginInfo.user.memberId,
    officeName: loginInfo.office.name,
    officeNameKana: loginInfo.office.nameKana,
    name: loginInfo.user.name,
    nameKana: loginInfo.user.nameKana,
    postalCode: loginInfo.user.postalCode,
    address: loginInfo.user.address,
    tel: loginInfo.user.tel,
    email: loginInfo.user.email,
  }

  useEffect(() => {
    // @fixme 同居家族変更画面の中段の参照のみパーツと同じ使い方をしたいので、ハック的に `previousFamily` を使った
    methods.reset({ ...methods.watch(), previousFamily: loginInfo.user.family })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.reset])

  return (
    <>
      <FormProvider {...methods}>
        <Heading type="sectionTitle" tag="h2">
          ■ {loginInfo.user.name}様（会員番号{loginInfo.user.memberId}）
        </Heading>

        <MemberInfoSection
          applicant={applicant}
          disabled={true}
          isAdmin={false}
        />

        <FamilySection disabled={true} isInput={false} />
        <Heading type="sectionTitle" tag="h2">
          ※同居家族情報を変更する場合は
          <TextLink
            href={`${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE}`}
          >
            こちら
          </TextLink>
        </Heading>

        <Section>
          <Heading type="sectionTitle" tag="h2">
            ■アカウント情報
          </Heading>
          <InputFieldWithButton>
            <HeadingThreeItems type="blockTitle" tag="span">
              メールアドレス
            </HeadingThreeItems>
            <Input type="text" defaultValue={loginInfo.user.email} disabled />
            <ButtonWrapper>
              <ButtonWrapperLink to={`${PATH.ACCOUNT}/email/edit`}>
                <LinkButton>変更</LinkButton>
              </ButtonWrapperLink>
            </ButtonWrapper>
          </InputFieldWithButton>
          <InputFieldWithButton>
            <HeadingThreeItems type="blockTitle" tag="span">
              パスワード
            </HeadingThreeItems>
            <Input type="text" defaultValue="*******" disabled />
            <ButtonWrapper>
              <ButtonWrapperLink to={`${PATH.ACCOUNT}/password/edit`}>
                <LinkButton>変更</LinkButton>
              </ButtonWrapperLink>
            </ButtonWrapper>
          </InputFieldWithButton>
        </Section>
        <BackButtonWrapper>
          <BackButtonLink to={PATH.TOP}>
            <BackButton>戻る</BackButton>
          </BackButtonLink>
        </BackButtonWrapper>
      </FormProvider>
    </>
  )
}

export const Section = styled.section`
  margin-top: 72px;
`
export const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const InputFieldWithButton = styled.div`
  display: grid;
  grid-template-columns: 182px 328px 1fr;
  align-items: center;
  width: 669px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const HeadingThreeItems = styled(Heading)`
  width: 132px;
`
const ButtonWrapper = styled.div`
  margin-left: 64px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    text-align: center;
    margin-top: 16px;
    margin-left: 0%;
  }
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  border-radius: 24px;
  width: 64px;
  height: 32px;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const BackButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const BackButtonLink = styled(Link)`
  text-decoration: none;
`
const BackButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const TextLink = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`
export default Account
