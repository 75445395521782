const API_URL = 'https://madefor.github.io/postal-code-api/api/v1'

async function fetchAddress(postalCode: string): Promise<string> {
  const [pref, autonomy] = postalCode.split('-')
  const endPoint = `${API_URL}/${pref}/${autonomy}.json`
  const res = await fetch(endPoint)
  const json = await res.json()
  if (json.status >= 300) {
    return ''
  }
  const { prefecture, address1, address2, address3, address4 } = json.data[0].ja
  return `${prefecture}${address1}${address2}${address3}${address4}`
}

export { fetchAddress }
