import { UseFormMethods } from 'react-hook-form'
import { toYYYYMMDD } from '../presenter/dateUtil'

export const isZenKatakana = (input: string) =>
  input && !!input.match(/^[ァ-ヶー　]+$/)
export const isPhoneNumber = (input: string) =>
  input && !!input.toString().match(/^[0-9]{10,11}$/)
export const isBankNumber = (input: string) =>
  input && !!input.match(/^[0-9]{7}$/)
export const isOfficeId = (input: string) =>
  input && !!input.match(/^[0-9]{4}$/)
export const isMemberId = (input: string) =>
  input && !!input.match(/^[0-9]{4}-[0-9]{3}$/)
export const isOfficeMemberNumber = (input: string) =>
  input && !!input.match(/^[0-9]{3}$/)
export const isPostalCode = (input: string) =>
  input && !!input.match(/^[0-9]{3}-[0-9]{4}$/)
export const isAmountYen = (input: string) => input && !!input.match(/^[0-9]+$/)
export const isMailAddress = (input: string) =>
  input &&
  !!input.match(
    /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
  ) // https://qiita.com/shimataro999/items/b24bf4def5c4c8fe8ae9
export const isPassword = (input: string) =>
  input && !!input.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,}$/)
export const isCountNumber = (input: string) =>
  input && !!input.match(/^[0-9]+$/)
export const isYYYYMMDD = (input: string) =>
  input &&
  !!toYYYYMMDD(input).match(
    /^(19[0-9]{2}|20[0-9]{2})\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/,
  )

const isNotEmpty = (str?: unknown) => typeof str === 'string' && str.length > 0
const isChangedPaymentMethod = (paymentMethod?: unknown) =>
  paymentMethod !== 'noChange'
export const isNotAllEmpty = <T>(
  methods: UseFormMethods<T>,
  watch: string[],
) => () => {
  const allResult = watch.reduce(
    (acc, val) => acc || isNotEmpty(methods.watch(val)),
    false,
  )
  const paymentMethodResult = isChangedPaymentMethod(
    methods.watch('paymentMethod'),
  )

  if (!allResult && !paymentMethodResult) {
    return false
  } else {
    return true
  }
}
