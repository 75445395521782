import * as React from 'react'
import styled from 'styled-components'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import 'react-datepicker/dist/react-datepicker.css'
import { mediaQuery } from '../../../themes/size'
import { isSafari, isSp } from 'libs/ua'

interface Props {
  dateFormat?: string
  name?: string
  onChange: (value: Date | null) => void
  placeholder?: string
  shouldCloseOnSelect?: boolean
  value?: Date | null
  withSuffix?: boolean
  disabled?: boolean
  minDate?: Date
  showMonthYearPicker?: boolean
  openToDate?: boolean
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void
  maxDate?: Date
}

export const DatePicker: React.FC<Props> = ({
  dateFormat = 'yyyy/MM/dd',
  name,
  placeholder = '',
  shouldCloseOnSelect = true,
  withSuffix,
  value,
  disabled,
  onChange,
  minDate,
  showMonthYearPicker = false,
  openToDate = false,
  onBlur,
  maxDate = new Date(),
}) => {
  const handleChange = (value: Date) => {
    onChange(value)
  }

  const className = withSuffix ? 'has-suffix' : ''
  registerLocale('ja', ja)

  let validValue = undefined
  // @todo 暫定対応。19977年とか、明らかに入力ミスの日付があると画面が白くなるのでここで切る
  // 一時的に未入力と同じ状態にする
  if (typeof value !== 'undefined' && value && typeof value !== typeof Date) {
    const date = new Date(value)
    const validateFrom = Date.parse('1990/1/1')
    const validateTo = Date.parse('2040/1/1')

    if (
      validateFrom < Date.parse(date.toString()) ||
      validateTo > Date.parse(date.toString())
    ) {
      validValue = date
    }
  }

  return (
    <Wrapper>
      <Label>
        <DatePickerComponent
          selected={validValue}
          dateFormat={dateFormat}
          dateFormatCalendar="MMM"
          onChange={handleChange}
          shouldCloseOnSelect={shouldCloseOnSelect}
          placeholderText={placeholder}
          name={name}
          className={className}
          disabled={disabled}
          autoComplete="off"
          locale="ja"
          minDate={minDate}
          showMonthYearPicker={showMonthYearPicker}
          showYearDropdown={true}
          scrollableYearDropdown
          required
          yearDropdownItemNumber={100}
          maxDate={maxDate}
          openToDate={
            openToDate ? (validValue ? validValue : new Date()) : validValue
          }
          onBlur={onBlur}
          withPortal={isSp}
        />
        <Icon></Icon>
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  background-color: #fff;
  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
    > label > div {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
`
const DatePickerComponent = styled(ReactDatePicker)`
  flex-grow: 1;
  display: inline-block;
  width: 328px;
  border: none;
  line-height: 1.6;
  outline: none;
  box-sizing: border-box;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  font-size: 16px;
  :disabled {
    background: rgb(190, 190, 190);
  }
`
const Icon = styled(CalendarTodayIcon)`
  position: absolute;
  top: 16px;
  right: 8px;
  transition: 0.3s;
  color: #aaaaaa;
  cursor: pointer;
  :disabled {
    cursor: default;
  }
`
const Label = styled.label`
  .react-datepicker {
    font-size: 0.9rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.4rem;
    line-height: 2.4rem;
  }

  .react-datepicker__portal {
    ${isSafari &&
    `
      /* The hack for Safari */
      height: -webkit-fill-available;
    `}
  }
`
