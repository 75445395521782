import { FormType } from '../types/formType'

// ユーザが新規で行える申請のすべて
// 申請フォーム（項目・種別など）自体はDBで持たないため、これをマスタとして扱う
export const FORM_TYPE_IDS = {
  // 会員
  SHUKUHAKURIYOUHOJOKIN: 'shukuhakuriyouhojokin',
  SHIBOUCHOUIKIN: 'shibouchouikin',
  SHOUGAIMIMAIKIN: 'shougaimimaikin',
  JUTAKUSAIGAIMIMAIKIN: 'jutakusaigaimimaikin',
  NYUINMIMAIKIN: 'nyuinmimaikin',
  KEKKONGINKONKINKON: 'kekkonginkonkinkon',
  SHUSSAN: 'shussan',
  SHUGAKUIWAIKIN: 'shugakuiwaikin',
  SEIJINIWAIKIN: 'seijiniwaikin',
  KAIINJOUHOU_HENKOUTODOKE: 'kaiinjouhou_henkoutodoke',
  DOUKYOKAZOKU_HENKOUTODOKE: 'doukyokazoku_henkoutodoke',
  // 事業所
  OFFICE_TEIKIKENKOSHINDAN_JOSEIKIN: 'office_teikikenkoshindan_joseikin',
  OFFICE_KAIINJOUHOU_HENKOUTODOKE: 'office_kaiinjouhou_henkoutodoke',
  OFFICE_ICHIBUTAIKAITODOKE: 'office_ichibutaikaitodoke',
} as const

// 二段承認の申請
export const TWO_STAGE_APPROVALS: FormType[] = [
  FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE,
  FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE,
  FORM_TYPE_IDS.OFFICE_ICHIBUTAIKAITODOKE,
  FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE,
]
