import encoding from 'encoding-japanese'

const download = (content: string, filename?: string) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  const code = encoding.stringToCode(content)
  const sjis = encoding.convert(code, 'SJIS')
  const uint8Array = new Uint8Array(sjis)
  const blob = new Blob([uint8Array], { type: 'text/css;charset=SJIS' })
  const url = window.URL.createObjectURL(blob)
  a.href = url
  if (filename) {
    a.download = filename
  }
  a.click()
  window.URL.revokeObjectURL(url)
  // @ts-ignore
  a.parentNode.removeChild(a)
}

export default download
