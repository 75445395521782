// カテゴリとフォーム名をハイフンつなぎで返却する
import {
  userCategorisedForms,
  officeCategorisedForms,
} from '../data/categorisedForms'
import { FormType } from '../types/formType'
import { CategorisedForm } from '../types/categorisedForm'

export const getStructuredLabel = (formType: FormType) => {
  const target = getCategoryAndForm(formType)
  return `${target.categoryName} - ${target.formName}`
}

export const getCategoryAndForm = (
  formType: FormType,
): { categoryName: string; formName: string } => {
  const categorisedForm = userCategorisedForms.concat(officeCategorisedForms)
  const targetCategory = categorisedForm.filter((category) => {
    let targetCategory: CategorisedForm | null = null
    category.forms.forEach((form) => {
      if (form.type.indexOf(formType) !== -1) {
        targetCategory = category
      }
    })
    return targetCategory
  })[0]
  const targetForm = targetCategory.forms.filter(
    (form) => formType === form.type,
  )[0] // idで絞っているので1つだけ合致する
  return {
    categoryName: targetCategory.name,
    formName: targetForm.name,
  }
}
