import styled from 'styled-components'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

const style = `
  position: absolute;
  top: 10px;
  right: 10px;
  transition: 0.3s;
  color: #439a89;
`

const OpenIcon = styled(ExpandLess)`
  ${style}
`
const CloseIcon = styled(ExpandMore)`
  ${style}
`

export { OpenIcon, CloseIcon }
