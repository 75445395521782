import React, { FC } from 'react'
import styled from 'styled-components'

import { RadioButton, Props as RadioButtonProps } from '../RadioButton'

type Props = RadioButtonProps & {
  label: string
}

export const RadioButtonLabel: FC<Props> = ({
  label,
  className = '',
  ...props
}) => {
  return (
    <Wrapper className={className}>
      <Label className={`${props.disabled ? 'disabled' : ''}`}>
        <RadioButton {...props} />
        <Txt>{label}</Txt>
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
`
const Label = styled.label`
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  &.disabled {
    color: #726f6f;
    cursor: default;
  }
`
const Txt = styled.span`
  margin: 0 0 0 16px;
  font-size: 16px;
`
