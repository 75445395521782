import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { MemberInfoSection } from '../../layout/inputParts/MemberInfoSection'
import { Heading } from '../../ui/Heading'
import { Button } from '../../ui/Button'
import { MessageModal } from '../../ui/Modal'
import { API, PATH } from '../../../const'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { MemberInfo, FormDataInterface } from '../../../types/formData'
import { useMultiStepState } from '../../../hooks/useMultiStepState'
import { PartialLoader } from '../../ui/Loader'
import { Section } from '../applications/form/SeijinIwaiKin'
import { BrowserBackWarning } from '../../util'

const Component: React.FC = () => {
  const initData: MemberInfo = {
    id: '',
    officeName: '',
    officeNameKana: '',
    name: '',
    nameKana: '',
    postalCode: '',
    address: '',
    tel: '',
    email: '',
  }

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initData,
  })

  const history = useHistory()

  const apiPath = `${API.ADMIN_MEMBERS}/`

  const handleConfirm = async (): Promise<boolean> => {
    document?.querySelector('main')?.scrollTo(0, 0)
    return await methods.trigger()
  }

  const handleSend = async (): Promise<void> => {
    await methods.handleSubmit(async (data: FormDataInterface['formData']) => {
      const requestBody = data
      const res = await fetch(apiPath, {
        headers: { Authorization: 'Bearer ' + bearerToken },
        method: 'post',
        mode: 'cors',
        body: JSON.stringify(requestBody),
      })
      if (res.status >= 400) {
        setIsComplete(false)
        return
      }
      methods.reset()
    })()
  }

  const formState = useMultiStepState()
  const shouldFormDisabled = formState.currentStep === 'confirm'

  const { bearerToken } = useContext(AdminAuthContext)

  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(true)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleConfirmClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    const isValid = await handleConfirm()
    if (isValid) {
      formState.nextStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
    setButtonDisabled(false)
  }

  const handleSendClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setButtonDisabled(true)
    e.preventDefault()
    await handleSend()
    setIsCompleteModalOpen(true)
    setButtonDisabled(false)
  }

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (formState.currentStep === 'input') {
      history.push(PATH.ADMIN_MEMBERS)
    } else if (formState.currentStep === 'confirm') {
      formState.prevStep()
      document?.querySelector('main')?.scrollTo(0, 0)
    }
  }

  const handleChangeMemberId = (): void => {
    const inputMemberId = methods.watch('id')
    const pattern = /^\d{4}-?\d{3}$/g
    if (!inputMemberId.match(new RegExp(pattern))) {
      methods.setValue('officeName', '')
      methods.setValue('officeNameKana', '')
      return
    }
    const officeId = inputMemberId.split('-')[0]
    const officeSingleApiPath = `${API.ADMIN_OFFICES}/${officeId}`
    fetch(officeSingleApiPath, {
      headers: { Authorization: 'Bearer ' + bearerToken },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((office) => {
        methods.setValue('officeName', office.name)
        methods.setValue('officeNameKana', office.nameKana)
      })
      .catch(() => {
        methods.setValue('officeName', '')
        methods.setValue('officeNameKana', '')
      })
  }

  const handleModalClose = (): void => {
    setIsCompleteModalOpen(false)
    if (isComplete) {
      const memberId = methods.watch('id')
      history.push(`${PATH.ADMIN_MEMBERS}/${memberId}`)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <BrowserBackWarning
          isDirty={methods.formState.isDirty}
          message="会員が作成されていません。本当に戻りますか？"
        />
        <Heading type="screenTitle" tag="h1">
          新規登録
        </Heading>

        {Object.keys(methods.errors).length > 0 && (
          <Section>
            <ErrorMessage>
              入力内容に不備があります。各入力項目のメッセージを参照してください。
            </ErrorMessage>
          </Section>
        )}

        <MemberInfoSection
          applicant={initData}
          currentStep={formState.currentStep}
          disabled={shouldFormDisabled}
          isAdmin={true}
          isActive={false}
          handleChangeMemberId={handleChangeMemberId}
        />

        <ButtonWrapper>
          {formState.currentStep === 'input' && (
            <Button
              type="button"
              onClick={handleConfirmClick}
              disabled={buttonDisabled}
            >
              確認
            </Button>
          )}
          {formState.currentStep === 'confirm' && (
            <Button
              type="button"
              onClick={handleSendClick}
              disabled={buttonDisabled}
            >
              {buttonDisabled ? <PartialLoader /> : '登録'}
            </Button>
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          <BackButton type="button" onClick={handleBackClick}>
            戻る
          </BackButton>
        </ButtonWrapper>
        <MessageModal
          isOpen={isCompleteModalOpen}
          title={isComplete ? '登録しました' : '登録に失敗しました'}
          description={
            isComplete ? undefined : (
              <p>しばらく経ってから再度お試しください。</p>
            )
          }
          closeText="OK"
          onClickClose={handleModalClose}
          onClickOverlay={handleModalClose}
          onPressEscape={handleModalClose}
          id="dialog-message"
        />
      </FormProvider>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const BackButton = styled(Button)`
  border: 1px solid #439a89;
  background-color: #fff;
  color: #000;
  font-weight: normal;
`
const ErrorMessage = styled.div`
  color: red;
`

export default Component
