import { FORM_TYPE_IDS } from '../const/formTypeIds'
import { CategorisedForms } from '../types/categorisedForm'
import { CONFIG } from '../const'
import { PATH } from '../const'

export const userCategorisedForms: CategorisedForms = [
  {
    name: '給付金申請',
    description:
      '会員に給付事由が生じたとき、申請を行うことで給付金が支給されます。',
    aboutLink: CONFIG.KYUFUKIN_SHOSAI_URL,
    forms: [
      {
        type: FORM_TYPE_IDS.SHIBOUCHOUIKIN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.SHIBOUCHOUIKIN}`,
        name: '死亡弔慰金',
      },
      {
        type: FORM_TYPE_IDS.SHOUGAIMIMAIKIN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.SHOUGAIMIMAIKIN}`,
        name: '障害見舞金',
      },
      {
        type: FORM_TYPE_IDS.NYUINMIMAIKIN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.NYUINMIMAIKIN}`,
        name: '入院見舞金',
      },
      {
        type: FORM_TYPE_IDS.JUTAKUSAIGAIMIMAIKIN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.JUTAKUSAIGAIMIMAIKIN}`,
        name: '住宅災害見舞金',
      },
      {
        type: FORM_TYPE_IDS.KEKKONGINKONKINKON,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.KEKKONGINKONKINKON}`,
        name: '結婚・銀婚・金婚祝金',
      },
      {
        type: FORM_TYPE_IDS.SHUSSAN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.SHUSSAN}`,
        name: '出産祝金',
      },
      {
        type: FORM_TYPE_IDS.SHUGAKUIWAIKIN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.SHUGAKUIWAIKIN}`,
        name: '小中就学祝金',
      },
      {
        type: FORM_TYPE_IDS.SEIJINIWAIKIN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.SEIJINIWAIKIN}`,
        name: '成人祝金',
      },
    ],
  },
  {
    name: '宿泊施設利用補助金',
    description:
      '会員とその家族を対象に宿泊施設利用補助金の助成を行っています。',
    aboutLink: CONFIG.SHUKUHAKU_SHOSAI_URL,
    forms: [
      {
        type: FORM_TYPE_IDS.SHUKUHAKURIYOUHOJOKIN,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.SHUKUHAKURIYOUHOJOKIN}`,
        name: '宿泊施設利用補助金',
      },
    ],
  },
  {
    name: '会員情報変更',
    description:
      '氏名、住所、電話番号、同居家族の追加など、会員情報の変更が可能です',
    forms: [
      {
        type: FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.KAIINJOUHOU_HENKOUTODOKE}`,
        name: '変更届（会員情報）',
      },
      {
        type: FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE,
        link: `${PATH.APPLICATIONS_NEW}/${FORM_TYPE_IDS.DOUKYOKAZOKU_HENKOUTODOKE}`,
        name: '変更届（同居家族）',
      },
    ],
  },
]

export const officeCategorisedForms: CategorisedForms = [
  {
    name: '助成金申請',
    description:
      '労働安全衛生法により、義務付けられている定期健康診断助成の助成をします。',
    aboutLink: CONFIG.JOSEIKIN_SHOSAI_URL,
    forms: [
      {
        type: FORM_TYPE_IDS.OFFICE_TEIKIKENKOSHINDAN_JOSEIKIN,
        link: `${PATH.OFFICE_APPLICATIONS_NEW}/${FORM_TYPE_IDS.OFFICE_TEIKIKENKOSHINDAN_JOSEIKIN}`,
        name: '定期健康診断助成金申請',
      },
    ],
  },
  {
    name: '登録情報変更届',
    description:
      '事業所情報の変更や、会員の一部退会届など、事業者情報の変更の申請ができます。',
    forms: [
      {
        type: FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE,
        link: `${PATH.OFFICE_APPLICATIONS_NEW}/${FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE}`,
        name: '変更届',
      },
      {
        type: FORM_TYPE_IDS.OFFICE_ICHIBUTAIKAITODOKE,
        link: `${PATH.OFFICE_APPLICATIONS_NEW}/${FORM_TYPE_IDS.OFFICE_ICHIBUTAIKAITODOKE}`,
        name: '一部退会届',
      },
    ],
  },
]
