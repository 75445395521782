import firebase from 'firebase/app'
import 'firebase/functions'
import React, { createContext, useEffect, useState } from 'react'
import app from '../base'
import { API, PATH } from '../const'
import { AdminLoginInfo } from '../types/AdminLoginInfo'
import { Redirect, useLocation } from 'react-router-dom'
import { toAdminLoginInfo } from '../mapper/response'
import { PageLoader } from '../components/ui/Loader'

type AuthContextType = {
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  bearerToken: string
  loginInfo: AdminLoginInfo
}

const login = async (email: string, password: string): Promise<void> => {
  await app.auth().signInWithEmailAndPassword(email, password)
}

const logout = async (): Promise<void> => {
  try {
    await app.auth().signOut()
  } catch (error) {
    console.error(error)
  }
}

const initialValue: AuthContextType = {
  login,
  logout,
  bearerToken: '',
  loginInfo: {
    uid: '',
    admin: {
      adminId: '',
      email: '',
      name: '',
      role: 'confirmer',
    },
  },
}

export const AdminAuthContext = createContext<AuthContextType>(initialValue)

export const AdminAuthProvider: React.FC = (props) => {
  const currentPath = useLocation().pathname

  // フロントで直接firebase authentication を照会して、セッションがなかったらリダイレクトしたいのでここで状態を持つ
  const [loadState, setLoadState] = useState<'loading' | 'noUser' | 'loggedIn'>(
    'loading',
  )
  const [loginInfo, setLoginInfo] = useState<AdminLoginInfo | null>()

  useEffect(() => {
    const unsubscribed = app.auth().onAuthStateChanged(async (user) => {
      setLoginInfo(undefined)
      if (!user) {
        setLoadState('noUser')
      } else {
        setLoadState('loggedIn')
      }
    })
    return () => {
      unsubscribed()
    }
  }, [])

  const [bearerToken, setBearerToken] = useState<string>('')
  const [isNotAdminUser, setIsNotAdminUser] = useState<boolean>(false)
  useEffect(() => {
    switch (loadState) {
      case 'loading':
        // nothing to do (still undefined)
        break
      case 'noUser':
        setLoginInfo(null)
        break
      case 'loggedIn':
        ;(async () => {
          const token = await firebase.auth().currentUser?.getIdToken()
          if (typeof token === 'undefined') {
            return
          }
          setBearerToken(token)
          const json = await fetch(API.ADMIN_ABOUT_ME, {
            headers: { Authorization: 'Bearer ' + token },
            mode: 'cors',
          }).then((res) => res.json())
          if (!json.admin.role) {
            setIsNotAdminUser(true)
          } else {
            setLoginInfo(toAdminLoginInfo(json))
          }
          return
        })()
        break
      default:
        // https://zenn.dev/suin/scraps/9f3cfc934e98fe#comment-a39a91c1d4a4e9
        throw new Error(`Unexpected state: ${loadState}`)
    }
  }, [loadState])

  if (typeof loginInfo === 'undefined') {
    if (isNotAdminUser) {
      return <Redirect to={PATH.LOGIN} />
    }
    return <PageLoader />
  }

  // ログインしていないユーザの場合
  if (loginInfo === null) {
    if (PATH.NO_LOGIN_PATHS.includes(currentPath)) {
      // ログイン不要の画面にアクセスしてきたらそのまま表示して差し上げる
      return <>{props.children}</>
    } else {
      // ログイン必須画面に非ログインでアクセスしてきたら /login にリダイレクト
      return <Redirect to={PATH.ADMIN_LOGIN} />
    }
  }

  // ログインしてんのに非ログイン前提の画面にアクセスしてきたらトップにリダイレクトする
  if (PATH.NO_LOGIN_PATHS.includes(currentPath)) {
    return <Redirect to={PATH.ADMIN_TOP} />
  }

  return (
    <AdminAuthContext.Provider
      value={{
        login,
        logout,
        bearerToken,
        loginInfo: loginInfo,
      }}
    >
      {props.children}
    </AdminAuthContext.Provider>
  )
}
