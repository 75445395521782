import React, { FC, InputHTMLAttributes, useState } from 'react'
import styled from 'styled-components'
import { mediaQuery } from 'themes/size'
import { FileResponseType } from '../../../types/formData'
import { PartialLoader } from '../Loader'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  id?: string
  className?: string
  label: string
  files?: File[]
  fileResponse?: FileResponseType
  onAdd?: (addFiles: File[]) => void
  onDelete?: (index: number) => Promise<void>
  hasFileList?: boolean
  canAdminUpload?: boolean
  isUploading?: boolean
}

export const InputFile: FC<Props> = ({
  id,
  className,
  size = 'default',
  label,
  files = [],
  hasFileList = true,
  fileResponse = [],
  onAdd,
  onDelete,
  disabled = false,
  canAdminUpload,
  isUploading,
  ...props
}) => {
  const FileButtonWrapperClassName = `${disabled ? 'disabled' : ''}`
  const FileButtonClassName = `${size}`

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onAdd && e.target.files && e.target.files?.length > 0) {
      const uploadFile = Array.from(e.target.files)
      onAdd(uploadFile)

      const target = e.target as HTMLInputElement
      target.value = ''
    }
  }

  const handleDelete = async (index: number) => {
    onDelete && (await onDelete(index))
  }

  return (
    <Wrapper className={className}>
      {!canAdminUpload && hasFileList && files.length > 0 && (
        <FileList>
          {files.map((file, index) => {
            return (
              <li key={Math.random()}>
                <ImageLink href={URL.createObjectURL(file)} target="_blank">
                  {file.name}
                </ImageLink>
                <span>
                  {!disabled && (
                    <DeleteButtonComponent
                      onClick={() => handleDelete(index)}
                    />
                  )}
                </span>
              </li>
            )
          })}
        </FileList>
      )}

      {disabled && fileResponse.length > 0 && (
        <FileList>
          {fileResponse.map((file, index) => {
            return (
              <li key={Math.random()}>
                <ImageLink href={file.url} target="_blank">
                  {file.name}
                </ImageLink>
                {canAdminUpload && file.ownerType === 'admin' && (
                  <span>
                    <DeleteButtonComponent
                      onClick={() => handleDelete(index)}
                    />
                  </span>
                )}
              </li>
            )
          })}
        </FileList>
      )}

      {!disabled && (
        <FileButtonWrapper className={FileButtonWrapperClassName}>
          <FileInput
            {...props}
            type="file"
            id={id}
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            tabIndex={-1}
          />
          <FileButton
            className={FileButtonClassName}
            disabled={disabled}
            type="button"
          >
            <label htmlFor={id}>
              <Prefix></Prefix>
              {label}
            </label>
          </FileButton>
        </FileButtonWrapper>
      )}

      {canAdminUpload && (
        <FileButtonWrapper className={FileButtonWrapperClassName}>
          <FileInput
            {...props}
            type="file"
            id={id}
            onChange={(e) => handleChange(e)}
            tabIndex={-1}
            disabled={isUploading}
          />
          <FileButton className={FileButtonClassName} type="button">
            <label htmlFor={id}>
              <Prefix></Prefix>
              {isUploading ? <PartialLoader /> : label}
            </label>
          </FileButton>
        </FileButtonWrapper>
      )}
    </Wrapper>
  )
}

// TODO: 共通にできるスタイルはテーマに持たせたい

const Wrapper = styled.div``

const FileList = styled.ul`
  font-size: 16px;
  color: #000;
  padding: 0px 24px;
  margin-bottom: 16px;
  list-style: none;
  > li {
    display: flex;
    align-items: center;
  }
`

const FileButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 450px;
  margin: 0 auto;
  text-align: center;
  > input[type='file'] {
    position: absolute;
    height: 100%;
    left: -10px;
    top: 0;
    margin: 0;
    font-size: 128px !important;
    opacity: 0;
    appearance: none;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  &:hover {
    > button {
      background-color: rgba(67, 154, 137, 0.5);
      color: rgb(255, 255, 255);
    }
  }

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`

const FileButton = styled.button`
  font-family: inherit;
  font-weight: bold;
  border-radius: 27.5px;
  color: #439a89;
  background-color: #fff;
  border: solid 1px #439a89;
  font-size: 16px;
  height: 40px;
  padding: 0 46px;
  transition: all 0.3s ease-out 0s;
  > label {
    display: flex;
    align-items: center;
  }
  &.prefix {
    justify-content: left;
  }
`
const ImageLink = styled.a`
  color: #439a89;
  &:hover {
    text-decoration: none;
  }
`
const Prefix = styled.span`
  display: inline-flex;
`
const FileInput = styled.input`
  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`

const DeleteButtonComponent: React.VFC<JSX.IntrinsicElements['button']> = (
  props,
) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const { onClick, ...rest } = props
  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setIsDeleting(true)
    onClick && (await onClick(e))
    setIsDeleting(false)
  }
  const DeleteButton = styled.button`
    margin-left: 8px;
    font-weight: bold;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    background-color: transparent;
    padding: 0 16px;
    height: 45px;
    transition: all 0.3s ease-out 0s;
    &:hover {
      background-color: rgb(242, 242, 242);
    }
  `
  return (
    <DeleteButton
      onClick={handleClick}
      disabled={isDeleting}
      {...{ rest }}
      type="button"
    >
      {isDeleting ? <PartialLoader /> : '削除'}
    </DeleteButton>
  )
}
