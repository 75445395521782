import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../../ui/Button'

type Props = {
  role: 'finalApprover' | 'approver' | 'confirmer'
  isTwoStagesApprove: boolean
  setIsApprovalModalOpen: (isOpen: boolean) => void
  setIsConfirmModalOpen: (isOpen: boolean) => void
}

export const Approve1ButtonSection: React.FC<Props> = (props) => {
  return (
    <>
      <ButtonWrapper>
        <Button
          type="button"
          onClick={() => props.setIsApprovalModalOpen(true)}
          disabled={
            (props.role === 'approver' || props.role === 'finalApprover') &&
            !props.isTwoStagesApprove
          }
        >
          承認1
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <CancelButton
          type="button"
          onClick={() => props.setIsConfirmModalOpen(true)}
          disabled={
            (props.role === 'approver' || props.role === 'finalApprover') &&
            !props.isTwoStagesApprove
          }
        >
          取消
        </CancelButton>
      </ButtonWrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const CancelButton = styled(Button)`
  background-color: #ea1c1c;
  &:hover {
    background-color: rgba(254, 57, 50, 0.5);
    color: rgb(255, 255, 255);
  }
`
