import React, { useContext, useState } from 'react'
import { AdminAuthContext } from '../../../auth/AdminAuthProvider'
import { useHistory } from 'react-router-dom'
import { PATH } from '../../../const'
import { Heading } from '../../ui/Heading'
import { Input } from '../../ui/Input'
import { Button } from '../../ui/Button'
import styled from 'styled-components'
import { PartialLoader } from '../../ui/Loader'

const AdminLogin: React.FC = () => {
  const { login } = useContext(AdminAuthContext)
  const history = useHistory()

  const [error, setError] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (e: any) => {
    setButtonDisabled(true)
    e.preventDefault()
    const { email, password } = e.target.elements
    try {
      await login(email.value, password.value)
      history.push(PATH.ADMIN_TOP)
    } catch (e) {
      setError('メールアドレスかパスワードが間違っています。')
    }
    setButtonDisabled(false)
  }

  return (
    <Wrapper>
      <Heading type="screenTitle" tag="h1">
        ＜管理者用＞
      </Heading>
      <Section>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input type="email" name="email" placeholder="メールアドレス" />
          </InputWrapper>
          <InputWrapper>
            <Input type="password" name="password" placeholder="パスワード" />
          </InputWrapper>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ButtonWrapper>
            <Button type="submit" disabled={buttonDisabled}>
              {buttonDisabled ? <PartialLoader /> : 'ログイン'}
            </Button>
          </ButtonWrapper>
        </form>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 64px;
  text-align: center;
`
const Section = styled.section`
  margin-top: 16px;
`
const InputWrapper = styled.div`
  display: block;

  > :nth-child(1) {
    margin-top: 32px;
  }
`
const ButtonWrapper = styled.div`
  margin: 32px 0 16px 0;
`
const ErrorMessage = styled.div`
  color: red;
`

export default AdminLogin
