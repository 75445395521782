import firebase from 'firebase/app'
import 'firebase/functions'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { PATH } from '../../../../const'
import { MemberAuthContext } from '../../../../auth/MemberAuthProvider'
import { Heading } from '../../../ui/Heading'
import { Button } from '../../../ui/Button'
import { Input } from '../../../ui/Input'
import { MessageModal } from '../../../ui/Modal'
import { PartialLoader } from '../../../ui/Loader'
import { mediaQuery } from '../../../../themes/size'

const ChangePasswordForm: React.FC = () => {
  const history = useHistory()
  const { login, loginInfo } = useContext(MemberAuthContext)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (e: any) => {
    setButtonDisabled(true)
    e.preventDefault()
    const {
      currentPassword,
      newPassword,
      newPasswordConfirm,
    } = e.target.elements
    try {
      // 再ログインで擬似的に現在のパスワード確認
      await login(loginInfo.user.email, currentPassword.value)
    } catch (e) {
      setError('現在のパスワードが間違っているため変更できませんでした。')
      setButtonDisabled(false)
      return
    }
    if (newPassword.value !== newPasswordConfirm.value) {
      setError(
        '確認のため入力していただいたパスワードが一致しないため変更できませんでした。',
      )
      setButtonDisabled(false)
      return
    }
    if (newPassword.value.length < 8) {
      setError('パスワードは8文字以上で設定してください')
      setButtonDisabled(false)
      return
    }
    try {
      await firebase.auth().currentUser?.updatePassword(newPassword.value)
      setIsModalOpen(true)
      setButtonDisabled(false)
    } catch (e) {
      setError(
        'パスワードが変更できませんでした。しばらく時間をおいてから再度お試しください。',
      )
      console.error(e)
      setButtonDisabled(false)
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    history.push(PATH.ACCOUNT)
  }

  return (
    <>
      <Heading type="sectionTitle" tag="h2">
        ■パスワードの変更
      </Heading>

      <form onSubmit={handleSubmit}>
        <InputField>
          <InputHeading type="blockTitle" tag="span">
            現在のパスワード
          </InputHeading>
          <Input type="password" name="currentPassword" />
        </InputField>
        <InputField>
          <InputHeading type="blockTitle" tag="span">
            新しいパスワード
            <br />
            <Paragraph>※8 文字以上で入力してください</Paragraph>
          </InputHeading>
          <Input type="password" name="newPassword" />
        </InputField>
        <InputField>
          <InputHeading type="blockTitle" tag="span">
            新しいパスワード
            <br />
            （確認）
          </InputHeading>
          <Input type="password" name="newPasswordConfirm" />
        </InputField>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonWrapper>
          <Button type="submit" disabled={buttonDisabled}>
            {buttonDisabled ? <PartialLoader /> : '送信'}
          </Button>
        </ButtonWrapper>
        <BackButtonWrapper>
          <ButtonWrapperLink to={PATH.ACCOUNT}>
            <LinkButton>戻る</LinkButton>
          </ButtonWrapperLink>
        </BackButtonWrapper>
      </form>
      <MessageModal
        isOpen={isModalOpen}
        title={'パスワード変更が完了しました。'}
        description={<span></span>}
        closeText="OK"
        onClickClose={handleModalClose}
        onClickOverlay={handleModalClose}
        onPressEscape={handleModalClose}
        id="dialog-message"
      />
    </>
  )
}

const InputHeading = styled(Heading)`
  width: 160px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    width: 100%;
  }
`
const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 496px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ButtonWrapper = styled.div`
  margin-top: 103px;
  text-align: center;
`
const BackButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`
const LinkButton = styled(Button)`
  font-weight: normal;
  color: #439a89;
  border: 1px solid #439a89;
  box-sizing: border-box;
  background-color: #fff;
`
const ButtonWrapperLink = styled(Link)`
  text-decoration: none;
`
const ErrorMessage = styled.div`
  color: red;
`
const Paragraph = styled.span`
  font-size: 12px;
`
export default ChangePasswordForm
