import React from 'react'
import { Heading } from '../../../../ui/Heading'
import { Input } from '../../../../ui/Input'
import { InputField, Section } from '../../../applications/form/ShougaiMimaiKin'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import { PATH } from '../../../../../const'
import { FORM_TYPE_IDS } from '../../../../../const/formTypeIds'
import { convertPaymentMethod } from '../../../../layout/inputParts/OfficeInfoSection'
import { useParams } from 'react-router-dom'
import { ParamType } from '../../Create'

type Props = {
  shouldShowOtherFormLinks?: true
}

export const OfficeInfoSection: React.FC<Props> = (props) => {
  const { formType } = useParams<ParamType>()

  const { control } = useFormContext()
  return (
    <Section>
      <Heading type="sectionTitle" tag="h2">
        ■事業所情報
      </Heading>
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所番号
        </Heading>
        <Controller
          control={control}
          name="officeInfo.officeId"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所名
        </Heading>
        <Controller
          control={control}
          name="officeInfo.name"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
        </Heading>
        <Controller
          control={control}
          name="officeInfo.nameKana"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          代表者名
        </Heading>
        <Controller
          control={control}
          name="officeInfo.representativeName"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
        </Heading>
        <Controller
          control={control}
          name="officeInfo.representativeNameKana"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          事務担当者
        </Heading>
        <Controller
          control={control}
          name="officeInfo.clerkName"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          フリガナ
        </Heading>
        <Controller
          control={control}
          name="officeInfo.clerkNameKana"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          事業所郵便番号
        </Heading>
        <Controller
          control={control}
          name="officeInfo.postalCode"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          所在地
        </Heading>
        <Controller
          control={control}
          name="officeInfo.address"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          電話番号
        </Heading>
        <Controller
          control={control}
          name="officeInfo.tel"
          render={({ value }) => (
            <Input type="text" defaultValue={value} disabled />
          )}
        />
      </InputField>
      <InputField>
        <Heading type="blockTitle" tag="span">
          会費振替方法
        </Heading>
        <Controller
          control={control}
          name="officeInfo.paymentMethod"
          render={({ value }) => (
            <Input
              type="text"
              defaultValue={convertPaymentMethod(value)}
              disabled
            />
          )}
        />
      </InputField>
      {props.shouldShowOtherFormLinks &&
        formType !== FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE && (
          <Heading type="sectionTitle" tag="h2">
            ※事業所情報を変更する場合は
            <Link
              href={`${PATH.OFFICE_APPLICATIONS_NEW}/${FORM_TYPE_IDS.OFFICE_KAIINJOUHOU_HENKOUTODOKE}`}
            >
              こちら
            </Link>
          </Heading>
        )}
    </Section>
  )
}

const Link = styled.a`
  text-decoration: underline;
  color: #439a89;
  margin-top: 32px;
  display: inline-block;
  font-size: 16px;
  &:hover {
    text-decoration: none;
  }
`
