import React from 'react'
import styled from 'styled-components'

import { SeijinIwaiKinData } from '../../../../types/formData'

import { Heading } from '../../../ui/Heading'
import { Input } from '../../../ui/Input'
import { DatePicker } from '../../../ui/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'
import {
  BankAccountSection,
  FileSection,
  MemberInfoSection,
} from './inputParts'
import { MessageColumnSection } from '../../../layout/inputParts/MessageColumnSection'
import { mediaQuery } from '../../../../themes/size'
import { isYYYYMMDD } from '../../../../util/validation'

type Props = {
  disabled: boolean
  canAdminUpload?: boolean
  handleSaveImage?: (files: File) => Promise<void>
  isUploading?: boolean
  handleDeleteImage?: (fileId: string) => Promise<void>
  shouldShowOtherFormLinks?: true
}

const Component: React.FC<Props> = (props) => {
  const methods = useFormContext<SeijinIwaiKinData['formData']>()
  const { control, errors } = methods

  return (
    <>
      <HeadingArea>
        <Heading type="screenTitle" tag="h1">
          ▼給付金申請 - 成人祝金
        </Heading>
      </HeadingArea>

      {Object.keys(errors).length > 0 && (
        <Section>
          <ErrorMessage>
            入力内容に不備があります。各入力項目のメッセージを参照してください。
          </ErrorMessage>
        </Section>
      )}

      <MemberInfoSection
        shouldShowOtherFormLinks={props.shouldShowOtherFormLinks}
      />

      <Section>
        <Heading type="sectionTitle" tag="h2">
          ■申請情報
        </Heading>
        <InputField>
          <Heading type="blockTitle" tag="span">
            生年月日
          </Heading>
          <Controller
            control={control}
            name="birthDay"
            rules={{
              required: '生年月日を入力してください',
              validate: { isYYYYMMDD },
            }}
            render={({ value, onChange, onBlur }) => (
              <DatePicker
                onChange={onChange}
                value={value}
                disabled={props.disabled}
                openToDate={true}
                onBlur={onBlur}
              />
            )}
          />
        </InputField>
        {errors.birthDay && (
          <ErrorMessage>{errors.birthDay.message}</ErrorMessage>
        )}
        {errors.birthDay?.type === 'isYYYYMMDD' && (
          <ErrorMessage>YYYY/MM/DDの形式で入力して下さい</ErrorMessage>
        )}
        <InputField>
          <Heading type="blockTitle" tag="span">
            請求金額
          </Heading>
          <LabelWrapper>
            <Controller
              control={control}
              name="amount"
              defaultValue="10,000"
              render={({ value }) => (
                <Input type="text" value={value} disabled />
              )}
            />
            <InputLabel>円</InputLabel>
          </LabelWrapper>
        </InputField>
      </Section>

      <FileSection
        headingLabel="年齢を確認できる免許証/保険証"
        disabled={props.disabled}
        canAdminUpload={props.canAdminUpload}
        handleSaveImage={props.handleSaveImage}
        isUploading={props.isUploading}
        handleDeleteImage={props.handleDeleteImage}
      />

      <BankAccountSection disabled={props.disabled} isMember={true} />

      <MessageColumnSection disabled={props.disabled} />
    </>
  )
}

const HeadingArea = styled.div`
  margin-top: 32px;
`
// @fixme 子コンポーネントから共通で参照できるように一旦exportしました
export const Section = styled.section`
  margin-top: 72px;
`
const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 510px;
  margin-top: 16px;

  @media screen and (max-width: ${mediaQuery.SP}px) {
    display: block;
    width: 100%;

    & > span {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`
const ErrorMessage = styled.div`
  color: red;
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const InputLabel = styled.label`
  margin-left: 8px;
  font-size: 16px;
`

export default Component
